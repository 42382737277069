.bounce-left {
  transform: translate(0);
  animation: bounce-left infinite 2s ease;
}

@keyframes bounce-left {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
