:root {
  --bs-blue: #ffa41e;
  --bs-indigo: #0a0a0a;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #fcb92c;
  --bs-green: #1cbb8c;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #ffa41e;
  --bs-secondary: #6c757d;
  --bs-success: #1cbb8c;
  --bs-info: #17a2b8;
  --bs-warning: #fcb92c;
  --bs-danger: #dc3545;
  --bs-light: #f5f7fb;
  --bs-dark: #212529;
  --bs-primary-rgb: 59, 125, 221;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 28, 187, 140;
  --bs-info-rgb: 23, 162, 184;
  --bs-warning-rgb: 252, 185, 44;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 245, 247, 251;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 73, 80, 87;
  --bs-body-bg-rgb: 245, 247, 251;
  --bs-font-sans-serif: "Inter", "Helvetica Neue", Arial, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
  --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #495057;
  --bs-body-bg: #f5f7fb;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.dashboard-h1,
.dashboard-h2,
.dashboard-h3,
.dashboard-h4,
.dashboard-h5,
.dashboard-h6 {
  color: #000;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
.dashboard-h1 {
  font-size: 1.75rem;
}
.dashboard-h2 {
  font-size: 1.53125rem;
}
.dashboard-h3 {
  font-size: 1.3125rem;
}
.dashboard-h4 {
  font-size: 1.09375rem;
}
.dashboard-h5,
.dashboard-h6 {
  font-size: 0.875rem;
}

.dashboard-small {
  font-size: 80%;
}
.dashboard-mark {
  background-color: #fcf8e3;
  padding: 0.2em;
}

.dashboard-lead {
  font-size: 1.09375rem;
  font-weight: 300;
}
.dashboard-display-1 {
  font-size: 6rem;
}
.dashboard-display-1,
.dashboard-display-2 {
  font-weight: 300;
  line-height: 1.2;
}
.dashboard-display-2 {
  font-size: 5.5rem;
}
.dashboard-display-3 {
  font-size: 4.5rem;
}
.dashboard-display-3,
.dashboard-display-4 {
  font-weight: 300;
  line-height: 1.2;
}
.dashboard-display-4 {
  font-size: 3.5rem;
}
.dashboard-display-5 {
  font-size: 3rem;
}
.dashboard-display-5,
.dashboard-display-6 {
  font-weight: 300;
  line-height: 1.2;
}
.dashboard-display-6 {
  font-size: 2.5rem;
}
.dashboard-list-inline,
.dashboard-list-unstyled {
  list-style: none;
  padding-left: 0;
}
.dashboard-list-inline-item {
  display: inline-block;
}
.dashboard-list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.dashboard-initialism {
  font-size: 80%;
  text-transform: uppercase;
}
.dashboard-blockquote {
  font-size: 1.09375rem;
  margin-bottom: 1rem;
}
.dashboard-blockquote > :last-child {
  margin-bottom: 0;
}
.dashboard-blockquote-footer {
  color: #6c757d;
  font-size: 80%;
  margin-bottom: 1rem;
  margin-top: -1rem;
}
.dashboard-blockquote-footer:before {
  content: "— ";
}
.dashboard-img-fluid,
.dashboard-img-thumbnail {
  height: auto;
  max-width: 100%;
}
.dashboard-img-thumbnail {
  background-color: #f5f7fb;
  border: 1px solid #dee2e6;
  border-radius: 0.2rem;
  padding: 0.25rem;
}
.dashboard-figure {
  display: inline-block;
}
.dashboard-figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}
.dashboard-figure-caption {
  color: #6c757d;
  font-size: 80%;
}
.dashboard-container,
.dashboard-container-fluid,
.dashboard-container-lg,
.dashboard-container-md,
.dashboard-container-sm,
.dashboard-container-xl {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-right: var(--bs-gutter-x, 0.75rem);
  width: 100%;
}
@media (min-width: 576px) {
  .dashboard-container,
  .dashboard-container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .dashboard-container,
  .dashboard-container-md,
  .dashboard-container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .dashboard-container,
  .dashboard-container-lg,
  .dashboard-container-md,
  .dashboard-container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .dashboard-container,
  .dashboard-container-lg,
  .dashboard-container-md,
  .dashboard-container-sm,
  .dashboard-container-xl {
    max-width: 1200px;
  }
}
.dashboard-row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
}
.dashboard-row > * {
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
}
.dashboard-col {
  flex: 1 0 0%;
}
.dashboard-row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.dashboard-row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.dashboard-row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.dashboard-row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.dashboard-row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.dashboard-row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.dashboard-row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.dashboard-col-auto {
  flex: 0 0 auto;
  width: auto;
}
.dashboard-col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.dashboard-col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.dashboard-col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.dashboard-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.dashboard-col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.dashboard-col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.dashboard-col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.dashboard-col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.dashboard-col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.dashboard-col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.dashboard-col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.dashboard-col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.dashboard-offset-1 {
  margin-left: 8.33333333%;
}
.dashboard-offset-2 {
  margin-left: 16.66666667%;
}
.dashboard-offset-3 {
  margin-left: 25%;
}
.dashboard-offset-4 {
  margin-left: 33.33333333%;
}
.dashboard-offset-5 {
  margin-left: 41.66666667%;
}
.dashboard-offset-6 {
  margin-left: 50%;
}
.dashboard-offset-7 {
  margin-left: 58.33333333%;
}
.dashboard-offset-8 {
  margin-left: 66.66666667%;
}
.dashboard-offset-9 {
  margin-left: 75%;
}
.dashboard-offset-10 {
  margin-left: 83.33333333%;
}
.dashboard-offset-11 {
  margin-left: 91.66666667%;
}
.dashboard-g-0,
.dashboard-gx-0 {
  --bs-gutter-x: 0;
}
.dashboard-g-0,
.dashboard-gy-0 {
  --bs-gutter-y: 0;
}
.dashboard-g-1,
.dashboard-gx-1 {
  --bs-gutter-x: 0.25rem;
}
.dashboard-g-1,
.dashboard-gy-1 {
  --bs-gutter-y: 0.25rem;
}
.dashboard-g-2,
.dashboard-gx-2 {
  --bs-gutter-x: 0.5rem;
}
.dashboard-g-2,
.dashboard-gy-2 {
  --bs-gutter-y: 0.5rem;
}
.dashboard-g-3,
.dashboard-gx-3 {
  --bs-gutter-x: 1rem;
}
.dashboard-g-3,
.dashboard-gy-3 {
  --bs-gutter-y: 1rem;
}
.dashboard-g-4,
.dashboard-gx-4 {
  --bs-gutter-x: 1.5rem;
}
.dashboard-g-4,
.dashboard-gy-4 {
  --bs-gutter-y: 1.5rem;
}
.dashboard-g-5,
.dashboard-gx-5 {
  --bs-gutter-x: 3rem;
}
.dashboard-g-5,
.dashboard-gy-5 {
  --bs-gutter-y: 3rem;
}
.dashboard-g-6,
.dashboard-gx-6 {
  --bs-gutter-x: 4.5rem;
}
.dashboard-g-6,
.dashboard-gy-6 {
  --bs-gutter-y: 4.5rem;
}
.dashboard-g-7,
.dashboard-gx-7 {
  --bs-gutter-x: 6rem;
}
.dashboard-g-7,
.dashboard-gy-7 {
  --bs-gutter-y: 6rem;
}
@media (min-width: 576px) {
  .dashboard-col-sm {
    flex: 1 0 0%;
  }
  .dashboard-row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .dashboard-row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .dashboard-row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .dashboard-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .dashboard-col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .dashboard-col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .dashboard-col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .dashboard-col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .dashboard-col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .dashboard-col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .dashboard-col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .dashboard-col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .dashboard-col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-offset-sm-0 {
    margin-left: 0;
  }
  .dashboard-offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .dashboard-offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .dashboard-offset-sm-3 {
    margin-left: 25%;
  }
  .dashboard-offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .dashboard-offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .dashboard-offset-sm-6 {
    margin-left: 50%;
  }
  .dashboard-offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .dashboard-offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .dashboard-offset-sm-9 {
    margin-left: 75%;
  }
  .dashboard-offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .dashboard-offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .dashboard-g-sm-0,
  .dashboard-gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .dashboard-g-sm-0,
  .dashboard-gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .dashboard-g-sm-1,
  .dashboard-gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .dashboard-g-sm-1,
  .dashboard-gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .dashboard-g-sm-2,
  .dashboard-gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .dashboard-g-sm-2,
  .dashboard-gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .dashboard-g-sm-3,
  .dashboard-gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .dashboard-g-sm-3,
  .dashboard-gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .dashboard-g-sm-4,
  .dashboard-gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .dashboard-g-sm-4,
  .dashboard-gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .dashboard-g-sm-5,
  .dashboard-gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .dashboard-g-sm-5,
  .dashboard-gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .dashboard-g-sm-6,
  .dashboard-gx-sm-6 {
    --bs-gutter-x: 4.5rem;
  }
  .dashboard-g-sm-6,
  .dashboard-gy-sm-6 {
    --bs-gutter-y: 4.5rem;
  }
  .dashboard-g-sm-7,
  .dashboard-gx-sm-7 {
    --bs-gutter-x: 6rem;
  }
  .dashboard-g-sm-7,
  .dashboard-gy-sm-7 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 768px) {
  .dashboard-col-md {
    flex: 1 0 0%;
  }
  .dashboard-row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .dashboard-row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .dashboard-row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .dashboard-col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .dashboard-col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .dashboard-col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .dashboard-col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .dashboard-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .dashboard-col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .dashboard-col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .dashboard-col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .dashboard-col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .dashboard-col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-offset-md-0 {
    margin-left: 0;
  }
  .dashboard-offset-md-1 {
    margin-left: 8.33333333%;
  }
  .dashboard-offset-md-2 {
    margin-left: 16.66666667%;
  }
  .dashboard-offset-md-3 {
    margin-left: 25%;
  }
  .dashboard-offset-md-4 {
    margin-left: 33.33333333%;
  }
  .dashboard-offset-md-5 {
    margin-left: 41.66666667%;
  }
  .dashboard-offset-md-6 {
    margin-left: 50%;
  }
  .dashboard-offset-md-7 {
    margin-left: 58.33333333%;
  }
  .dashboard-offset-md-8 {
    margin-left: 66.66666667%;
  }
  .dashboard-offset-md-9 {
    margin-left: 75%;
  }
  .dashboard-offset-md-10 {
    margin-left: 83.33333333%;
  }
  .dashboard-offset-md-11 {
    margin-left: 91.66666667%;
  }
  .dashboard-g-md-0,
  .dashboard-gx-md-0 {
    --bs-gutter-x: 0;
  }
  .dashboard-g-md-0,
  .dashboard-gy-md-0 {
    --bs-gutter-y: 0;
  }
  .dashboard-g-md-1,
  .dashboard-gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .dashboard-g-md-1,
  .dashboard-gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .dashboard-g-md-2,
  .dashboard-gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .dashboard-g-md-2,
  .dashboard-gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .dashboard-g-md-3,
  .dashboard-gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .dashboard-g-md-3,
  .dashboard-gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .dashboard-g-md-4,
  .dashboard-gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .dashboard-g-md-4,
  .dashboard-gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .dashboard-g-md-5,
  .dashboard-gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .dashboard-g-md-5,
  .dashboard-gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .dashboard-g-md-6,
  .dashboard-gx-md-6 {
    --bs-gutter-x: 4.5rem;
  }
  .dashboard-g-md-6,
  .dashboard-gy-md-6 {
    --bs-gutter-y: 4.5rem;
  }
  .dashboard-g-md-7,
  .dashboard-gx-md-7 {
    --bs-gutter-x: 6rem;
  }
  .dashboard-g-md-7,
  .dashboard-gy-md-7 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 992px) {
  .dashboard-col-lg {
    flex: 1 0 0%;
  }
  .dashboard-row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .dashboard-row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .dashboard-row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .dashboard-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .dashboard-col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .dashboard-col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .dashboard-col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .dashboard-col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .dashboard-col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .dashboard-col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .dashboard-col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .dashboard-col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .dashboard-col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-offset-lg-0 {
    margin-left: 0;
  }
  .dashboard-offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .dashboard-offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .dashboard-offset-lg-3 {
    margin-left: 25%;
  }
  .dashboard-offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .dashboard-offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .dashboard-offset-lg-6 {
    margin-left: 50%;
  }
  .dashboard-offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .dashboard-offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .dashboard-offset-lg-9 {
    margin-left: 75%;
  }
  .dashboard-offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .dashboard-offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .dashboard-g-lg-0,
  .dashboard-gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .dashboard-g-lg-0,
  .dashboard-gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .dashboard-g-lg-1,
  .dashboard-gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .dashboard-g-lg-1,
  .dashboard-gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .dashboard-g-lg-2,
  .dashboard-gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .dashboard-g-lg-2,
  .dashboard-gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .dashboard-g-lg-3,
  .dashboard-gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .dashboard-g-lg-3,
  .dashboard-gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .dashboard-g-lg-4,
  .dashboard-gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .dashboard-g-lg-4,
  .dashboard-gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .dashboard-g-lg-5,
  .dashboard-gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .dashboard-g-lg-5,
  .dashboard-gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .dashboard-g-lg-6,
  .dashboard-gx-lg-6 {
    --bs-gutter-x: 4.5rem;
  }
  .dashboard-g-lg-6,
  .dashboard-gy-lg-6 {
    --bs-gutter-y: 4.5rem;
  }
  .dashboard-g-lg-7,
  .dashboard-gx-lg-7 {
    --bs-gutter-x: 6rem;
  }
  .dashboard-g-lg-7,
  .dashboard-gy-lg-7 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 1200px) {
  .dashboard-col-xl {
    flex: 1 0 0%;
  }
  .dashboard-row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .dashboard-row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .dashboard-row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .dashboard-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .dashboard-col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .dashboard-col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .dashboard-col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .dashboard-col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .dashboard-col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .dashboard-col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .dashboard-col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .dashboard-col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .dashboard-col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-offset-xl-0 {
    margin-left: 0;
  }
  .dashboard-offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .dashboard-offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .dashboard-offset-xl-3 {
    margin-left: 25%;
  }
  .dashboard-offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .dashboard-offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .dashboard-offset-xl-6 {
    margin-left: 50%;
  }
  .dashboard-offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .dashboard-offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .dashboard-offset-xl-9 {
    margin-left: 75%;
  }
  .dashboard-offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .dashboard-offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .dashboard-g-xl-0,
  .dashboard-gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .dashboard-g-xl-0,
  .dashboard-gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .dashboard-g-xl-1,
  .dashboard-gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .dashboard-g-xl-1,
  .dashboard-gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .dashboard-g-xl-2,
  .dashboard-gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .dashboard-g-xl-2,
  .dashboard-gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .dashboard-g-xl-3,
  .dashboard-gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .dashboard-g-xl-3,
  .dashboard-gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .dashboard-g-xl-4,
  .dashboard-gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .dashboard-g-xl-4,
  .dashboard-gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .dashboard-g-xl-5,
  .dashboard-gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .dashboard-g-xl-5,
  .dashboard-gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .dashboard-g-xl-6,
  .dashboard-gx-xl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .dashboard-g-xl-6,
  .dashboard-gy-xl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .dashboard-g-xl-7,
  .dashboard-gx-xl-7 {
    --bs-gutter-x: 6rem;
  }
  .dashboard-g-xl-7,
  .dashboard-gy-xl-7 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 1440px) {
  .dashboard-col-xxl {
    flex: 1 0 0%;
  }
  .dashboard-row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .dashboard-row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .dashboard-row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .dashboard-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .dashboard-col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .dashboard-col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .dashboard-col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .dashboard-col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .dashboard-col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .dashboard-col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .dashboard-col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .dashboard-col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .dashboard-col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .dashboard-col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .dashboard-col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .dashboard-col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .dashboard-offset-xxl-0 {
    margin-left: 0;
  }
  .dashboard-offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .dashboard-offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .dashboard-offset-xxl-3 {
    margin-left: 25%;
  }
  .dashboard-offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .dashboard-offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .dashboard-offset-xxl-6 {
    margin-left: 50%;
  }
  .dashboard-offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .dashboard-offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .dashboard-offset-xxl-9 {
    margin-left: 75%;
  }
  .dashboard-offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .dashboard-offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .dashboard-g-xxl-0,
  .dashboard-gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .dashboard-g-xxl-0,
  .dashboard-gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .dashboard-g-xxl-1,
  .dashboard-gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .dashboard-g-xxl-1,
  .dashboard-gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .dashboard-g-xxl-2,
  .dashboard-gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .dashboard-g-xxl-2,
  .dashboard-gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .dashboard-g-xxl-3,
  .dashboard-gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .dashboard-g-xxl-3,
  .dashboard-gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .dashboard-g-xxl-4,
  .dashboard-gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .dashboard-g-xxl-4,
  .dashboard-gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .dashboard-g-xxl-5,
  .dashboard-gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .dashboard-g-xxl-5,
  .dashboard-gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .dashboard-g-xxl-6,
  .dashboard-gx-xxl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .dashboard-g-xxl-6,
  .dashboard-gy-xxl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .dashboard-g-xxl-7,
  .dashboard-gx-xxl-7 {
    --bs-gutter-x: 6rem;
  }
  .dashboard-g-xxl-7,
  .dashboard-gy-xxl-7 {
    --bs-gutter-y: 6rem;
  }
}
.dashboard-table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #495057;
  --bs-table-striped-bg: #f8f9fa;
  --bs-table-active-color: #495057;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #495057;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.038);
  border-color: #dee2e6;
  color: #495057;
  margin-bottom: 1rem;
  vertical-align: top;
  width: 100%;
}
.dashboard-table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  padding: 0.75rem;
}
.dashboard-table > tbody {
  vertical-align: inherit;
}
.dashboard-table > thead {
  vertical-align: bottom;
}
.dashboard-table > :not(:first-child) {
  border-top: 2px solid;
}
.dashboard-caption-top {
  caption-side: top;
}
.dashboard-table-sm > :not(caption) > * > * {
  padding: 0.3rem;
}
.dashboard-table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.dashboard-table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.dashboard-table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.dashboard-table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.dashboard-table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.dashboard-table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}
.dashboard-table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}
.dashboard-table-primary {
  --bs-table-bg: #d8e5f8;
  --bs-table-striped-bg: #cddaec;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2cedf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8d4e5;
  --bs-table-hover-color: #000;
  border-color: #c2cedf;
  color: #000;
}
.dashboard-table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  border-color: #cbccce;
  color: #000;
}
.dashboard-table-success {
  --bs-table-bg: #d2f1e8;
  --bs-table-striped-bg: #c8e5dc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdd9d1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2dfd7;
  --bs-table-hover-color: #000;
  border-color: #bdd9d1;
  color: #000;
}
.dashboard-table-info {
  --bs-table-bg: #d1ecf1;
  --bs-table-striped-bg: #c7e0e5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd4d9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1dadf;
  --bs-table-hover-color: #000;
  border-color: #bcd4d9;
  color: #000;
}
.dashboard-table-warning {
  --bs-table-bg: #fef1d5;
  --bs-table-striped-bg: #f1e5ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5d9c0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebdfc5;
  --bs-table-hover-color: #000;
  border-color: #e5d9c0;
  color: #000;
}
.dashboard-table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  border-color: #dfc2c4;
  color: #000;
}
.dashboard-table-light {
  --bs-table-bg: #f5f7fb;
  --bs-table-striped-bg: #e9ebee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dddee2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3e4e8;
  --bs-table-hover-color: #000;
  border-color: #dddee2;
  color: #000;
}
.dashboard-table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  border-color: #373b3e;
  color: #fff;
}
.dashboard-table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}
@media (max-width: 575.98px) {
  .dashboard-table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 767.98px) {
  .dashboard-table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 991.98px) {
  .dashboard-table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1199.98px) {
  .dashboard-table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1439.98px) {
  .dashboard-table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
.dashboard-form-label {
  margin-bottom: 0.5rem;
}
.dashboard-col-form-label {
  font-size: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: calc(0.3rem + 1px);
  padding-top: calc(0.3rem + 1px);
}
.dashboard-col-form-label-lg {
  font-size: 0.925rem;
  padding-bottom: calc(0.4rem + 1px);
  padding-top: calc(0.4rem + 1px);
}
.dashboard-col-form-label-sm {
  font-size: 0.75rem;
  padding-bottom: calc(0.2rem + 1px);
  padding-top: calc(0.2rem + 1px);
}
.dashboard-form-text {
  color: #6c757d;
  font-size: 80%;
  margin-top: 0.25rem;
}
.dashboard-form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  color: #495057;
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.3rem 0.85rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-form-control {
    transition: none;
  }
}
.dashboard-form-control[type="file"] {
  overflow: hidden;
}
.dashboard-form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.dashboard-form-control:focus {
  background-color: #fff;
  border-color: #9dbeee;
  box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
  color: #495057;
  outline: 0;
}
.dashboard-form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.dashboard-form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.dashboard-form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.dashboard-form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.dashboard-form-control:disabled,
.dashboard-form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.dashboard-form-control::file-selector-button {
  -webkit-margin-end: 0.85rem;
  background-color: #e9ecef;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #495057;
  margin: -0.3rem -0.85rem;
  margin-inline-end: 0.85rem;
  padding: 0.3rem 0.85rem;
  pointer-events: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .dashboard-form-control::file-selector-button {
    transition: none;
  }
}
.dashboard-form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.dashboard-form-control::-webkit-file-upload-button {
  -webkit-margin-end: 0.85rem;
  background-color: #e9ecef;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #495057;
  margin: -0.3rem -0.85rem;
  margin-inline-end: 0.85rem;
  padding: 0.3rem 0.85rem;
  pointer-events: none;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.dashboard-form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}
.dashboard-form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: #495057;
  display: block;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.3rem 0;
  width: 100%;
}
.dashboard-form-control-plaintext.dashboard-form-control-lg,
.dashboard-form-control-plaintext.dashboard-form-control-sm {
  padding-left: 0;
  padding-right: 0;
}
.dashboard-form-control-sm {
  border-radius: 0.1rem;
  font-size: 0.75rem;
  min-height: calc(1.525rem + 2px);
  padding: 0.2rem 0.5rem;
}
.dashboard-form-control-sm::file-selector-button {
  -webkit-margin-end: 0.5rem;
  margin: -0.2rem -0.5rem;
  margin-inline-end: 0.5rem;
  padding: 0.2rem 0.5rem;
}
.dashboard-form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: 0.5rem;
  margin: -0.2rem -0.5rem;
  margin-inline-end: 0.5rem;
  padding: 0.2rem 0.5rem;
}
.dashboard-form-control-lg {
  border-radius: 0.3rem;
  font-size: 0.925rem;
  min-height: calc(2.1875rem + 2px);
  padding: 0.4rem 1rem;
}
.dashboard-form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -0.4rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.4rem 1rem;
}
.dashboard-form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -0.4rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.4rem 1rem;
}
textarea.dashboard-form-control {
  min-height: calc(1.9125rem + 2px);
}
textarea.dashboard-form-control-sm {
  min-height: calc(1.525rem + 2px);
}
textarea.dashboard-form-control-lg {
  min-height: calc(2.1875rem + 2px);
}
.dashboard-form-control-color {
  height: auto;
  padding: 0.3rem;
  width: 3rem;
}
.dashboard-form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.dashboard-form-control-color::-moz-color-swatch {
  border-radius: 0.2rem;
  height: 1.5em;
}
.dashboard-form-control-color::-webkit-color-swatch {
  border-radius: 0.2rem;
  height: 1.5em;
}
.dashboard-form-select {
  -moz-padding-start: calc(0.85rem - 3px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 0.85rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  color: #495057;
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.3rem 2.55rem 0.3rem 0.85rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-form-select {
    transition: none;
  }
}
.dashboard-form-select:focus {
  border-color: #9dbeee;
  box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
  outline: 0;
}
.dashboard-form-select[multiple],
.dashboard-form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 0.85rem;
}
.dashboard-form-select:disabled {
  background-color: #e9ecef;
}
.dashboard-form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.dashboard-form-select-sm {
  border-radius: 0.1rem;
  font-size: 0.75rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
}
.dashboard-form-select-lg {
  border-radius: 0.3rem;
  font-size: 0.925rem;
  padding-bottom: 0.4rem;
  padding-left: 1rem;
  padding-top: 0.4rem;
}
.dashboard-form-check {
  display: block;
  margin-bottom: 0.125rem;
  min-height: 1.3125rem;
  padding-left: 1.5em;
}
.dashboard-form-check .dashboard-form-check-input {
  float: left;
  margin-left: -1.5em;
}
.dashboard-form-check-input {
  -webkit-print-color-adjust: exact;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color-adjust: exact;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  width: 1em;
}
.dashboard-form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.dashboard-form-check-input[type="radio"] {
  border-radius: 50%;
}
.dashboard-form-check-input:active {
  filter: brightness(90%);
}
.dashboard-form-check-input:focus {
  border-color: #9dbeee;
  box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
  outline: 0;
}
.dashboard-form-check-input:checked {
  background-color: #ffa41e;
  border-color: #ffa41e;
}
.dashboard-form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}
.dashboard-form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}
.dashboard-form-check-input[type="checkbox"]:indeterminate {
  background-color: #ffa41e;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  border-color: #ffa41e;
}
.dashboard-form-check-input:disabled {
  filter: none;
  opacity: 0.5;
  pointer-events: none;
}
.dashboard-form-check-input:disabled ~ .dashboard-form-check-label,
.dashboard-form-check-input[disabled] ~ .dashboard-form-check-label {
  opacity: 0.5;
}
.dashboard-form-switch {
  padding-left: 2.5em;
}
.dashboard-form-switch .dashboard-form-check-input {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position 0.15s ease-in-out;
  width: 2em;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-form-switch .dashboard-form-check-input {
    transition: none;
  }
}
.dashboard-form-switch .dashboard-form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%239dbeee'/%3E%3C/svg%3E");
}
.dashboard-form-switch .dashboard-form-check-input:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 100%;
}
.dashboard-form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.dashboard-btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}
.dashboard-btn-check:disabled + .dashboard-btn,
.dashboard-btn-check[disabled] + .dashboard-btn {
  filter: none;
  opacity: 0.65;
  pointer-events: none;
}
.dashboard-form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 1.4rem;
  padding: 0;
  width: 100%;
}
.dashboard-form-range:focus {
  outline: 0;
}
.dashboard-form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
}
.dashboard-form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f5f7fb, 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
}
.dashboard-form-range::-moz-focus-outer {
  border: 0;
}
.dashboard-form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffa41e;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.dashboard-form-range::-webkit-slider-thumb:active {
  background-color: #c4d8f5;
}
.dashboard-form-range::-webkit-slider-runnable-track {
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.dashboard-form-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: #ffa41e;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.dashboard-form-range::-moz-range-thumb:active {
  background-color: #c4d8f5;
}
.dashboard-form-range::-moz-range-track {
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.dashboard-form-range:disabled {
  pointer-events: none;
}
.dashboard-form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.dashboard-form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.dashboard-form-floating {
  position: relative;
}
.dashboard-form-floating > .dashboard-form-control,
.dashboard-form-floating > .dashboard-form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.dashboard-form-floating > label {
  border: 1px solid transparent;
  height: 100%;
  left: 0;
  padding: 1rem 0.85rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-form-floating > label {
    transition: none;
  }
}
.dashboard-form-floating > .dashboard-form-control {
  padding: 1rem 0.85rem;
}
.dashboard-form-floating > .dashboard-form-control::-webkit-input-placeholder {
  color: transparent;
}
.dashboard-form-floating > .dashboard-form-control::-moz-placeholder {
  color: transparent;
}
.dashboard-form-floating > .dashboard-form-control::placeholder {
  color: transparent;
}
.dashboard-form-floating > .dashboard-form-control:not(:-moz-placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.dashboard-form-floating > .dashboard-form-control:focus,
.dashboard-form-floating > .dashboard-form-control:not(:placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.dashboard-form-floating > .dashboard-form-control:-webkit-autofill {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.dashboard-form-floating > .dashboard-form-select {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.dashboard-form-floating > .dashboard-form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.dashboard-form-floating > .dashboard-form-control:focus ~ label,
.dashboard-form-floating > .dashboard-form-control:not(:placeholder-shown) ~ label,
.dashboard-form-floating > .dashboard-form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.dashboard-form-floating > .dashboard-form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.dashboard-input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.dashboard-input-group > .dashboard-form-control,
.dashboard-input-group > .dashboard-form-select {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%;
}
.dashboard-input-group > .dashboard-form-control:focus,
.dashboard-input-group > .dashboard-form-select:focus {
  z-index: 3;
}
.dashboard-input-group .dashboard-btn {
  position: relative;
  z-index: 2;
}
.dashboard-input-group .dashboard-btn:focus {
  z-index: 3;
}
.dashboard-input-group-text {
  align-items: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  color: #495057;
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.3rem 0.85rem;
  text-align: center;
  white-space: nowrap;
}
.dashboard-input-group-lg > .dashboard-btn,
.dashboard-input-group-lg > .dashboard-form-control,
.dashboard-input-group-lg > .dashboard-form-select,
.dashboard-input-group-lg > .dashboard-input-group-text {
  border-radius: 0.3rem;
  font-size: 0.925rem;
  padding: 0.4rem 1rem;
}
.dashboard-input-group-sm > .dashboard-btn,
.dashboard-input-group-sm > .dashboard-form-control,
.dashboard-input-group-sm > .dashboard-form-select,
.dashboard-input-group-sm > .dashboard-input-group-text {
  border-radius: 0.1rem;
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
}
.dashboard-input-group-lg > .dashboard-form-select,
.dashboard-input-group-sm > .dashboard-form-select {
  padding-right: 3.4rem;
}
.dashboard-input-group.dashboard-has-validation > .dashboard-dropdown-toggle:nth-last-child(n + 4),
.dashboard-input-group.dashboard-has-validation
  > :nth-last-child(n + 3):not(.dashboard-dropdown-toggle):not(.dashboard-dropdown-menu),
.dashboard-input-group:not(.dashboard-has-validation)
  > .dashboard-dropdown-toggle:nth-last-child(n + 3),
.dashboard-input-group:not(.dashboard-has-validation)
  > :not(:last-child):not(.dashboard-dropdown-toggle):not(.dashboard-dropdown-menu) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.dashboard-input-group
  > :not(:first-child):not(.dashboard-dropdown-menu):not(.dashboard-valid-tooltip):not(.dashboard-valid-feedback):not(.dashboard-invalid-tooltip):not(.dashboard-invalid-feedback) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
.dashboard-valid-feedback {
  color: #1cbb8c;
  display: none;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
}
.dashboard-valid-tooltip {
  background-color: rgba(28, 187, 140, 0.9);
  border-radius: 0.2rem;
  color: #000;
  display: none;
  font-size: 0.75rem;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.dashboard-is-valid ~ .dashboard-valid-feedback,
.dashboard-is-valid ~ .dashboard-valid-tooltip,
.dashboard-was-validated :valid ~ .dashboard-valid-feedback,
.dashboard-was-validated :valid ~ .dashboard-valid-tooltip {
  display: block;
}
.dashboard-form-control.dashboard-is-valid,
.dashboard-was-validated .dashboard-form-control:valid {
  border-color: #1cbb8c;
}
.dashboard-form-control.dashboard-is-valid:focus,
.dashboard-was-validated .dashboard-form-control:valid:focus {
  border-color: #1cbb8c;
  box-shadow: 0 0 0 0.2rem rgba(28, 187, 140, 0.25);
}
.dashboard-form-select.dashboard-is-valid,
.dashboard-was-validated .dashboard-form-select:valid {
  border-color: #1cbb8c;
}
.dashboard-form-select.dashboard-is-valid:focus,
.dashboard-was-validated .dashboard-form-select:valid:focus {
  border-color: #1cbb8c;
  box-shadow: 0 0 0 0.2rem rgba(28, 187, 140, 0.25);
}
.dashboard-form-check-input.dashboard-is-valid,
.dashboard-was-validated .dashboard-form-check-input:valid {
  border-color: #1cbb8c;
}
.dashboard-form-check-input.dashboard-is-valid:checked,
.dashboard-was-validated .dashboard-form-check-input:valid:checked {
  background-color: #1cbb8c;
}
.dashboard-form-check-input.dashboard-is-valid:focus,
.dashboard-was-validated .dashboard-form-check-input:valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 187, 140, 0.25);
}
.dashboard-form-check-input.dashboard-is-valid ~ .dashboard-form-check-label,
.dashboard-was-validated .dashboard-form-check-input:valid ~ .dashboard-form-check-label {
  color: #1cbb8c;
}
.dashboard-form-check-inline .dashboard-form-check-input ~ .dashboard-valid-feedback {
  margin-left: 0.5em;
}
.dashboard-input-group .dashboard-form-control.dashboard-is-valid,
.dashboard-input-group .dashboard-form-select.dashboard-is-valid,
.dashboard-was-validated .dashboard-input-group .dashboard-form-control:valid,
.dashboard-was-validated .dashboard-input-group .dashboard-form-select:valid {
  z-index: 1;
}
.dashboard-input-group .dashboard-form-control.dashboard-is-valid:focus,
.dashboard-input-group .dashboard-form-select.dashboard-is-valid:focus,
.dashboard-was-validated .dashboard-input-group .dashboard-form-control:valid:focus,
.dashboard-was-validated .dashboard-input-group .dashboard-form-select:valid:focus {
  z-index: 3;
}
.dashboard-invalid-feedback {
  color: #dc3545;
  display: none;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
}
.dashboard-invalid-tooltip {
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.2rem;
  color: #fff;
  display: none;
  font-size: 0.75rem;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.dashboard-is-invalid ~ .dashboard-invalid-feedback,
.dashboard-is-invalid ~ .dashboard-invalid-tooltip,
.dashboard-was-validated :invalid ~ .dashboard-invalid-feedback,
.dashboard-was-validated :invalid ~ .dashboard-invalid-tooltip {
  display: block;
}
.dashboard-form-control.dashboard-is-invalid,
.dashboard-was-validated .dashboard-form-control:invalid {
  border-color: #dc3545;
}
.dashboard-form-control.dashboard-is-invalid:focus,
.dashboard-was-validated .dashboard-form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dashboard-form-select.dashboard-is-invalid,
.dashboard-was-validated .dashboard-form-select:invalid {
  border-color: #dc3545;
}
.dashboard-form-select.dashboard-is-invalid:focus,
.dashboard-was-validated .dashboard-form-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dashboard-form-check-input.dashboard-is-invalid,
.dashboard-was-validated .dashboard-form-check-input:invalid {
  border-color: #dc3545;
}
.dashboard-form-check-input.dashboard-is-invalid:checked,
.dashboard-was-validated .dashboard-form-check-input:invalid:checked {
  background-color: #dc3545;
}
.dashboard-form-check-input.dashboard-is-invalid:focus,
.dashboard-was-validated .dashboard-form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dashboard-form-check-input.dashboard-is-invalid ~ .dashboard-form-check-label,
.dashboard-was-validated .dashboard-form-check-input:invalid ~ .dashboard-form-check-label {
  color: #dc3545;
}
.dashboard-form-check-inline .dashboard-form-check-input ~ .dashboard-invalid-feedback {
  margin-left: 0.5em;
}
.dashboard-input-group .dashboard-form-control.dashboard-is-invalid,
.dashboard-input-group .dashboard-form-select.dashboard-is-invalid,
.dashboard-was-validated .dashboard-input-group .dashboard-form-control:invalid,
.dashboard-was-validated .dashboard-input-group .dashboard-form-select:invalid {
  z-index: 2;
}
.dashboard-input-group .dashboard-form-control.dashboard-is-invalid:focus,
.dashboard-input-group .dashboard-form-select.dashboard-is-invalid:focus,
.dashboard-was-validated .dashboard-input-group .dashboard-form-control:invalid:focus,
.dashboard-was-validated .dashboard-input-group .dashboard-form-select:invalid:focus {
  z-index: 3;
}
.dashboard-btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  color: #495057;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.3rem 0.85rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-btn {
    transition: none;
  }
}
.dashboard-btn:hover {
  color: #495057;
  text-decoration: none;
}
.dashboard-btn-check:focus + .dashboard-btn,
.dashboard-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
  outline: 0;
}
.dashboard-btn.dashboard-disabled,
.dashboard-btn:disabled,
fieldset:disabled .dashboard-btn {
  opacity: 0.65;
  pointer-events: none;
}
.dashboard-btn-primary {
  background-color: #ffa41e;
  border-color: #ffa41e;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-primary,
.dashboard-btn-primary:focus,
.dashboard-btn-primary:hover {
  background-color: #ff9d33;
  border-color: #ff9d33;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-primary,
.dashboard-btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 133, 0, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-primary,
.dashboard-btn-check:checked + .dashboard-btn-primary,
.dashboard-btn-primary.dashboard-active,
.dashboard-btn-primary:active,
.dashboard-show > .dashboard-btn-primary.dashboard-dropdown-toggle {
  background-color: #ff9d33;
  border-color: #ff9d33;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-primary:focus,
.dashboard-btn-check:checked + .dashboard-btn-primary:focus,
.dashboard-btn-primary.dashboard-active:focus,
.dashboard-btn-primary:active:focus,
.dashboard-show > .dashboard-btn-primary.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 133, 0, 0.5);
}
.dashboard-btn-primary.dashboard-disabled,
.dashboard-btn-primary:disabled {
  background-color: #ffa41e;
  border-color: #ffa41e;
  color: #fff;
}
.dashboard-btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-secondary,
.dashboard-btn-secondary:focus,
.dashboard-btn-secondary:hover {
  background-color: #5c636a;
  border-color: #565e64;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-secondary,
.dashboard-btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-secondary,
.dashboard-btn-check:checked + .dashboard-btn-secondary,
.dashboard-btn-secondary.dashboard-active,
.dashboard-btn-secondary:active,
.dashboard-show > .dashboard-btn-secondary.dashboard-dropdown-toggle {
  background-color: #565e64;
  border-color: #51585e;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-secondary:focus,
.dashboard-btn-check:checked + .dashboard-btn-secondary:focus,
.dashboard-btn-secondary.dashboard-active:focus,
.dashboard-btn-secondary:active:focus,
.dashboard-show > .dashboard-btn-secondary.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.dashboard-btn-secondary.dashboard-disabled,
.dashboard-btn-secondary:disabled {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}
.dashboard-btn-success {
  background-color: #1cbb8c;
  border-color: #1cbb8c;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-success,
.dashboard-btn-success:focus,
.dashboard-btn-success:hover {
  background-color: #3ec59d;
  border-color: #33c298;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-success,
.dashboard-btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 159, 119, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-success,
.dashboard-btn-check:checked + .dashboard-btn-success,
.dashboard-btn-success.dashboard-active,
.dashboard-btn-success:active,
.dashboard-show > .dashboard-btn-success.dashboard-dropdown-toggle {
  background-color: #49c9a3;
  border-color: #33c298;
  color: #000;
}
.dashboard-btn-check:active + .dashboard-btn-success:focus,
.dashboard-btn-check:checked + .dashboard-btn-success:focus,
.dashboard-btn-success.dashboard-active:focus,
.dashboard-btn-success:active:focus,
.dashboard-show > .dashboard-btn-success.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 159, 119, 0.5);
}
.dashboard-btn-success.dashboard-disabled,
.dashboard-btn-success:disabled {
  background-color: #1cbb8c;
  border-color: #1cbb8c;
  color: #000;
}
.dashboard-btn-info {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-info,
.dashboard-btn-info:focus,
.dashboard-btn-info:hover {
  background-color: #148a9c;
  border-color: #128293;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-info,
.dashboard-btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-info,
.dashboard-btn-check:checked + .dashboard-btn-info,
.dashboard-btn-info.dashboard-active,
.dashboard-btn-info:active,
.dashboard-show > .dashboard-btn-info.dashboard-dropdown-toggle {
  background-color: #128293;
  border-color: #117a8a;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-info:focus,
.dashboard-btn-check:checked + .dashboard-btn-info:focus,
.dashboard-btn-info.dashboard-active:focus,
.dashboard-btn-info:active:focus,
.dashboard-show > .dashboard-btn-info.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.dashboard-btn-info.dashboard-disabled,
.dashboard-btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.dashboard-btn-warning {
  background-color: #fcb92c;
  border-color: #fcb92c;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-warning,
.dashboard-btn-warning:focus,
.dashboard-btn-warning:hover {
  background-color: #fcc44c;
  border-color: #fcc041;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-warning,
.dashboard-btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 157, 37, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-warning,
.dashboard-btn-check:checked + .dashboard-btn-warning,
.dashboard-btn-warning.dashboard-active,
.dashboard-btn-warning:active,
.dashboard-show > .dashboard-btn-warning.dashboard-dropdown-toggle {
  background-color: #fdc756;
  border-color: #fcc041;
  color: #000;
}
.dashboard-btn-check:active + .dashboard-btn-warning:focus,
.dashboard-btn-check:checked + .dashboard-btn-warning:focus,
.dashboard-btn-warning.dashboard-active:focus,
.dashboard-btn-warning:active:focus,
.dashboard-show > .dashboard-btn-warning.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 157, 37, 0.5);
}
.dashboard-btn-warning.dashboard-disabled,
.dashboard-btn-warning:disabled {
  background-color: #fcb92c;
  border-color: #fcb92c;
  color: #000;
}
.dashboard-btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-danger,
.dashboard-btn-danger:focus,
.dashboard-btn-danger:hover {
  background-color: #bb2d3b;
  border-color: #b02a37;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-danger,
.dashboard-btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-danger,
.dashboard-btn-check:checked + .dashboard-btn-danger,
.dashboard-btn-danger.dashboard-active,
.dashboard-btn-danger:active,
.dashboard-show > .dashboard-btn-danger.dashboard-dropdown-toggle {
  background-color: #b02a37;
  border-color: #a52834;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-danger:focus,
.dashboard-btn-check:checked + .dashboard-btn-danger:focus,
.dashboard-btn-danger.dashboard-active:focus,
.dashboard-btn-danger:active:focus,
.dashboard-show > .dashboard-btn-danger.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.dashboard-btn-danger.dashboard-disabled,
.dashboard-btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.dashboard-btn-light {
  background-color: #f5f7fb;
  border-color: #f5f7fb;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-light,
.dashboard-btn-light:focus,
.dashboard-btn-light:hover {
  background-color: #f7f8fc;
  border-color: #f6f8fb;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-light,
.dashboard-btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 210, 213, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-light,
.dashboard-btn-check:checked + .dashboard-btn-light,
.dashboard-btn-light.dashboard-active,
.dashboard-btn-light:active,
.dashboard-show > .dashboard-btn-light.dashboard-dropdown-toggle {
  background-color: #f7f9fc;
  border-color: #f6f8fb;
  color: #000;
}
.dashboard-btn-check:active + .dashboard-btn-light:focus,
.dashboard-btn-check:checked + .dashboard-btn-light:focus,
.dashboard-btn-light.dashboard-active:focus,
.dashboard-btn-light:active:focus,
.dashboard-show > .dashboard-btn-light.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 210, 213, 0.5);
}
.dashboard-btn-light.dashboard-disabled,
.dashboard-btn-light:disabled {
  background-color: #f5f7fb;
  border-color: #f5f7fb;
  color: #000;
}
.dashboard-btn-dark {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-dark,
.dashboard-btn-dark:focus,
.dashboard-btn-dark:hover {
  background-color: #1c1f23;
  border-color: #1a1e21;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-dark,
.dashboard-btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-dark,
.dashboard-btn-check:checked + .dashboard-btn-dark,
.dashboard-btn-dark.dashboard-active,
.dashboard-btn-dark:active,
.dashboard-show > .dashboard-btn-dark.dashboard-dropdown-toggle {
  background-color: #1a1e21;
  border-color: #191c1f;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-dark:focus,
.dashboard-btn-check:checked + .dashboard-btn-dark:focus,
.dashboard-btn-dark.dashboard-active:focus,
.dashboard-btn-dark:active:focus,
.dashboard-show > .dashboard-btn-dark.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5);
}
.dashboard-btn-dark.dashboard-disabled,
.dashboard-btn-dark:disabled {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}
.dashboard-btn-outline-primary {
  border-color: #ffa41e;
  color: #ffa41e;
}
.dashboard-btn-outline-primary:hover {
  background-color: #ff9d33;
  border-color: #ff9d33;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-outline-primary,
.dashboard-btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 51, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-outline-primary,
.dashboard-btn-check:checked + .dashboard-btn-outline-primary,
.dashboard-btn-outline-primary.dashboard-active,
.dashboard-btn-outline-primary.dashboard-dropdown-toggle.dashboard-show,
.dashboard-btn-outline-primary:active {
  background-color: #ff9d33;
  border-color: #ff9d33;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-outline-primary:focus,
.dashboard-btn-check:checked + .dashboard-btn-outline-primary:focus,
.dashboard-btn-outline-primary.dashboard-active:focus,
.dashboard-btn-outline-primary.dashboard-dropdown-toggle.dashboard-show:focus,
.dashboard-btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 51, 0.5);
}
.dashboard-btn-outline-primary.dashboard-disabled,
.dashboard-btn-outline-primary:disabled {
  background-color: transparent;
  color: #b25d00;
}
.dashboard-btn-outline-secondary {
  border-color: #6c757d;
  color: #6c757d;
}
.dashboard-btn-outline-secondary:hover {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-outline-secondary,
.dashboard-btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-outline-secondary,
.dashboard-btn-check:checked + .dashboard-btn-outline-secondary,
.dashboard-btn-outline-secondary.dashboard-active,
.dashboard-btn-outline-secondary.dashboard-dropdown-toggle.dashboard-show,
.dashboard-btn-outline-secondary:active {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-outline-secondary:focus,
.dashboard-btn-check:checked + .dashboard-btn-outline-secondary:focus,
.dashboard-btn-outline-secondary.dashboard-active:focus,
.dashboard-btn-outline-secondary.dashboard-dropdown-toggle.dashboard-show:focus,
.dashboard-btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.dashboard-btn-outline-secondary.dashboard-disabled,
.dashboard-btn-outline-secondary:disabled {
  background-color: transparent;
  color: #6c757d;
}
.dashboard-btn-outline-success {
  border-color: #1cbb8c;
  color: #1cbb8c;
}
.dashboard-btn-outline-success:hover {
  background-color: #1cbb8c;
  border-color: #1cbb8c;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-outline-success,
.dashboard-btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 187, 140, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-outline-success,
.dashboard-btn-check:checked + .dashboard-btn-outline-success,
.dashboard-btn-outline-success.dashboard-active,
.dashboard-btn-outline-success.dashboard-dropdown-toggle.dashboard-show,
.dashboard-btn-outline-success:active {
  background-color: #1cbb8c;
  border-color: #1cbb8c;
  color: #000;
}
.dashboard-btn-check:active + .dashboard-btn-outline-success:focus,
.dashboard-btn-check:checked + .dashboard-btn-outline-success:focus,
.dashboard-btn-outline-success.dashboard-active:focus,
.dashboard-btn-outline-success.dashboard-dropdown-toggle.dashboard-show:focus,
.dashboard-btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 187, 140, 0.5);
}
.dashboard-btn-outline-success.dashboard-disabled,
.dashboard-btn-outline-success:disabled {
  background-color: transparent;
  color: #1cbb8c;
}
.dashboard-btn-outline-info {
  border-color: #17a2b8;
  color: #17a2b8;
}
.dashboard-btn-outline-info:hover {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-outline-info,
.dashboard-btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-outline-info,
.dashboard-btn-check:checked + .dashboard-btn-outline-info,
.dashboard-btn-outline-info.dashboard-active,
.dashboard-btn-outline-info.dashboard-dropdown-toggle.dashboard-show,
.dashboard-btn-outline-info:active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-outline-info:focus,
.dashboard-btn-check:checked + .dashboard-btn-outline-info:focus,
.dashboard-btn-outline-info.dashboard-active:focus,
.dashboard-btn-outline-info.dashboard-dropdown-toggle.dashboard-show:focus,
.dashboard-btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.dashboard-btn-outline-info.dashboard-disabled,
.dashboard-btn-outline-info:disabled {
  background-color: transparent;
  color: #17a2b8;
}
.dashboard-btn-outline-warning {
  border-color: #fcb92c;
  color: #fcb92c;
}
.dashboard-btn-outline-warning:hover {
  background-color: #fcb92c;
  border-color: #fcb92c;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-outline-warning,
.dashboard-btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 185, 44, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-outline-warning,
.dashboard-btn-check:checked + .dashboard-btn-outline-warning,
.dashboard-btn-outline-warning.dashboard-active,
.dashboard-btn-outline-warning.dashboard-dropdown-toggle.dashboard-show,
.dashboard-btn-outline-warning:active {
  background-color: #fcb92c;
  border-color: #fcb92c;
  color: #000;
}
.dashboard-btn-check:active + .dashboard-btn-outline-warning:focus,
.dashboard-btn-check:checked + .dashboard-btn-outline-warning:focus,
.dashboard-btn-outline-warning.dashboard-active:focus,
.dashboard-btn-outline-warning.dashboard-dropdown-toggle.dashboard-show:focus,
.dashboard-btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 185, 44, 0.5);
}
.dashboard-btn-outline-warning.dashboard-disabled,
.dashboard-btn-outline-warning:disabled {
  background-color: transparent;
  color: #fcb92c;
}
.dashboard-btn-outline-danger {
  border-color: #dc3545;
  color: #dc3545;
}
.dashboard-btn-outline-danger:hover {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-outline-danger,
.dashboard-btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-outline-danger,
.dashboard-btn-check:checked + .dashboard-btn-outline-danger,
.dashboard-btn-outline-danger.dashboard-active,
.dashboard-btn-outline-danger.dashboard-dropdown-toggle.dashboard-show,
.dashboard-btn-outline-danger:active {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-outline-danger:focus,
.dashboard-btn-check:checked + .dashboard-btn-outline-danger:focus,
.dashboard-btn-outline-danger.dashboard-active:focus,
.dashboard-btn-outline-danger.dashboard-dropdown-toggle.dashboard-show:focus,
.dashboard-btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.dashboard-btn-outline-danger.dashboard-disabled,
.dashboard-btn-outline-danger:disabled {
  background-color: transparent;
  color: #dc3545;
}
.dashboard-btn-outline-light {
  border-color: #f5f7fb;
  color: #f5f7fb;
}
.dashboard-btn-outline-light:hover {
  background-color: #f5f7fb;
  border-color: #f5f7fb;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-outline-light,
.dashboard-btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 247, 251, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-outline-light,
.dashboard-btn-check:checked + .dashboard-btn-outline-light,
.dashboard-btn-outline-light.dashboard-active,
.dashboard-btn-outline-light.dashboard-dropdown-toggle.dashboard-show,
.dashboard-btn-outline-light:active {
  background-color: #f5f7fb;
  border-color: #f5f7fb;
  color: #000;
}
.dashboard-btn-check:active + .dashboard-btn-outline-light:focus,
.dashboard-btn-check:checked + .dashboard-btn-outline-light:focus,
.dashboard-btn-outline-light.dashboard-active:focus,
.dashboard-btn-outline-light.dashboard-dropdown-toggle.dashboard-show:focus,
.dashboard-btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 247, 251, 0.5);
}
.dashboard-btn-outline-light.dashboard-disabled,
.dashboard-btn-outline-light:disabled {
  background-color: transparent;
  color: #f5f7fb;
}
.dashboard-btn-outline-dark {
  border-color: #212529;
  color: #212529;
}
.dashboard-btn-outline-dark:hover {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-outline-dark,
.dashboard-btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-outline-dark,
.dashboard-btn-check:checked + .dashboard-btn-outline-dark,
.dashboard-btn-outline-dark.dashboard-active,
.dashboard-btn-outline-dark.dashboard-dropdown-toggle.dashboard-show,
.dashboard-btn-outline-dark:active {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-outline-dark:focus,
.dashboard-btn-check:checked + .dashboard-btn-outline-dark:focus,
.dashboard-btn-outline-dark.dashboard-active:focus,
.dashboard-btn-outline-dark.dashboard-dropdown-toggle.dashboard-show:focus,
.dashboard-btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5);
}
.dashboard-btn-outline-dark.dashboard-disabled,
.dashboard-btn-outline-dark:disabled {
  background-color: transparent;
  color: #212529;
}
.dashboard-btn-link {
  color: #ffa41e;
  font-weight: 400;
  text-decoration: none;
}
.dashboard-btn-link:hover {
  color: #ff9d33;
}
.dashboard-btn-link:focus,
.dashboard-btn-link:hover {
  text-decoration: underline;
}
.dashboard-btn-link.dashboard-disabled,
.dashboard-btn-link:disabled {
  color: #6c757d;
}
.dashboard-btn-group-lg > .dashboard-btn,
.dashboard-btn-lg {
  border-radius: 0.3rem;
  font-size: 0.925rem;
  padding: 0.4rem 1rem;
}
.dashboard-btn-group-sm > .dashboard-btn,
.dashboard-btn-sm {
  border-radius: 0.1rem;
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
}
.dashboard-fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-fade {
    transition: none;
  }
}
.dashboard-fade:not(.dashboard-show) {
  opacity: 0;
}
.dashboard-collapse:not(.dashboard-show) {
  display: none;
}
.dashboard-collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-collapsing {
    transition: none;
  }
}
.dashboard-collapsing.dashboard-collapse-horizontal {
  height: auto;
  transition: width 0.35s ease;
  width: 0;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-collapsing.dashboard-collapse-horizontal {
    transition: none;
  }
}
.dashboard-dropdown,
.dashboard-dropend,
.dashboard-dropstart,
.dashboard-dropup {
  position: relative;
}
.dashboard-dropdown-toggle {
  white-space: nowrap;
}
.dashboard-dropdown-toggle:after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: "";
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.dashboard-dropdown-toggle:empty:after {
  margin-left: 0;
}
.dashboard-dropdown-menu {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
  color: #495057;
  display: none;
  font-size: 0.875rem;
  list-style: none;
  margin: 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  z-index: 1000;
}
.dashboard-dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: 0.125rem;
  top: 100%;
}
.dashboard-dropdown-menu-start {
  --bs-position: start;
}
.dashboard-dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}
.dashboard-dropdown-menu-end {
  --bs-position: end;
}
.dashboard-dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}
@media (min-width: 576px) {
  .dashboard-dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dashboard-dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dashboard-dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dashboard-dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 768px) {
  .dashboard-dropdown-menu-md-start {
    --bs-position: start;
  }
  .dashboard-dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dashboard-dropdown-menu-md-end {
    --bs-position: end;
  }
  .dashboard-dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 992px) {
  .dashboard-dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dashboard-dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dashboard-dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dashboard-dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .dashboard-dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dashboard-dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dashboard-dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dashboard-dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1440px) {
  .dashboard-dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dashboard-dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dashboard-dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dashboard-dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
.dashboard-dropup .dashboard-dropdown-menu[data-bs-popper] {
  bottom: 100%;
  margin-bottom: 0.125rem;
  margin-top: 0;
  top: auto;
}
.dashboard-dropup .dashboard-dropdown-toggle:after {
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.dashboard-dropup .dashboard-dropdown-toggle:empty:after {
  margin-left: 0;
}
.dashboard-dropend .dashboard-dropdown-menu[data-bs-popper] {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto;
  top: 0;
}
.dashboard-dropend .dashboard-dropdown-toggle:after {
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-right: 0;
  border-top: 0.3em solid transparent;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.dashboard-dropend .dashboard-dropdown-toggle:empty:after {
  margin-left: 0;
}
.dashboard-dropend .dashboard-dropdown-toggle:after {
  vertical-align: 0;
}
.dashboard-dropstart .dashboard-dropdown-menu[data-bs-popper] {
  left: auto;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100%;
  top: 0;
}
.dashboard-dropstart .dashboard-dropdown-toggle:after {
  content: "";
  display: inline-block;
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.dashboard-dropstart .dashboard-dropdown-toggle:before {
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-top: 0.3em solid transparent;
  content: "";
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
}
.dashboard-dropstart .dashboard-dropdown-toggle:empty:after {
  margin-left: 0;
}
.dashboard-dropstart .dashboard-dropdown-toggle:before {
  vertical-align: 0;
}
.dashboard-dropdown-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
}
.dashboard-dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #212529;
  display: block;
  font-weight: 400;
  padding: 0.25rem 1rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
.dashboard-dropdown-item:focus,
.dashboard-dropdown-item:hover {
  background-color: #e9ecef;
  color: #1e2125;
  text-decoration: none;
}
.dashboard-dropdown-item.dashboard-active,
.dashboard-dropdown-item:active {
  background-color: #ff9d33;
  color: #fff;
  text-decoration: none;
}
.dashboard-dropdown-item.dashboard-disabled,
.dashboard-dropdown-item:disabled {
  background-color: transparent;
  color: #adb5bd;
  pointer-events: none;
}
.dashboard-dropdown-menu.dashboard-show {
  display: block;
}
.dashboard-dropdown-header {
  color: #6c757d;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}
.dashboard-dropdown-item-text {
  color: #212529;
  display: block;
  padding: 0.25rem 1rem;
}
.dashboard-dropdown-menu-dark {
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
  color: #dee2e6;
}
.dashboard-dropdown-menu-dark .dashboard-dropdown-item {
  color: #dee2e6;
}
.dashboard-dropdown-menu-dark .dashboard-dropdown-item:focus,
.dashboard-dropdown-menu-dark .dashboard-dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
}
.dashboard-dropdown-menu-dark .dashboard-dropdown-item.dashboard-active,
.dashboard-dropdown-menu-dark .dashboard-dropdown-item:active {
  background-color: #ff9d33;
  color: #fff;
}
.dashboard-dropdown-menu-dark .dashboard-dropdown-item.dashboard-disabled,
.dashboard-dropdown-menu-dark .dashboard-dropdown-item:disabled {
  color: #adb5bd;
}
.dashboard-dropdown-menu-dark .dashboard-dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dashboard-dropdown-menu-dark .dashboard-dropdown-item-text {
  color: #dee2e6;
}
.dashboard-dropdown-menu-dark .dashboard-dropdown-header {
  color: #adb5bd;
}
.dashboard-btn-group,
.dashboard-btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}
.dashboard-btn-group-vertical > .dashboard-btn,
.dashboard-btn-group > .dashboard-btn {
  flex: 1 1 auto;
  position: relative;
}
.dashboard-btn-group-vertical > .dashboard-btn-check:checked + .dashboard-btn,
.dashboard-btn-group-vertical > .dashboard-btn-check:focus + .dashboard-btn,
.dashboard-btn-group-vertical > .dashboard-btn.dashboard-active,
.dashboard-btn-group-vertical > .dashboard-btn:active,
.dashboard-btn-group-vertical > .dashboard-btn:focus,
.dashboard-btn-group-vertical > .dashboard-btn:hover,
.dashboard-btn-group > .dashboard-btn-check:checked + .dashboard-btn,
.dashboard-btn-group > .dashboard-btn-check:focus + .dashboard-btn,
.dashboard-btn-group > .dashboard-btn.dashboard-active,
.dashboard-btn-group > .dashboard-btn:active,
.dashboard-btn-group > .dashboard-btn:focus,
.dashboard-btn-group > .dashboard-btn:hover {
  z-index: 1;
}
.dashboard-btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.dashboard-btn-toolbar .dashboard-input-group {
  width: auto;
}
.dashboard-btn-group > .dashboard-btn-group:not(:first-child),
.dashboard-btn-group > .dashboard-btn:not(:first-child) {
  margin-left: -1px;
}
.dashboard-btn-group > .dashboard-btn-group:not(:last-child) > .dashboard-btn,
.dashboard-btn-group > .dashboard-btn:not(:last-child):not(.dashboard-dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.dashboard-btn-group > .dashboard-btn-group:not(:first-child) > .dashboard-btn,
.dashboard-btn-group > .dashboard-btn:nth-child(n + 3),
.dashboard-btn-group > :not(.dashboard-btn-check) + .dashboard-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.dashboard-dropdown-toggle-split {
  padding-left: 0.6375rem;
  padding-right: 0.6375rem;
}
.dashboard-dropdown-toggle-split:after,
.dashboard-dropend .dashboard-dropdown-toggle-split:after,
.dashboard-dropup .dashboard-dropdown-toggle-split:after {
  margin-left: 0;
}
.dashboard-dropstart .dashboard-dropdown-toggle-split:before {
  margin-right: 0;
}
.dashboard-btn-group-sm > .dashboard-btn + .dashboard-dropdown-toggle-split,
.dashboard-btn-sm + .dashboard-dropdown-toggle-split {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.dashboard-btn-group-lg > .dashboard-btn + .dashboard-dropdown-toggle-split,
.dashboard-btn-lg + .dashboard-dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.dashboard-btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.dashboard-btn-group-vertical > .dashboard-btn,
.dashboard-btn-group-vertical > .dashboard-btn-group {
  width: 100%;
}
.dashboard-btn-group-vertical > .dashboard-btn-group:not(:first-child),
.dashboard-btn-group-vertical > .dashboard-btn:not(:first-child) {
  margin-top: -1px;
}
.dashboard-btn-group-vertical > .dashboard-btn-group:not(:last-child) > .dashboard-btn,
.dashboard-btn-group-vertical > .dashboard-btn:not(:last-child):not(.dashboard-dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dashboard-btn-group-vertical > .dashboard-btn-group:not(:first-child) > .dashboard-btn,
.dashboard-btn-group-vertical > .dashboard-btn ~ .dashboard-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dashboard-nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.dashboard-nav-link {
  color: #ffa41e;
  display: block;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-nav-link {
    transition: none;
  }
}
.dashboard-nav-link:focus,
.dashboard-nav-link:hover {
  color: #ff9d33;
  text-decoration: none;
}
.dashboard-nav-link.dashboard-disabled {
  color: #6c757d;
  cursor: default;
  pointer-events: none;
}
.dashboard-nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.dashboard-nav-tabs .dashboard-nav-link {
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  margin-bottom: -1px;
}
.dashboard-nav-tabs .dashboard-nav-link:focus,
.dashboard-nav-tabs .dashboard-nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.dashboard-nav-tabs .dashboard-nav-link.dashboard-disabled {
  background-color: transparent;
  border-color: transparent;
  color: #6c757d;
}
.dashboard-nav-tabs .dashboard-nav-item.dashboard-show .dashboard-nav-link,
.dashboard-nav-tabs .dashboard-nav-link.dashboard-active {
  background-color: #f5f7fb;
  border-color: #dee2e6 #dee2e6 #f5f7fb;
  color: #495057;
}
.dashboard-nav-tabs .dashboard-dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}
.dashboard-nav-pills .dashboard-nav-link {
  background: none;
  border: 0;
  border-radius: 0.2rem;
}
.dashboard-nav-pills .dashboard-nav-link.dashboard-active,
.dashboard-nav-pills .dashboard-show > .dashboard-nav-link {
  background-color: #ffa41e;
  color: #fff;
}
.dashboard-nav-fill .dashboard-nav-item,
.dashboard-nav-fill > .dashboard-nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.dashboard-nav-justified .dashboard-nav-item,
.dashboard-nav-justified > .dashboard-nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.dashboard-nav-fill .dashboard-nav-item .dashboard-nav-link,
.dashboard-nav-justified .dashboard-nav-item .dashboard-nav-link {
  width: 100%;
}
.dashboard-tab-content > .dashboard-tab-pane {
  display: none;
}
.dashboard-tab-content > .dashboard-active {
  display: block;
}
.dashboard-navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.875rem 1.375rem;
  position: relative;
}
.dashboard-navbar > .dashboard-container,
.dashboard-navbar > .dashboard-container-fluid,
.dashboard-navbar > .dashboard-container-lg,
.dashboard-navbar > .dashboard-container-md,
.dashboard-navbar > .dashboard-container-sm,
.dashboard-navbar > .dashboard-container-xl {
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
}
.dashboard-navbar-brand {
  margin-right: 1rem;
  padding-bottom: 0.875rem;
  padding-top: 0.875rem;
  white-space: nowrap;
}
.dashboard-navbar-brand:focus,
.dashboard-navbar-brand:hover {
  text-decoration: none;
}
.dashboard-navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.dashboard-navbar-nav .dashboard-nav-link {
  padding-left: 0;
  padding-right: 0;
}
.dashboard-navbar-nav .dashboard-dropdown-menu {
  position: static;
}
.dashboard-navbar-text {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.dashboard-navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}
.dashboard-navbar-toggler {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  font-size: 0.925rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dashboard-navbar-toggler {
    transition: none;
  }
}
.dashboard-navbar-toggler:hover {
  text-decoration: none;
}
.dashboard-navbar-toggler:focus {
  box-shadow: 0 0 0 0.2rem;
  outline: 0;
  text-decoration: none;
}
.dashboard-navbar-toggler-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}
.dashboard-navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .dashboard-navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .dashboard-navbar-expand-sm .dashboard-navbar-nav {
    flex-direction: row;
  }
  .dashboard-navbar-expand-sm .dashboard-navbar-nav .dashboard-dropdown-menu {
    position: absolute;
  }
  .dashboard-navbar-expand-sm .dashboard-navbar-nav .dashboard-nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .dashboard-navbar-expand-sm .dashboard-navbar-nav-scroll {
    overflow: visible;
  }
  .dashboard-navbar-expand-sm .dashboard-navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .dashboard-navbar-expand-sm .dashboard-navbar-toggler,
  .dashboard-navbar-expand-sm .dashboard-offcanvas-header {
    display: none;
  }
  .dashboard-navbar-expand-sm .dashboard-offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    flex-grow: 1;
    position: inherit;
    transform: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .dashboard-navbar-expand-sm .dashboard-offcanvas-bottom,
  .dashboard-navbar-expand-sm .dashboard-offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .dashboard-navbar-expand-sm .dashboard-offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .dashboard-navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .dashboard-navbar-expand-md .dashboard-navbar-nav {
    flex-direction: row;
  }
  .dashboard-navbar-expand-md .dashboard-navbar-nav .dashboard-dropdown-menu {
    position: absolute;
  }
  .dashboard-navbar-expand-md .dashboard-navbar-nav .dashboard-nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .dashboard-navbar-expand-md .dashboard-navbar-nav-scroll {
    overflow: visible;
  }
  .dashboard-navbar-expand-md .dashboard-navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .dashboard-navbar-expand-md .dashboard-navbar-toggler,
  .dashboard-navbar-expand-md .dashboard-offcanvas-header {
    display: none;
  }
  .dashboard-navbar-expand-md .dashboard-offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    flex-grow: 1;
    position: inherit;
    transform: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .dashboard-navbar-expand-md .dashboard-offcanvas-bottom,
  .dashboard-navbar-expand-md .dashboard-offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .dashboard-navbar-expand-md .dashboard-offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 992px) {
  .dashboard-navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .dashboard-navbar-expand-lg .dashboard-navbar-nav {
    flex-direction: row;
  }
  .dashboard-navbar-expand-lg .dashboard-navbar-nav .dashboard-dropdown-menu {
    position: absolute;
  }
  .dashboard-navbar-expand-lg .dashboard-navbar-nav .dashboard-nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .dashboard-navbar-expand-lg .dashboard-navbar-nav-scroll {
    overflow: visible;
  }
  .dashboard-navbar-expand-lg .dashboard-navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .dashboard-navbar-expand-lg .dashboard-navbar-toggler,
  .dashboard-navbar-expand-lg .dashboard-offcanvas-header {
    display: none;
  }
  .dashboard-navbar-expand-lg .dashboard-offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    flex-grow: 1;
    position: inherit;
    transform: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .dashboard-navbar-expand-lg .dashboard-offcanvas-bottom,
  .dashboard-navbar-expand-lg .dashboard-offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .dashboard-navbar-expand-lg .dashboard-offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .dashboard-navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .dashboard-navbar-expand-xl .dashboard-navbar-nav {
    flex-direction: row;
  }
  .dashboard-navbar-expand-xl .dashboard-navbar-nav .dashboard-dropdown-menu {
    position: absolute;
  }
  .dashboard-navbar-expand-xl .dashboard-navbar-nav .dashboard-nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .dashboard-navbar-expand-xl .dashboard-navbar-nav-scroll {
    overflow: visible;
  }
  .dashboard-navbar-expand-xl .dashboard-navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .dashboard-navbar-expand-xl .dashboard-navbar-toggler,
  .dashboard-navbar-expand-xl .dashboard-offcanvas-header {
    display: none;
  }
  .dashboard-navbar-expand-xl .dashboard-offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    flex-grow: 1;
    position: inherit;
    transform: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .dashboard-navbar-expand-xl .dashboard-offcanvas-bottom,
  .dashboard-navbar-expand-xl .dashboard-offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .dashboard-navbar-expand-xl .dashboard-offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 1440px) {
  .dashboard-navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .dashboard-navbar-expand-xxl .dashboard-navbar-nav {
    flex-direction: row;
  }
  .dashboard-navbar-expand-xxl .dashboard-navbar-nav .dashboard-dropdown-menu {
    position: absolute;
  }
  .dashboard-navbar-expand-xxl .dashboard-navbar-nav .dashboard-nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .dashboard-navbar-expand-xxl .dashboard-navbar-nav-scroll {
    overflow: visible;
  }
  .dashboard-navbar-expand-xxl .dashboard-navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .dashboard-navbar-expand-xxl .dashboard-navbar-toggler,
  .dashboard-navbar-expand-xxl .dashboard-offcanvas-header {
    display: none;
  }
  .dashboard-navbar-expand-xxl .dashboard-offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    flex-grow: 1;
    position: inherit;
    transform: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }
  .dashboard-navbar-expand-xxl .dashboard-offcanvas-bottom,
  .dashboard-navbar-expand-xxl .dashboard-offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }
  .dashboard-navbar-expand-xxl .dashboard-offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
.dashboard-navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.dashboard-navbar-expand .dashboard-navbar-nav {
  flex-direction: row;
}
.dashboard-navbar-expand .dashboard-navbar-nav .dashboard-dropdown-menu {
  position: absolute;
}
.dashboard-navbar-expand .dashboard-navbar-nav .dashboard-nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.dashboard-navbar-expand .dashboard-navbar-nav-scroll {
  overflow: visible;
}
.dashboard-navbar-expand .dashboard-navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.dashboard-navbar-expand .dashboard-navbar-toggler,
.dashboard-navbar-expand .dashboard-offcanvas-header {
  display: none;
}
.dashboard-navbar-expand .dashboard-offcanvas {
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  bottom: 0;
  flex-grow: 1;
  position: inherit;
  transform: none;
  transition: none;
  visibility: visible !important;
  z-index: 1000;
}
.dashboard-navbar-expand .dashboard-offcanvas-bottom,
.dashboard-navbar-expand .dashboard-offcanvas-top {
  border-bottom: 0;
  border-top: 0;
  height: auto;
}
.dashboard-navbar-expand .dashboard-offcanvas-body {
  display: flex;
  flex-grow: 0;
  overflow-y: visible;
  padding: 0;
}
.dashboard-navbar-light .dashboard-navbar-brand,
.dashboard-navbar-light .dashboard-navbar-brand:focus,
.dashboard-navbar-light .dashboard-navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.dashboard-navbar-light .dashboard-navbar-nav .dashboard-nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.dashboard-navbar-light .dashboard-navbar-nav .dashboard-nav-link:focus,
.dashboard-navbar-light .dashboard-navbar-nav .dashboard-nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.dashboard-navbar-light .dashboard-navbar-nav .dashboard-nav-link.dashboard-disabled {
  color: rgba(0, 0, 0, 0.3);
}
.dashboard-navbar-light .dashboard-navbar-nav .dashboard-nav-link.dashboard-active,
.dashboard-navbar-light .dashboard-navbar-nav .dashboard-show > .dashboard-nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.dashboard-navbar-light .dashboard-navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.55);
}
.dashboard-navbar-light .dashboard-navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.dashboard-navbar-light .dashboard-navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.dashboard-navbar-light .dashboard-navbar-text a,
.dashboard-navbar-light .dashboard-navbar-text a:focus,
.dashboard-navbar-light .dashboard-navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.dashboard-navbar-dark .dashboard-navbar-brand,
.dashboard-navbar-dark .dashboard-navbar-brand:focus,
.dashboard-navbar-dark .dashboard-navbar-brand:hover {
  color: #fff;
}
.dashboard-navbar-dark .dashboard-navbar-nav .dashboard-nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.dashboard-navbar-dark .dashboard-navbar-nav .dashboard-nav-link:focus,
.dashboard-navbar-dark .dashboard-navbar-nav .dashboard-nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.dashboard-navbar-dark .dashboard-navbar-nav .dashboard-nav-link.dashboard-disabled {
  color: rgba(255, 255, 255, 0.25);
}
.dashboard-navbar-dark .dashboard-navbar-nav .dashboard-nav-link.dashboard-active,
.dashboard-navbar-dark .dashboard-navbar-nav .dashboard-show > .dashboard-nav-link {
  color: #fff;
}
.dashboard-navbar-dark .dashboard-navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.55);
}
.dashboard-navbar-dark .dashboard-navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.dashboard-navbar-dark .dashboard-navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.dashboard-navbar-dark .dashboard-navbar-text a,
.dashboard-navbar-dark .dashboard-navbar-text a:focus,
.dashboard-navbar-dark .dashboard-navbar-text a:hover {
  color: #fff;
}
.dashboard-card {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
}
.dashboard-card > hr {
  margin-left: 0;
  margin-right: 0;
}
.dashboard-card > .dashboard-list-group {
  border-bottom: inherit;
  border-top: inherit;
}
.dashboard-card > .dashboard-list-group:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-top-width: 0;
}
.dashboard-card > .dashboard-list-group:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-width: 0;
}
.dashboard-card > .dashboard-card-header + .dashboard-list-group,
.dashboard-card > .dashboard-list-group + .dashboard-card-footer {
  border-top: 0;
}
.dashboard-card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.dashboard-card-title {
  margin-bottom: 0.5rem;
}
.dashboard-card-subtitle {
  margin-top: -0.25rem;
}
.dashboard-card-subtitle,
.dashboard-card-text:last-child {
  margin-bottom: 0;
}
.dashboard-card-link:hover {
  text-decoration: none;
}
.dashboard-card-link + .dashboard-card-link {
  margin-left: 1.25rem;
}
.dashboard-card-header {
  background-color: #fff;
  border-bottom: 0 solid transparent;
  margin-bottom: 0;
  padding: 1rem 1.25rem;
}
.dashboard-card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}
.dashboard-card-footer {
  background-color: #fff;
  border-top: 0 solid transparent;
  padding: 1rem 1.25rem;
}
.dashboard-card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}
.dashboard-card-header-tabs {
  border-bottom: 0;
  margin-bottom: -1rem;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.dashboard-card-header-tabs .dashboard-nav-link.dashboard-active {
  background-color: #fff;
  border-bottom-color: #fff;
}
.dashboard-card-header-pills {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.dashboard-card-img-overlay {
  border-radius: 0.25rem;
  bottom: 0;
  left: 0;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
}
.dashboard-card-img,
.dashboard-card-img-bottom,
.dashboard-card-img-top {
  width: 100%;
}
.dashboard-card-img,
.dashboard-card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.dashboard-card-img,
.dashboard-card-img-bottom {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.dashboard-card-group > .dashboard-card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .dashboard-card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .dashboard-card-group > .dashboard-card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .dashboard-card-group > .dashboard-card + .dashboard-card {
    border-left: 0;
    margin-left: 0;
  }
  .dashboard-card-group > .dashboard-card:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .dashboard-card-group > .dashboard-card:not(:last-child) .dashboard-card-header,
  .dashboard-card-group > .dashboard-card:not(:last-child) .dashboard-card-img-top {
    border-top-right-radius: 0;
  }
  .dashboard-card-group > .dashboard-card:not(:last-child) .dashboard-card-footer,
  .dashboard-card-group > .dashboard-card:not(:last-child) .dashboard-card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .dashboard-card-group > .dashboard-card:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .dashboard-card-group > .dashboard-card:not(:first-child) .dashboard-card-header,
  .dashboard-card-group > .dashboard-card:not(:first-child) .dashboard-card-img-top {
    border-top-left-radius: 0;
  }
  .dashboard-card-group > .dashboard-card:not(:first-child) .dashboard-card-footer,
  .dashboard-card-group > .dashboard-card:not(:first-child) .dashboard-card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.dashboard-badge {
  border-radius: 0.2rem;
  color: #fff;
  display: inline-block;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  padding: 0.3em 0.45em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.dashboard-badge:empty {
  display: none;
}
.dashboard-btn .dashboard-badge {
  position: relative;
  top: -1px;
}
.dashboard-list-group {
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}
.dashboard-list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}
.dashboard-list-group-numbered > li:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.dashboard-list-group-item-action {
  color: #495057;
  text-align: inherit;
  width: 100%;
}
.dashboard-list-group-item-action:focus,
.dashboard-list-group-item-action:hover {
  background-color: #f8f9fa;
  color: #495057;
  text-decoration: none;
  z-index: 1;
}
.dashboard-list-group-item-action:active {
  background-color: #e9ecef;
  color: #495057;
}
.dashboard-list-group-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: #212529;
  display: block;
  padding: 0.5rem 1rem;
  position: relative;
}
.dashboard-list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.dashboard-list-group-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.dashboard-list-group-item.dashboard-disabled,
.dashboard-list-group-item:disabled {
  background-color: #fff;
  color: #6c757d;
  pointer-events: none;
}
.dashboard-list-group-item.dashboard-active {
  background-color: #3b7ddd;
  border-color: #3b7ddd;
  color: #fff;
  z-index: 2;
}
.dashboard-list-group-item + .dashboard-list-group-item {
  border-top-width: 0;
}
.dashboard-list-group-item + .dashboard-list-group-item.dashboard-active {
  border-top-width: 1px;
  margin-top: -1px;
}
.dashboard-list-group-horizontal {
  flex-direction: row;
}
.dashboard-list-group-horizontal > .dashboard-list-group-item:first-child {
  border-bottom-left-radius: 0.2rem;
  border-top-right-radius: 0;
}
.dashboard-list-group-horizontal > .dashboard-list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.2rem;
}
.dashboard-list-group-horizontal > .dashboard-list-group-item.dashboard-active {
  margin-top: 0;
}
.dashboard-list-group-horizontal > .dashboard-list-group-item + .dashboard-list-group-item {
  border-left-width: 0;
  border-top-width: 1px;
}
.dashboard-list-group-horizontal
  > .dashboard-list-group-item
  + .dashboard-list-group-item.dashboard-active {
  border-left-width: 1px;
  margin-left: -1px;
}
@media (min-width: 576px) {
  .dashboard-list-group-horizontal-sm {
    flex-direction: row;
  }
  .dashboard-list-group-horizontal-sm > .dashboard-list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .dashboard-list-group-horizontal-sm > .dashboard-list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.2rem;
  }
  .dashboard-list-group-horizontal-sm > .dashboard-list-group-item.dashboard-active {
    margin-top: 0;
  }
  .dashboard-list-group-horizontal-sm > .dashboard-list-group-item + .dashboard-list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .dashboard-list-group-horizontal-sm
    > .dashboard-list-group-item
    + .dashboard-list-group-item.dashboard-active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
@media (min-width: 768px) {
  .dashboard-list-group-horizontal-md {
    flex-direction: row;
  }
  .dashboard-list-group-horizontal-md > .dashboard-list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .dashboard-list-group-horizontal-md > .dashboard-list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.2rem;
  }
  .dashboard-list-group-horizontal-md > .dashboard-list-group-item.dashboard-active {
    margin-top: 0;
  }
  .dashboard-list-group-horizontal-md > .dashboard-list-group-item + .dashboard-list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .dashboard-list-group-horizontal-md
    > .dashboard-list-group-item
    + .dashboard-list-group-item.dashboard-active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
@media (min-width: 992px) {
  .dashboard-list-group-horizontal-lg {
    flex-direction: row;
  }
  .dashboard-list-group-horizontal-lg > .dashboard-list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .dashboard-list-group-horizontal-lg > .dashboard-list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.2rem;
  }
  .dashboard-list-group-horizontal-lg > .dashboard-list-group-item.dashboard-active {
    margin-top: 0;
  }
  .dashboard-list-group-horizontal-lg > .dashboard-list-group-item + .dashboard-list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .dashboard-list-group-horizontal-lg
    > .dashboard-list-group-item
    + .dashboard-list-group-item.dashboard-active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
@media (min-width: 1200px) {
  .dashboard-list-group-horizontal-xl {
    flex-direction: row;
  }
  .dashboard-list-group-horizontal-xl > .dashboard-list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .dashboard-list-group-horizontal-xl > .dashboard-list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.2rem;
  }
  .dashboard-list-group-horizontal-xl > .dashboard-list-group-item.dashboard-active {
    margin-top: 0;
  }
  .dashboard-list-group-horizontal-xl > .dashboard-list-group-item + .dashboard-list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .dashboard-list-group-horizontal-xl
    > .dashboard-list-group-item
    + .dashboard-list-group-item.dashboard-active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
@media (min-width: 1440px) {
  .dashboard-list-group-horizontal-xxl {
    flex-direction: row;
  }
  .dashboard-list-group-horizontal-xxl > .dashboard-list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .dashboard-list-group-horizontal-xxl > .dashboard-list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.2rem;
  }
  .dashboard-list-group-horizontal-xxl > .dashboard-list-group-item.dashboard-active {
    margin-top: 0;
  }
  .dashboard-list-group-horizontal-xxl > .dashboard-list-group-item + .dashboard-list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }
  .dashboard-list-group-horizontal-xxl
    > .dashboard-list-group-item
    + .dashboard-list-group-item.dashboard-active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}
.dashboard-list-group-flush {
  border-radius: 0;
}
.dashboard-list-group-flush > .dashboard-list-group-item {
  border-width: 0 0 1px;
}
.dashboard-list-group-flush > .dashboard-list-group-item:last-child {
  border-bottom-width: 0;
}
.dashboard-list-group-item-primary {
  background-color: #d8e5f8;
  color: #ffa41e;
}
.dashboard-list-group-item-primary.dashboard-list-group-item-action:focus,
.dashboard-list-group-item-primary.dashboard-list-group-item-action:hover {
  background-color: #c2cedf;
  color: #ff9d33;
}
.dashboard-list-group-item-primary.dashboard-list-group-item-action.dashboard-active {
  background-color: #ff9d33;
  border-color: #ff9d33;
  color: #fff;
}
.dashboard-list-group-item-secondary {
  background-color: #e2e3e5;
  color: #41464b;
}
.dashboard-list-group-item-secondary.dashboard-list-group-item-action:focus,
.dashboard-list-group-item-secondary.dashboard-list-group-item-action:hover {
  background-color: #cbccce;
  color: #41464b;
}
.dashboard-list-group-item-secondary.dashboard-list-group-item-action.dashboard-active {
  background-color: #41464b;
  border-color: #41464b;
  color: #fff;
}
.dashboard-list-group-item-success {
  background-color: #d2f1e8;
  color: #117054;
}
.dashboard-list-group-item-success.dashboard-list-group-item-action:focus,
.dashboard-list-group-item-success.dashboard-list-group-item-action:hover {
  background-color: #bdd9d1;
  color: #117054;
}
.dashboard-list-group-item-success.dashboard-list-group-item-action.dashboard-active {
  background-color: #117054;
  border-color: #117054;
  color: #fff;
}
.dashboard-list-group-item-info {
  background-color: #d1ecf1;
  color: #0e616e;
}
.dashboard-list-group-item-info.dashboard-list-group-item-action:focus,
.dashboard-list-group-item-info.dashboard-list-group-item-action:hover {
  background-color: #bcd4d9;
  color: #0e616e;
}
.dashboard-list-group-item-info.dashboard-list-group-item-action.dashboard-active {
  background-color: #0e616e;
  border-color: #0e616e;
  color: #fff;
}
.dashboard-list-group-item-warning {
  background-color: #fef1d5;
  color: #976f1a;
}
.dashboard-list-group-item-warning.dashboard-list-group-item-action:focus,
.dashboard-list-group-item-warning.dashboard-list-group-item-action:hover {
  background-color: #e5d9c0;
  color: #976f1a;
}
.dashboard-list-group-item-warning.dashboard-list-group-item-action.dashboard-active {
  background-color: #976f1a;
  border-color: #976f1a;
  color: #fff;
}
.dashboard-list-group-item-danger {
  background-color: #f8d7da;
  color: #842029;
}
.dashboard-list-group-item-danger.dashboard-list-group-item-action:focus,
.dashboard-list-group-item-danger.dashboard-list-group-item-action:hover {
  background-color: #dfc2c4;
  color: #842029;
}
.dashboard-list-group-item-danger.dashboard-list-group-item-action.dashboard-active {
  background-color: #842029;
  border-color: #842029;
  color: #fff;
}
.dashboard-list-group-item-light {
  background-color: #fdfdfe;
  color: #626364;
}
.dashboard-list-group-item-light.dashboard-list-group-item-action:focus,
.dashboard-list-group-item-light.dashboard-list-group-item-action:hover {
  background-color: #e4e4e5;
  color: #626364;
}
.dashboard-list-group-item-light.dashboard-list-group-item-action.dashboard-active {
  background-color: #626364;
  border-color: #626364;
  color: #fff;
}
.dashboard-list-group-item-dark {
  background-color: #d3d3d4;
  color: #141619;
}
.dashboard-list-group-item-dark.dashboard-list-group-item-action:focus,
.dashboard-list-group-item-dark.dashboard-list-group-item-action:hover {
  background-color: #bebebf;
  color: #141619;
}
.dashboard-list-group-item-dark.dashboard-list-group-item-action.dashboard-active {
  background-color: #141619;
  border-color: #141619;
  color: #fff;
}
.dashboard-btn-close {
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.dashboard-org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.2rem;
  box-sizing: content-box;
  color: #000;
  height: 1em;
  opacity: 0.5;
  padding: 0.25em;
  width: 1em;
}
.dashboard-btn-close:hover {
  color: #000;
  opacity: 0.75;
  text-decoration: none;
}
.dashboard-btn-close:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 51, 0.25);
  opacity: 1;
  outline: 0;
}
.dashboard-btn-close.dashboard-disabled,
.dashboard-btn-close:disabled {
  opacity: 0.25;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.dashboard-btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.dashboard-clearfix:after {
  clear: both;
  content: "";
  display: block;
}
.dashboard-link-primary {
  color: #ffa41e;
}
.dashboard-link-primary:focus,
.dashboard-link-primary:hover {
  color: #ff9d33;
}
.dashboard-link-secondary {
  color: #6c757d;
}
.dashboard-link-secondary:focus,
.dashboard-link-secondary:hover {
  color: #565e64;
}
.dashboard-link-success {
  color: #1cbb8c;
}
.dashboard-link-success:focus,
.dashboard-link-success:hover {
  color: #49c9a3;
}
.dashboard-link-info {
  color: #17a2b8;
}
.dashboard-link-info:focus,
.dashboard-link-info:hover {
  color: #128293;
}
.dashboard-link-warning {
  color: #fcb92c;
}
.dashboard-link-warning:focus,
.dashboard-link-warning:hover {
  color: #fdc756;
}
.dashboard-link-danger {
  color: #dc3545;
}
.dashboard-link-danger:focus,
.dashboard-link-danger:hover {
  color: #b02a37;
}
.dashboard-link-light {
  color: #f5f7fb;
}
.dashboard-link-light:focus,
.dashboard-link-light:hover {
  color: #f7f9fc;
}
.dashboard-link-dark {
  color: #212529;
}
.dashboard-link-dark:focus,
.dashboard-link-dark:hover {
  color: #1a1e21;
}
.dashboard-ratio {
  position: relative;
  width: 100%;
}
.dashboard-ratio:before {
  content: "";
  display: block;
  padding-top: var(--bs-aspect-ratio);
}
.dashboard-ratio > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.dashboard-ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.dashboard-ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.dashboard-ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.dashboard-ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}
.dashboard-fixed-top {
  top: 0;
}
.dashboard-fixed-bottom,
.dashboard-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}
.dashboard-fixed-bottom {
  bottom: 0;
}
.dashboard-sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .dashboard-sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .dashboard-sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .dashboard-sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .dashboard-sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1440px) {
  .dashboard-sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.dashboard-hstack {
  align-items: center;
  flex-direction: row;
}
.dashboard-hstack,
.dashboard-vstack {
  align-self: stretch;
  display: flex;
}
.dashboard-vstack {
  flex: 1 1 auto;
  flex-direction: column;
}
.dashboard-visually-hidden,
.dashboard-visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
.dashboard-stretched-link:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.dashboard-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard-vr {
  align-self: stretch;
  background-color: currentColor;
  display: inline-block;
  min-height: 1em;
  opacity: 0.25;
  width: 1px;
}
.dashboard-align-baseline {
  vertical-align: baseline !important;
}
.dashboard-align-top {
  vertical-align: top !important;
}
.dashboard-align-middle {
  vertical-align: middle !important;
}
.dashboard-align-bottom {
  vertical-align: bottom !important;
}
.dashboard-align-text-bottom {
  vertical-align: text-bottom !important;
}
.dashboard-align-text-top {
  vertical-align: text-top !important;
}
.dashboard-float-start {
  float: left !important;
}
.dashboard-float-end {
  float: right !important;
}
.dashboard-float-none {
  float: none !important;
}
.dashboard-opacity-0 {
  opacity: 0 !important;
}
.dashboard-opacity-25 {
  opacity: 0.25 !important;
}
.dashboard-opacity-50 {
  opacity: 0.5 !important;
}
.dashboard-opacity-75 {
  opacity: 0.75 !important;
}
.dashboard-opacity-100 {
  opacity: 1 !important;
}
.dashboard-overflow-auto {
  overflow: auto !important;
}
.dashboard-overflow-hidden {
  overflow: hidden !important;
}
.dashboard-overflow-visible {
  overflow: visible !important;
}
.dashboard-overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.dashboard-shadow {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05) !important;
}
.dashboard-shadow-sm {
  box-shadow: 0 0.05rem 0.2rem rgba(0, 0, 0, 0.05) !important;
}
.dashboard-shadow-lg {
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.05) !important;
}
.dashboard-shadow-none {
  box-shadow: none !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.dashboard-top-0 {
  top: 0 !important;
}
.dashboard-top-50 {
  top: 50% !important;
}
.dashboard-top-100 {
  top: 100% !important;
}
.dashboard-bottom-0 {
  bottom: 0 !important;
}
.dashboard-bottom-50 {
  bottom: 50% !important;
}
.dashboard-bottom-100 {
  bottom: 100% !important;
}
.dashboard-start-0 {
  left: 0 !important;
}
.dashboard-start-50 {
  left: 50% !important;
}
.dashboard-start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.dashboard-translate-middle {
  transform: translate(-50%, -50%) !important;
}
.dashboard-translate-middle-x {
  transform: translateX(-50%) !important;
}
.dashboard-translate-middle-y {
  transform: translateY(-50%) !important;
}
.dashboard-border {
  border: 1px solid #dee2e6 !important;
}
.dashboard-border-0 {
  border: 0 !important;
}
.dashboard-border-top {
  border-top: 1px solid #dee2e6 !important;
}
.dashboard-border-top-0 {
  border-top: 0 !important;
}
.dashboard-border-end {
  border-right: 1px solid #dee2e6 !important;
}
.dashboard-border-end-0 {
  border-right: 0 !important;
}
.dashboard-border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.dashboard-border-bottom-0 {
  border-bottom: 0 !important;
}
.dashboard-border-start {
  border-left: 1px solid #dee2e6 !important;
}
.dashboard-border-start-0 {
  border-left: 0 !important;
}
.dashboard-border-primary {
  border-color: #ffa41e !important;
}
.dashboard-border-secondary {
  border-color: #6c757d !important;
}
.dashboard-border-success {
  border-color: #1cbb8c !important;
}
.dashboard-border-info {
  border-color: #17a2b8 !important;
}
.dashboard-border-warning {
  border-color: #fcb92c !important;
}
.dashboard-border-danger {
  border-color: #dc3545 !important;
}
.dashboard-border-light {
  border-color: #f5f7fb !important;
}
.dashboard-border-dark {
  border-color: #212529 !important;
}
.dashboard-border-white {
  border-color: #fff !important;
}
.dashboard-border-1 {
  border-width: 1px !important;
}
.dashboard-border-2 {
  border-width: 2px !important;
}
.dashboard-border-3 {
  border-width: 3px !important;
}
.dashboard-border-4 {
  border-width: 4px !important;
}
.dashboard-border-5 {
  border-width: 5px !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.dashboard-mw-100 {
  max-width: 100% !important;
}
.dashboard-vw-100 {
  width: 100vw !important;
}
.dashboard-min-vw-100 {
  min-width: 100vw !important;
}
.dashboard-h-25 {
  height: 25% !important;
}
.dashboard-h-50 {
  height: 50% !important;
}
.dashboard-h-75 {
  height: 75% !important;
}
.dashboard-h-100 {
  height: 100% !important;
}
.dashboard-h-auto {
  height: auto !important;
}
.dashboard-mh-100 {
  max-height: 100% !important;
}
.dashboard-vh-100 {
  height: 100vh !important;
}
.dashboard-min-vh-100 {
  min-height: 100vh !important;
}
.dashboard-flex-fill {
  flex: 1 1 auto !important;
}
.dashboard-flex-row {
  flex-direction: row !important;
}
.dashboard-flex-column {
  flex-direction: column !important;
}
.dashboard-flex-row-reverse {
  flex-direction: row-reverse !important;
}
.dashboard-flex-column-reverse {
  flex-direction: column-reverse !important;
}
.dashboard-flex-grow-0 {
  flex-grow: 0 !important;
}
.dashboard-flex-grow-1 {
  flex-grow: 1 !important;
}
.dashboard-flex-shrink-0 {
  flex-shrink: 0 !important;
}
.dashboard-flex-shrink-1 {
  flex-shrink: 1 !important;
}
.dashboard-flex-wrap {
  flex-wrap: wrap !important;
}
.dashboard-flex-nowrap {
  flex-wrap: nowrap !important;
}
.dashboard-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.dashboard-gap-0 {
  gap: 0 !important;
}
.dashboard-gap-1 {
  gap: 0.25rem !important;
}
.dashboard-gap-2 {
  gap: 0.5rem !important;
}
.dashboard-gap-3 {
  gap: 1rem !important;
}
.dashboard-gap-4 {
  gap: 1.5rem !important;
}
.dashboard-gap-5 {
  gap: 3rem !important;
}
.dashboard-gap-6 {
  gap: 4.5rem !important;
}
.dashboard-gap-7 {
  gap: 6rem !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.dashboard-align-items-start {
  align-items: flex-start !important;
}
.dashboard-align-items-end {
  align-items: flex-end !important;
}
.dashboard-align-items-center {
  align-items: center !important;
}
.dashboard-align-items-baseline {
  align-items: baseline !important;
}
.dashboard-align-items-stretch {
  align-items: stretch !important;
}
.dashboard-align-content-start {
  align-content: flex-start !important;
}
.dashboard-align-content-end {
  align-content: flex-end !important;
}
.dashboard-align-content-center {
  align-content: center !important;
}
.dashboard-align-content-between {
  align-content: space-between !important;
}
.dashboard-align-content-around {
  align-content: space-around !important;
}
.dashboard-align-content-stretch {
  align-content: stretch !important;
}
.dashboard-align-self-auto {
  align-self: auto !important;
}
.dashboard-align-self-start {
  align-self: flex-start !important;
}
.dashboard-align-self-end {
  align-self: flex-end !important;
}
.dashboard-align-self-center {
  align-self: center !important;
}
.dashboard-align-self-baseline {
  align-self: baseline !important;
}
.dashboard-align-self-stretch {
  align-self: stretch !important;
}
.dashboard-order-first {
  order: -1 !important;
}
.dashboard-order-0 {
  order: 0 !important;
}
.dashboard-order-1 {
  order: 1 !important;
}
.dashboard-order-2 {
  order: 2 !important;
}
.dashboard-order-3 {
  order: 3 !important;
}
.dashboard-order-4 {
  order: 4 !important;
}
.dashboard-order-5 {
  order: 5 !important;
}
.dashboard-order-last {
  order: 6 !important;
}
.dashboard-m-0 {
  margin: 0 !important;
}
.dashboard-m-1 {
  margin: 0.25rem !important;
}
.dashboard-m-2 {
  margin: 0.5rem !important;
}
.dashboard-m-3 {
  margin: 1rem !important;
}
.dashboard-m-4 {
  margin: 1.5rem !important;
}
.dashboard-m-5 {
  margin: 3rem !important;
}
.dashboard-m-6 {
  margin: 4.5rem !important;
}
.dashboard-m-7 {
  margin: 6rem !important;
}
.dashboard-m-auto {
  margin: auto !important;
}
.dashboard-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.dashboard-mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.dashboard-mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.dashboard-mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.dashboard-mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.dashboard-mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.dashboard-mx-6 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}
.dashboard-mx-7 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}
.dashboard-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.dashboard-my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.dashboard-my-1 {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}
.dashboard-my-2 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.dashboard-my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
.dashboard-my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}
.dashboard-my-5 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}
.dashboard-my-6 {
  margin-bottom: 4.5rem !important;
  margin-top: 4.5rem !important;
}
.dashboard-my-7 {
  margin-bottom: 6rem !important;
  margin-top: 6rem !important;
}
.dashboard-my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
.dashboard-mt-0 {
  margin-top: 0 !important;
}
.dashboard-mt-1 {
  margin-top: 0.25rem !important;
}
.dashboard-mt-2 {
  margin-top: 0.5rem !important;
}
.dashboard-mt-3 {
  margin-top: 1rem !important;
}
.dashboard-mt-4 {
  margin-top: 1.5rem !important;
}
.dashboard-mt-5 {
  margin-top: 3rem !important;
}
.dashboard-mt-6 {
  margin-top: 4.5rem !important;
}
.dashboard-mt-7 {
  margin-top: 6rem !important;
}
.dashboard-mt-auto {
  margin-top: auto !important;
}
.dashboard-me-0 {
  margin-right: 0 !important;
}
.dashboard-me-1 {
  margin-right: 0.25rem !important;
}
.dashboard-me-2 {
  margin-right: 0.5rem !important;
}
.dashboard-me-3 {
  margin-right: 1rem !important;
}
.dashboard-me-4 {
  margin-right: 1.5rem !important;
}
.dashboard-me-5 {
  margin-right: 3rem !important;
}
.dashboard-me-6 {
  margin-right: 4.5rem !important;
}
.dashboard-me-7 {
  margin-right: 6rem !important;
}
.dashboard-me-auto {
  margin-right: auto !important;
}
.dashboard-mb-0 {
  margin-bottom: 0 !important;
}
.dashboard-mb-1 {
  margin-bottom: 0.25rem !important;
}
.dashboard-mb-2 {
  margin-bottom: 0.5rem !important;
}
.dashboard-mb-3 {
  margin-bottom: 1rem !important;
}
.dashboard-mb-4 {
  margin-bottom: 1.5rem !important;
}
.dashboard-mb-5 {
  margin-bottom: 3rem !important;
}
.dashboard-mb-6 {
  margin-bottom: 4.5rem !important;
}
.dashboard-mb-7 {
  margin-bottom: 6rem !important;
}
.dashboard-mb-auto {
  margin-bottom: auto !important;
}
.dashboard-ms-0 {
  margin-left: 0 !important;
}
.dashboard-ms-1 {
  margin-left: 0.25rem !important;
}
.dashboard-ms-2 {
  margin-left: 0.5rem !important;
}
.dashboard-ms-3 {
  margin-left: 1rem !important;
}
.dashboard-ms-4 {
  margin-left: 1.5rem !important;
}
.dashboard-ms-5 {
  margin-left: 3rem !important;
}
.dashboard-ms-6 {
  margin-left: 4.5rem !important;
}
.dashboard-ms-7 {
  margin-left: 6rem !important;
}
.dashboard-ms-auto {
  margin-left: auto !important;
}
.dashboard-m-n1 {
  margin: -0.25rem !important;
}
.dashboard-m-n2 {
  margin: -0.5rem !important;
}
.dashboard-m-n3 {
  margin: -1rem !important;
}
.dashboard-m-n4 {
  margin: -1.5rem !important;
}
.dashboard-m-n5 {
  margin: -3rem !important;
}
.dashboard-m-n6 {
  margin: -4.5rem !important;
}
.dashboard-m-n7 {
  margin: -6rem !important;
}
.dashboard-mx-n1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}
.dashboard-mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.dashboard-mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
.dashboard-mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.dashboard-mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}
.dashboard-mx-n6 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}
.dashboard-mx-n7 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}
.dashboard-my-n1 {
  margin-bottom: -0.25rem !important;
  margin-top: -0.25rem !important;
}
.dashboard-my-n2 {
  margin-bottom: -0.5rem !important;
  margin-top: -0.5rem !important;
}
.dashboard-my-n3 {
  margin-bottom: -1rem !important;
  margin-top: -1rem !important;
}
.dashboard-my-n4 {
  margin-bottom: -1.5rem !important;
  margin-top: -1.5rem !important;
}
.dashboard-my-n5 {
  margin-bottom: -3rem !important;
  margin-top: -3rem !important;
}
.dashboard-my-n6 {
  margin-bottom: -4.5rem !important;
  margin-top: -4.5rem !important;
}
.dashboard-my-n7 {
  margin-bottom: -6rem !important;
  margin-top: -6rem !important;
}
.dashboard-mt-n1 {
  margin-top: -0.25rem !important;
}
.dashboard-mt-n2 {
  margin-top: -0.5rem !important;
}
.dashboard-mt-n3 {
  margin-top: -1rem !important;
}
.dashboard-mt-n4 {
  margin-top: -1.5rem !important;
}
.dashboard-mt-n5 {
  margin-top: -3rem !important;
}
.dashboard-mt-n6 {
  margin-top: -4.5rem !important;
}
.dashboard-mt-n7 {
  margin-top: -6rem !important;
}
.dashboard-me-n1 {
  margin-right: -0.25rem !important;
}
.dashboard-me-n2 {
  margin-right: -0.5rem !important;
}
.dashboard-me-n3 {
  margin-right: -1rem !important;
}
.dashboard-me-n4 {
  margin-right: -1.5rem !important;
}
.dashboard-me-n5 {
  margin-right: -3rem !important;
}
.dashboard-me-n6 {
  margin-right: -4.5rem !important;
}
.dashboard-me-n7 {
  margin-right: -6rem !important;
}
.dashboard-mb-n1 {
  margin-bottom: -0.25rem !important;
}
.dashboard-mb-n2 {
  margin-bottom: -0.5rem !important;
}
.dashboard-mb-n3 {
  margin-bottom: -1rem !important;
}
.dashboard-mb-n4 {
  margin-bottom: -1.5rem !important;
}
.dashboard-mb-n5 {
  margin-bottom: -3rem !important;
}
.dashboard-mb-n6 {
  margin-bottom: -4.5rem !important;
}
.dashboard-mb-n7 {
  margin-bottom: -6rem !important;
}
.dashboard-ms-n1 {
  margin-left: -0.25rem !important;
}
.dashboard-ms-n2 {
  margin-left: -0.5rem !important;
}
.dashboard-ms-n3 {
  margin-left: -1rem !important;
}
.dashboard-ms-n4 {
  margin-left: -1.5rem !important;
}
.dashboard-ms-n5 {
  margin-left: -3rem !important;
}
.dashboard-ms-n6 {
  margin-left: -4.5rem !important;
}
.dashboard-ms-n7 {
  margin-left: -6rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.p-6 {
  padding: 4.5rem !important;
}
.p-7 {
  padding: 6rem !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.px-6 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}
.px-7 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}
.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.py-1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}
.py-2 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}
.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}
.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}
.py-6 {
  padding-bottom: 4.5rem !important;
  padding-top: 4.5rem !important;
}
.py-7 {
  padding-bottom: 6rem !important;
  padding-top: 6rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pt-6 {
  padding-top: 4.5rem !important;
}
.pt-7 {
  padding-top: 6rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pe-6 {
  padding-right: 4.5rem !important;
}
.pe-7 {
  padding-right: 6rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pb-6 {
  padding-bottom: 4.5rem !important;
}
.pb-7 {
  padding-bottom: 6rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}
.ps-6 {
  padding-left: 4.5rem !important;
}
.ps-7 {
  padding-left: 6rem !important;
}
.dashboard-font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.dashboard-fs-1 {
  font-size: 1.75rem !important;
}
.dashboard-fs-2 {
  font-size: 1.53125rem !important;
}
.dashboard-fs-3 {
  font-size: 1.3125rem !important;
}
.dashboard-fs-4 {
  font-size: 1.09375rem !important;
}
.dashboard-fs-5,
.dashboard-fs-6 {
  font-size: 0.875rem !important;
}
.dashboard-fst-italic {
  font-style: italic !important;
}
.dashboard-fst-normal {
  font-style: normal !important;
}
.dashboard-fw-light {
  font-weight: 300 !important;
}
.dashboard-fw-lighter {
  font-weight: lighter !important;
}
.dashboard-fw-normal {
  font-weight: 400 !important;
}
.dashboard-fw-bold {
  font-weight: 600 !important;
}
.dashboard-fw-bolder {
  font-weight: bolder !important;
}
.dashboard-lh-1 {
  line-height: 1 !important;
}
.dashboard-lh-base,
.dashboard-lh-lg,
.dashboard-lh-sm {
  line-height: 1.5 !important;
}
.dashboard-text-start {
  text-align: left !important;
}
.dashboard-text-end {
  text-align: right !important;
}
.dashboard-text-center {
  text-align: center !important;
}
.dashboard-text-decoration-none {
  text-decoration: none !important;
}
.dashboard-text-decoration-underline {
  text-decoration: underline !important;
}
.dashboard-text-decoration-line-through {
  text-decoration: line-through !important;
}
.dashboard-text-lowercase {
  text-transform: lowercase !important;
}
.dashboard-text-uppercase {
  text-transform: uppercase !important;
}
.dashboard-text-capitalize {
  text-transform: capitalize !important;
}
.dashboard-text-wrap {
  white-space: normal !important;
}
.dashboard-text-nowrap {
  white-space: nowrap !important;
}
.dashboard-text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.dashboard-text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.dashboard-text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}
.dashboard-text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.dashboard-text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.dashboard-text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.dashboard-text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.dashboard-text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.dashboard-text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.dashboard-text-opacity-100 {
  --bs-text-opacity: 1;
}
.dashboard-bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}
.dashboard-bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.dashboard-bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.dashboard-bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.dashboard-bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.dashboard-bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.dashboard-bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.dashboard-bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.dashboard-rounded {
  border-radius: 0.2rem !important;
}
.dashboard-rounded-0 {
  border-radius: 0 !important;
}
.dashboard-rounded-1 {
  border-radius: 0.1rem !important;
}
.dashboard-rounded-2 {
  border-radius: 0.2rem !important;
}
.dashboard-rounded-3 {
  border-radius: 0.3rem !important;
}
.dashboard-rounded-circle {
  border-radius: 50% !important;
}
.dashboard-rounded-pill {
  border-radius: 50rem !important;
}
.dashboard-rounded-top {
  border-top-left-radius: 0.2rem !important;
}
.dashboard-rounded-end,
.dashboard-rounded-top {
  border-top-right-radius: 0.2rem !important;
}
.dashboard-rounded-bottom,
.dashboard-rounded-end {
  border-bottom-right-radius: 0.2rem !important;
}
.dashboard-rounded-bottom,
.dashboard-rounded-start {
  border-bottom-left-radius: 0.2rem !important;
}
.dashboard-rounded-start {
  border-top-left-radius: 0.2rem !important;
}
.dashboard-visible {
  visibility: visible !important;
}
.dashboard-invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  .dashboard-float-sm-start {
    float: left !important;
  }
  .dashboard-float-sm-end {
    float: right !important;
  }
  .dashboard-float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .dashboard-flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .dashboard-flex-sm-row {
    flex-direction: row !important;
  }
  .dashboard-flex-sm-column {
    flex-direction: column !important;
  }
  .dashboard-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .dashboard-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .dashboard-flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .dashboard-flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .dashboard-flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .dashboard-flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .dashboard-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .dashboard-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .dashboard-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .dashboard-gap-sm-0 {
    gap: 0 !important;
  }
  .dashboard-gap-sm-1 {
    gap: 0.25rem !important;
  }
  .dashboard-gap-sm-2 {
    gap: 0.5rem !important;
  }
  .dashboard-gap-sm-3 {
    gap: 1rem !important;
  }
  .dashboard-gap-sm-4 {
    gap: 1.5rem !important;
  }
  .dashboard-gap-sm-5 {
    gap: 3rem !important;
  }
  .dashboard-gap-sm-6 {
    gap: 4.5rem !important;
  }
  .dashboard-gap-sm-7 {
    gap: 6rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .dashboard-align-items-sm-start {
    align-items: flex-start !important;
  }
  .dashboard-align-items-sm-end {
    align-items: flex-end !important;
  }
  .dashboard-align-items-sm-center {
    align-items: center !important;
  }
  .dashboard-align-items-sm-baseline {
    align-items: baseline !important;
  }
  .dashboard-align-items-sm-stretch {
    align-items: stretch !important;
  }
  .dashboard-align-content-sm-start {
    align-content: flex-start !important;
  }
  .dashboard-align-content-sm-end {
    align-content: flex-end !important;
  }
  .dashboard-align-content-sm-center {
    align-content: center !important;
  }
  .dashboard-align-content-sm-between {
    align-content: space-between !important;
  }
  .dashboard-align-content-sm-around {
    align-content: space-around !important;
  }
  .dashboard-align-content-sm-stretch {
    align-content: stretch !important;
  }
  .dashboard-align-self-sm-auto {
    align-self: auto !important;
  }
  .dashboard-align-self-sm-start {
    align-self: flex-start !important;
  }
  .dashboard-align-self-sm-end {
    align-self: flex-end !important;
  }
  .dashboard-align-self-sm-center {
    align-self: center !important;
  }
  .dashboard-align-self-sm-baseline {
    align-self: baseline !important;
  }
  .dashboard-align-self-sm-stretch {
    align-self: stretch !important;
  }
  .dashboard-order-sm-first {
    order: -1 !important;
  }
  .dashboard-order-sm-0 {
    order: 0 !important;
  }
  .dashboard-order-sm-1 {
    order: 1 !important;
  }
  .dashboard-order-sm-2 {
    order: 2 !important;
  }
  .dashboard-order-sm-3 {
    order: 3 !important;
  }
  .dashboard-order-sm-4 {
    order: 4 !important;
  }
  .dashboard-order-sm-5 {
    order: 5 !important;
  }
  .dashboard-order-sm-last {
    order: 6 !important;
  }
  .dashboard-m-sm-0 {
    margin: 0 !important;
  }
  .dashboard-m-sm-1 {
    margin: 0.25rem !important;
  }
  .dashboard-m-sm-2 {
    margin: 0.5rem !important;
  }
  .dashboard-m-sm-3 {
    margin: 1rem !important;
  }
  .dashboard-m-sm-4 {
    margin: 1.5rem !important;
  }
  .dashboard-m-sm-5 {
    margin: 3rem !important;
  }
  .dashboard-m-sm-6 {
    margin: 4.5rem !important;
  }
  .dashboard-m-sm-7 {
    margin: 6rem !important;
  }
  .dashboard-m-sm-auto {
    margin: auto !important;
  }
  .dashboard-mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .dashboard-mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .dashboard-mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .dashboard-mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .dashboard-mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .dashboard-mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .dashboard-mx-sm-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .dashboard-mx-sm-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .dashboard-mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .dashboard-my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .dashboard-my-sm-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .dashboard-my-sm-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .dashboard-my-sm-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .dashboard-my-sm-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .dashboard-my-sm-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .dashboard-my-sm-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .dashboard-my-sm-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .dashboard-my-sm-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .dashboard-mt-sm-0 {
    margin-top: 0 !important;
  }
  .dashboard-mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .dashboard-mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .dashboard-mt-sm-3 {
    margin-top: 1rem !important;
  }
  .dashboard-mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .dashboard-mt-sm-5 {
    margin-top: 3rem !important;
  }
  .dashboard-mt-sm-6 {
    margin-top: 4.5rem !important;
  }
  .dashboard-mt-sm-7 {
    margin-top: 6rem !important;
  }
  .dashboard-mt-sm-auto {
    margin-top: auto !important;
  }
  .dashboard-me-sm-0 {
    margin-right: 0 !important;
  }
  .dashboard-me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .dashboard-me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .dashboard-me-sm-3 {
    margin-right: 1rem !important;
  }
  .dashboard-me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .dashboard-me-sm-5 {
    margin-right: 3rem !important;
  }
  .dashboard-me-sm-6 {
    margin-right: 4.5rem !important;
  }
  .dashboard-me-sm-7 {
    margin-right: 6rem !important;
  }
  .dashboard-me-sm-auto {
    margin-right: auto !important;
  }
  .dashboard-mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .dashboard-mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .dashboard-mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .dashboard-mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .dashboard-mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .dashboard-mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .dashboard-mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .dashboard-mb-sm-7 {
    margin-bottom: 6rem !important;
  }
  .dashboard-mb-sm-auto {
    margin-bottom: auto !important;
  }
  .dashboard-ms-sm-0 {
    margin-left: 0 !important;
  }
  .dashboard-ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .dashboard-ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .dashboard-ms-sm-3 {
    margin-left: 1rem !important;
  }
  .dashboard-ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .dashboard-ms-sm-5 {
    margin-left: 3rem !important;
  }
  .dashboard-ms-sm-6 {
    margin-left: 4.5rem !important;
  }
  .dashboard-ms-sm-7 {
    margin-left: 6rem !important;
  }
  .dashboard-ms-sm-auto {
    margin-left: auto !important;
  }
  .dashboard-m-sm-n1 {
    margin: -0.25rem !important;
  }
  .dashboard-m-sm-n2 {
    margin: -0.5rem !important;
  }
  .dashboard-m-sm-n3 {
    margin: -1rem !important;
  }
  .dashboard-m-sm-n4 {
    margin: -1.5rem !important;
  }
  .dashboard-m-sm-n5 {
    margin: -3rem !important;
  }
  .dashboard-m-sm-n6 {
    margin: -4.5rem !important;
  }
  .dashboard-m-sm-n7 {
    margin: -6rem !important;
  }
  .dashboard-mx-sm-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .dashboard-mx-sm-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .dashboard-mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .dashboard-mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .dashboard-mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .dashboard-mx-sm-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .dashboard-mx-sm-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .dashboard-my-sm-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .dashboard-my-sm-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .dashboard-my-sm-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .dashboard-my-sm-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .dashboard-my-sm-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .dashboard-my-sm-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .dashboard-my-sm-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .dashboard-mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .dashboard-mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .dashboard-mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .dashboard-mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .dashboard-mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .dashboard-mt-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .dashboard-mt-sm-n7 {
    margin-top: -6rem !important;
  }
  .dashboard-me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .dashboard-me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .dashboard-me-sm-n3 {
    margin-right: -1rem !important;
  }
  .dashboard-me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .dashboard-me-sm-n5 {
    margin-right: -3rem !important;
  }
  .dashboard-me-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .dashboard-me-sm-n7 {
    margin-right: -6rem !important;
  }
  .dashboard-mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .dashboard-mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .dashboard-mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .dashboard-mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .dashboard-mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .dashboard-mb-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .dashboard-mb-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .dashboard-ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .dashboard-ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .dashboard-ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .dashboard-ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .dashboard-ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .dashboard-ms-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .dashboard-ms-sm-n7 {
    margin-left: -6rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-sm-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-sm-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-sm-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-sm-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-sm-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 6rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 6rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 6rem !important;
  }
  .dashboard-text-sm-start {
    text-align: left !important;
  }
  .dashboard-text-sm-end {
    text-align: right !important;
  }
  .dashboard-text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .dashboard-float-md-start {
    float: left !important;
  }
  .dashboard-float-md-end {
    float: right !important;
  }
  .dashboard-float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .dashboard-flex-md-fill {
    flex: 1 1 auto !important;
  }
  .dashboard-flex-md-row {
    flex-direction: row !important;
  }
  .dashboard-flex-md-column {
    flex-direction: column !important;
  }
  .dashboard-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .dashboard-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .dashboard-flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .dashboard-flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .dashboard-flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .dashboard-flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .dashboard-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .dashboard-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .dashboard-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .dashboard-gap-md-0 {
    gap: 0 !important;
  }
  .dashboard-gap-md-1 {
    gap: 0.25rem !important;
  }
  .dashboard-gap-md-2 {
    gap: 0.5rem !important;
  }
  .dashboard-gap-md-3 {
    gap: 1rem !important;
  }
  .dashboard-gap-md-4 {
    gap: 1.5rem !important;
  }
  .dashboard-gap-md-5 {
    gap: 3rem !important;
  }
  .dashboard-gap-md-6 {
    gap: 4.5rem !important;
  }
  .dashboard-gap-md-7 {
    gap: 6rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .dashboard-align-items-md-start {
    align-items: flex-start !important;
  }
  .dashboard-align-items-md-end {
    align-items: flex-end !important;
  }
  .dashboard-align-items-md-center {
    align-items: center !important;
  }
  .dashboard-align-items-md-baseline {
    align-items: baseline !important;
  }
  .dashboard-align-items-md-stretch {
    align-items: stretch !important;
  }
  .dashboard-align-content-md-start {
    align-content: flex-start !important;
  }
  .dashboard-align-content-md-end {
    align-content: flex-end !important;
  }
  .dashboard-align-content-md-center {
    align-content: center !important;
  }
  .dashboard-align-content-md-between {
    align-content: space-between !important;
  }
  .dashboard-align-content-md-around {
    align-content: space-around !important;
  }
  .dashboard-align-content-md-stretch {
    align-content: stretch !important;
  }
  .dashboard-align-self-md-auto {
    align-self: auto !important;
  }
  .dashboard-align-self-md-start {
    align-self: flex-start !important;
  }
  .dashboard-align-self-md-end {
    align-self: flex-end !important;
  }
  .dashboard-align-self-md-center {
    align-self: center !important;
  }
  .dashboard-align-self-md-baseline {
    align-self: baseline !important;
  }
  .dashboard-align-self-md-stretch {
    align-self: stretch !important;
  }
  .dashboard-order-md-first {
    order: -1 !important;
  }
  .dashboard-order-md-0 {
    order: 0 !important;
  }
  .dashboard-order-md-1 {
    order: 1 !important;
  }
  .dashboard-order-md-2 {
    order: 2 !important;
  }
  .dashboard-order-md-3 {
    order: 3 !important;
  }
  .dashboard-order-md-4 {
    order: 4 !important;
  }
  .dashboard-order-md-5 {
    order: 5 !important;
  }
  .dashboard-order-md-last {
    order: 6 !important;
  }
  .dashboard-m-md-0 {
    margin: 0 !important;
  }
  .dashboard-m-md-1 {
    margin: 0.25rem !important;
  }
  .dashboard-m-md-2 {
    margin: 0.5rem !important;
  }
  .dashboard-m-md-3 {
    margin: 1rem !important;
  }
  .dashboard-m-md-4 {
    margin: 1.5rem !important;
  }
  .dashboard-m-md-5 {
    margin: 3rem !important;
  }
  .dashboard-m-md-6 {
    margin: 4.5rem !important;
  }
  .dashboard-m-md-7 {
    margin: 6rem !important;
  }
  .dashboard-m-md-auto {
    margin: auto !important;
  }
  .dashboard-mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .dashboard-mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .dashboard-mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .dashboard-mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .dashboard-mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .dashboard-mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .dashboard-mx-md-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .dashboard-mx-md-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .dashboard-mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .dashboard-my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .dashboard-my-md-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .dashboard-my-md-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .dashboard-my-md-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .dashboard-my-md-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .dashboard-my-md-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .dashboard-my-md-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .dashboard-my-md-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .dashboard-my-md-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .dashboard-mt-md-0 {
    margin-top: 0 !important;
  }
  .dashboard-mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .dashboard-mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .dashboard-mt-md-3 {
    margin-top: 1rem !important;
  }
  .dashboard-mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .dashboard-mt-md-5 {
    margin-top: 3rem !important;
  }
  .dashboard-mt-md-6 {
    margin-top: 4.5rem !important;
  }
  .dashboard-mt-md-7 {
    margin-top: 6rem !important;
  }
  .dashboard-mt-md-auto {
    margin-top: auto !important;
  }
  .dashboard-me-md-0 {
    margin-right: 0 !important;
  }
  .dashboard-me-md-1 {
    margin-right: 0.25rem !important;
  }
  .dashboard-me-md-2 {
    margin-right: 0.5rem !important;
  }
  .dashboard-me-md-3 {
    margin-right: 1rem !important;
  }
  .dashboard-me-md-4 {
    margin-right: 1.5rem !important;
  }
  .dashboard-me-md-5 {
    margin-right: 3rem !important;
  }
  .dashboard-me-md-6 {
    margin-right: 4.5rem !important;
  }
  .dashboard-me-md-7 {
    margin-right: 6rem !important;
  }
  .dashboard-me-md-auto {
    margin-right: auto !important;
  }
  .dashboard-mb-md-0 {
    margin-bottom: 0 !important;
  }
  .dashboard-mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .dashboard-mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .dashboard-mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .dashboard-mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .dashboard-mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .dashboard-mb-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .dashboard-mb-md-7 {
    margin-bottom: 6rem !important;
  }
  .dashboard-mb-md-auto {
    margin-bottom: auto !important;
  }
  .dashboard-ms-md-0 {
    margin-left: 0 !important;
  }
  .dashboard-ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .dashboard-ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .dashboard-ms-md-3 {
    margin-left: 1rem !important;
  }
  .dashboard-ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .dashboard-ms-md-5 {
    margin-left: 3rem !important;
  }
  .dashboard-ms-md-6 {
    margin-left: 4.5rem !important;
  }
  .dashboard-ms-md-7 {
    margin-left: 6rem !important;
  }
  .dashboard-ms-md-auto {
    margin-left: auto !important;
  }
  .dashboard-m-md-n1 {
    margin: -0.25rem !important;
  }
  .dashboard-m-md-n2 {
    margin: -0.5rem !important;
  }
  .dashboard-m-md-n3 {
    margin: -1rem !important;
  }
  .dashboard-m-md-n4 {
    margin: -1.5rem !important;
  }
  .dashboard-m-md-n5 {
    margin: -3rem !important;
  }
  .dashboard-m-md-n6 {
    margin: -4.5rem !important;
  }
  .dashboard-m-md-n7 {
    margin: -6rem !important;
  }
  .dashboard-mx-md-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .dashboard-mx-md-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .dashboard-mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .dashboard-mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .dashboard-mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .dashboard-mx-md-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .dashboard-mx-md-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .dashboard-my-md-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .dashboard-my-md-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .dashboard-my-md-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .dashboard-my-md-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .dashboard-my-md-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .dashboard-my-md-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .dashboard-my-md-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .dashboard-mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .dashboard-mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .dashboard-mt-md-n3 {
    margin-top: -1rem !important;
  }
  .dashboard-mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .dashboard-mt-md-n5 {
    margin-top: -3rem !important;
  }
  .dashboard-mt-md-n6 {
    margin-top: -4.5rem !important;
  }
  .dashboard-mt-md-n7 {
    margin-top: -6rem !important;
  }
  .dashboard-me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .dashboard-me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .dashboard-me-md-n3 {
    margin-right: -1rem !important;
  }
  .dashboard-me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .dashboard-me-md-n5 {
    margin-right: -3rem !important;
  }
  .dashboard-me-md-n6 {
    margin-right: -4.5rem !important;
  }
  .dashboard-me-md-n7 {
    margin-right: -6rem !important;
  }
  .dashboard-mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .dashboard-mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .dashboard-mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .dashboard-mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .dashboard-mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .dashboard-mb-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .dashboard-mb-md-n7 {
    margin-bottom: -6rem !important;
  }
  .dashboard-ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .dashboard-ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .dashboard-ms-md-n3 {
    margin-left: -1rem !important;
  }
  .dashboard-ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .dashboard-ms-md-n5 {
    margin-left: -3rem !important;
  }
  .dashboard-ms-md-n6 {
    margin-left: -4.5rem !important;
  }
  .dashboard-ms-md-n7 {
    margin-left: -6rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-md-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-md-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-md-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-md-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-md-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-md-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-md-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-md-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-md-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4.5rem !important;
  }
  .pt-md-7 {
    padding-top: 6rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 4.5rem !important;
  }
  .pe-md-7 {
    padding-right: 6rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 6rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 4.5rem !important;
  }
  .ps-md-7 {
    padding-left: 6rem !important;
  }
  .dashboard-text-md-start {
    text-align: left !important;
  }
  .dashboard-text-md-end {
    text-align: right !important;
  }
  .dashboard-text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .dashboard-float-lg-start {
    float: left !important;
  }
  .dashboard-float-lg-end {
    float: right !important;
  }
  .dashboard-float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .dashboard-flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .dashboard-flex-lg-row {
    flex-direction: row !important;
  }
  .dashboard-flex-lg-column {
    flex-direction: column !important;
  }
  .dashboard-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .dashboard-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .dashboard-flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .dashboard-flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .dashboard-flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .dashboard-flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .dashboard-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .dashboard-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .dashboard-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .dashboard-gap-lg-0 {
    gap: 0 !important;
  }
  .dashboard-gap-lg-1 {
    gap: 0.25rem !important;
  }
  .dashboard-gap-lg-2 {
    gap: 0.5rem !important;
  }
  .dashboard-gap-lg-3 {
    gap: 1rem !important;
  }
  .dashboard-gap-lg-4 {
    gap: 1.5rem !important;
  }
  .dashboard-gap-lg-5 {
    gap: 3rem !important;
  }
  .dashboard-gap-lg-6 {
    gap: 4.5rem !important;
  }
  .dashboard-gap-lg-7 {
    gap: 6rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .dashboard-align-items-lg-start {
    align-items: flex-start !important;
  }
  .dashboard-align-items-lg-end {
    align-items: flex-end !important;
  }
  .dashboard-align-items-lg-center {
    align-items: center !important;
  }
  .dashboard-align-items-lg-baseline {
    align-items: baseline !important;
  }
  .dashboard-align-items-lg-stretch {
    align-items: stretch !important;
  }
  .dashboard-align-content-lg-start {
    align-content: flex-start !important;
  }
  .dashboard-align-content-lg-end {
    align-content: flex-end !important;
  }
  .dashboard-align-content-lg-center {
    align-content: center !important;
  }
  .dashboard-align-content-lg-between {
    align-content: space-between !important;
  }
  .dashboard-align-content-lg-around {
    align-content: space-around !important;
  }
  .dashboard-align-content-lg-stretch {
    align-content: stretch !important;
  }
  .dashboard-align-self-lg-auto {
    align-self: auto !important;
  }
  .dashboard-align-self-lg-start {
    align-self: flex-start !important;
  }
  .dashboard-align-self-lg-end {
    align-self: flex-end !important;
  }
  .dashboard-align-self-lg-center {
    align-self: center !important;
  }
  .dashboard-align-self-lg-baseline {
    align-self: baseline !important;
  }
  .dashboard-align-self-lg-stretch {
    align-self: stretch !important;
  }
  .dashboard-order-lg-first {
    order: -1 !important;
  }
  .dashboard-order-lg-0 {
    order: 0 !important;
  }
  .dashboard-order-lg-1 {
    order: 1 !important;
  }
  .dashboard-order-lg-2 {
    order: 2 !important;
  }
  .dashboard-order-lg-3 {
    order: 3 !important;
  }
  .dashboard-order-lg-4 {
    order: 4 !important;
  }
  .dashboard-order-lg-5 {
    order: 5 !important;
  }
  .dashboard-order-lg-last {
    order: 6 !important;
  }
  .dashboard-m-lg-0 {
    margin: 0 !important;
  }
  .dashboard-m-lg-1 {
    margin: 0.25rem !important;
  }
  .dashboard-m-lg-2 {
    margin: 0.5rem !important;
  }
  .dashboard-m-lg-3 {
    margin: 1rem !important;
  }
  .dashboard-m-lg-4 {
    margin: 1.5rem !important;
  }
  .dashboard-m-lg-5 {
    margin: 3rem !important;
  }
  .dashboard-m-lg-6 {
    margin: 4.5rem !important;
  }
  .dashboard-m-lg-7 {
    margin: 6rem !important;
  }
  .dashboard-m-lg-auto {
    margin: auto !important;
  }
  .dashboard-mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .dashboard-mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .dashboard-mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .dashboard-mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .dashboard-mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .dashboard-mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .dashboard-mx-lg-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .dashboard-mx-lg-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .dashboard-mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .dashboard-my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .dashboard-my-lg-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .dashboard-my-lg-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .dashboard-my-lg-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .dashboard-my-lg-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .dashboard-my-lg-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .dashboard-my-lg-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .dashboard-my-lg-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .dashboard-my-lg-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .dashboard-mt-lg-0 {
    margin-top: 0 !important;
  }
  .dashboard-mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .dashboard-mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .dashboard-mt-lg-3 {
    margin-top: 1rem !important;
  }
  .dashboard-mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .dashboard-mt-lg-5 {
    margin-top: 3rem !important;
  }
  .dashboard-mt-lg-6 {
    margin-top: 4.5rem !important;
  }
  .dashboard-mt-lg-7 {
    margin-top: 6rem !important;
  }
  .dashboard-mt-lg-auto {
    margin-top: auto !important;
  }
  .dashboard-me-lg-0 {
    margin-right: 0 !important;
  }
  .dashboard-me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .dashboard-me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .dashboard-me-lg-3 {
    margin-right: 1rem !important;
  }
  .dashboard-me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .dashboard-me-lg-5 {
    margin-right: 3rem !important;
  }
  .dashboard-me-lg-6 {
    margin-right: 4.5rem !important;
  }
  .dashboard-me-lg-7 {
    margin-right: 6rem !important;
  }
  .dashboard-me-lg-auto {
    margin-right: auto !important;
  }
  .dashboard-mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .dashboard-mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .dashboard-mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .dashboard-mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .dashboard-mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .dashboard-mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .dashboard-mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .dashboard-mb-lg-7 {
    margin-bottom: 6rem !important;
  }
  .dashboard-mb-lg-auto {
    margin-bottom: auto !important;
  }
  .dashboard-ms-lg-0 {
    margin-left: 0 !important;
  }
  .dashboard-ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .dashboard-ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .dashboard-ms-lg-3 {
    margin-left: 1rem !important;
  }
  .dashboard-ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .dashboard-ms-lg-5 {
    margin-left: 3rem !important;
  }
  .dashboard-ms-lg-6 {
    margin-left: 4.5rem !important;
  }
  .dashboard-ms-lg-7 {
    margin-left: 6rem !important;
  }
  .dashboard-ms-lg-auto {
    margin-left: auto !important;
  }
  .dashboard-m-lg-n1 {
    margin: -0.25rem !important;
  }
  .dashboard-m-lg-n2 {
    margin: -0.5rem !important;
  }
  .dashboard-m-lg-n3 {
    margin: -1rem !important;
  }
  .dashboard-m-lg-n4 {
    margin: -1.5rem !important;
  }
  .dashboard-m-lg-n5 {
    margin: -3rem !important;
  }
  .dashboard-m-lg-n6 {
    margin: -4.5rem !important;
  }
  .dashboard-m-lg-n7 {
    margin: -6rem !important;
  }
  .dashboard-mx-lg-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .dashboard-mx-lg-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .dashboard-mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .dashboard-mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .dashboard-mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .dashboard-mx-lg-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .dashboard-mx-lg-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .dashboard-my-lg-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .dashboard-my-lg-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .dashboard-my-lg-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .dashboard-my-lg-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .dashboard-my-lg-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .dashboard-my-lg-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .dashboard-my-lg-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .dashboard-mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .dashboard-mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .dashboard-mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .dashboard-mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .dashboard-mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .dashboard-mt-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .dashboard-mt-lg-n7 {
    margin-top: -6rem !important;
  }
  .dashboard-me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .dashboard-me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .dashboard-me-lg-n3 {
    margin-right: -1rem !important;
  }
  .dashboard-me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .dashboard-me-lg-n5 {
    margin-right: -3rem !important;
  }
  .dashboard-me-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .dashboard-me-lg-n7 {
    margin-right: -6rem !important;
  }
  .dashboard-mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .dashboard-mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .dashboard-mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .dashboard-mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .dashboard-mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .dashboard-mb-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .dashboard-mb-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .dashboard-ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .dashboard-ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .dashboard-ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .dashboard-ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .dashboard-ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .dashboard-ms-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .dashboard-ms-lg-n7 {
    margin-left: -6rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-lg-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-lg-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-lg-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-lg-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-lg-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 6rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6rem !important;
  }
  .dashboard-text-lg-start {
    text-align: left !important;
  }
  .dashboard-text-lg-end {
    text-align: right !important;
  }
  .dashboard-text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .dashboard-float-xl-start {
    float: left !important;
  }
  .dashboard-float-xl-end {
    float: right !important;
  }
  .dashboard-float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .dashboard-flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .dashboard-flex-xl-row {
    flex-direction: row !important;
  }
  .dashboard-flex-xl-column {
    flex-direction: column !important;
  }
  .dashboard-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .dashboard-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .dashboard-flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .dashboard-flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .dashboard-flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .dashboard-flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .dashboard-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .dashboard-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .dashboard-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .dashboard-gap-xl-0 {
    gap: 0 !important;
  }
  .dashboard-gap-xl-1 {
    gap: 0.25rem !important;
  }
  .dashboard-gap-xl-2 {
    gap: 0.5rem !important;
  }
  .dashboard-gap-xl-3 {
    gap: 1rem !important;
  }
  .dashboard-gap-xl-4 {
    gap: 1.5rem !important;
  }
  .dashboard-gap-xl-5 {
    gap: 3rem !important;
  }
  .dashboard-gap-xl-6 {
    gap: 4.5rem !important;
  }
  .dashboard-gap-xl-7 {
    gap: 6rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .dashboard-align-items-xl-start {
    align-items: flex-start !important;
  }
  .dashboard-align-items-xl-end {
    align-items: flex-end !important;
  }
  .dashboard-align-items-xl-center {
    align-items: center !important;
  }
  .dashboard-align-items-xl-baseline {
    align-items: baseline !important;
  }
  .dashboard-align-items-xl-stretch {
    align-items: stretch !important;
  }
  .dashboard-align-content-xl-start {
    align-content: flex-start !important;
  }
  .dashboard-align-content-xl-end {
    align-content: flex-end !important;
  }
  .dashboard-align-content-xl-center {
    align-content: center !important;
  }
  .dashboard-align-content-xl-between {
    align-content: space-between !important;
  }
  .dashboard-align-content-xl-around {
    align-content: space-around !important;
  }
  .dashboard-align-content-xl-stretch {
    align-content: stretch !important;
  }
  .dashboard-align-self-xl-auto {
    align-self: auto !important;
  }
  .dashboard-align-self-xl-start {
    align-self: flex-start !important;
  }
  .dashboard-align-self-xl-end {
    align-self: flex-end !important;
  }
  .dashboard-align-self-xl-center {
    align-self: center !important;
  }
  .dashboard-align-self-xl-baseline {
    align-self: baseline !important;
  }
  .dashboard-align-self-xl-stretch {
    align-self: stretch !important;
  }
  .dashboard-order-xl-first {
    order: -1 !important;
  }
  .dashboard-order-xl-0 {
    order: 0 !important;
  }
  .dashboard-order-xl-1 {
    order: 1 !important;
  }
  .dashboard-order-xl-2 {
    order: 2 !important;
  }
  .dashboard-order-xl-3 {
    order: 3 !important;
  }
  .dashboard-order-xl-4 {
    order: 4 !important;
  }
  .dashboard-order-xl-5 {
    order: 5 !important;
  }
  .dashboard-order-xl-last {
    order: 6 !important;
  }
  .dashboard-m-xl-0 {
    margin: 0 !important;
  }
  .dashboard-m-xl-1 {
    margin: 0.25rem !important;
  }
  .dashboard-m-xl-2 {
    margin: 0.5rem !important;
  }
  .dashboard-m-xl-3 {
    margin: 1rem !important;
  }
  .dashboard-m-xl-4 {
    margin: 1.5rem !important;
  }
  .dashboard-m-xl-5 {
    margin: 3rem !important;
  }
  .dashboard-m-xl-6 {
    margin: 4.5rem !important;
  }
  .dashboard-m-xl-7 {
    margin: 6rem !important;
  }
  .dashboard-m-xl-auto {
    margin: auto !important;
  }
  .dashboard-mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .dashboard-mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .dashboard-mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .dashboard-mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .dashboard-mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .dashboard-mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .dashboard-mx-xl-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .dashboard-mx-xl-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .dashboard-mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .dashboard-my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .dashboard-my-xl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .dashboard-my-xl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .dashboard-my-xl-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .dashboard-my-xl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .dashboard-my-xl-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .dashboard-my-xl-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .dashboard-my-xl-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .dashboard-my-xl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .dashboard-mt-xl-0 {
    margin-top: 0 !important;
  }
  .dashboard-mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .dashboard-mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .dashboard-mt-xl-3 {
    margin-top: 1rem !important;
  }
  .dashboard-mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .dashboard-mt-xl-5 {
    margin-top: 3rem !important;
  }
  .dashboard-mt-xl-6 {
    margin-top: 4.5rem !important;
  }
  .dashboard-mt-xl-7 {
    margin-top: 6rem !important;
  }
  .dashboard-mt-xl-auto {
    margin-top: auto !important;
  }
  .dashboard-me-xl-0 {
    margin-right: 0 !important;
  }
  .dashboard-me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .dashboard-me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .dashboard-me-xl-3 {
    margin-right: 1rem !important;
  }
  .dashboard-me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .dashboard-me-xl-5 {
    margin-right: 3rem !important;
  }
  .dashboard-me-xl-6 {
    margin-right: 4.5rem !important;
  }
  .dashboard-me-xl-7 {
    margin-right: 6rem !important;
  }
  .dashboard-me-xl-auto {
    margin-right: auto !important;
  }
  .dashboard-mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .dashboard-mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .dashboard-mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .dashboard-mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .dashboard-mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .dashboard-mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .dashboard-mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .dashboard-mb-xl-7 {
    margin-bottom: 6rem !important;
  }
  .dashboard-mb-xl-auto {
    margin-bottom: auto !important;
  }
  .dashboard-ms-xl-0 {
    margin-left: 0 !important;
  }
  .dashboard-ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .dashboard-ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .dashboard-ms-xl-3 {
    margin-left: 1rem !important;
  }
  .dashboard-ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .dashboard-ms-xl-5 {
    margin-left: 3rem !important;
  }
  .dashboard-ms-xl-6 {
    margin-left: 4.5rem !important;
  }
  .dashboard-ms-xl-7 {
    margin-left: 6rem !important;
  }
  .dashboard-ms-xl-auto {
    margin-left: auto !important;
  }
  .dashboard-m-xl-n1 {
    margin: -0.25rem !important;
  }
  .dashboard-m-xl-n2 {
    margin: -0.5rem !important;
  }
  .dashboard-m-xl-n3 {
    margin: -1rem !important;
  }
  .dashboard-m-xl-n4 {
    margin: -1.5rem !important;
  }
  .dashboard-m-xl-n5 {
    margin: -3rem !important;
  }
  .dashboard-m-xl-n6 {
    margin: -4.5rem !important;
  }
  .dashboard-m-xl-n7 {
    margin: -6rem !important;
  }
  .dashboard-mx-xl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .dashboard-mx-xl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .dashboard-mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .dashboard-mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .dashboard-mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .dashboard-mx-xl-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .dashboard-mx-xl-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .dashboard-my-xl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .dashboard-my-xl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .dashboard-my-xl-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .dashboard-my-xl-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .dashboard-my-xl-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .dashboard-my-xl-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .dashboard-my-xl-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .dashboard-mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .dashboard-mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .dashboard-mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .dashboard-mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .dashboard-mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .dashboard-mt-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .dashboard-mt-xl-n7 {
    margin-top: -6rem !important;
  }
  .dashboard-me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .dashboard-me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .dashboard-me-xl-n3 {
    margin-right: -1rem !important;
  }
  .dashboard-me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .dashboard-me-xl-n5 {
    margin-right: -3rem !important;
  }
  .dashboard-me-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .dashboard-me-xl-n7 {
    margin-right: -6rem !important;
  }
  .dashboard-mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .dashboard-mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .dashboard-mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .dashboard-mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .dashboard-mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .dashboard-mb-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .dashboard-mb-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .dashboard-ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .dashboard-ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .dashboard-ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .dashboard-ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .dashboard-ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .dashboard-ms-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .dashboard-ms-xl-n7 {
    margin-left: -6rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xl-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xl-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xl-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xl-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xl-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 6rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 6rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 6rem !important;
  }
  .dashboard-text-xl-start {
    text-align: left !important;
  }
  .dashboard-text-xl-end {
    text-align: right !important;
  }
  .dashboard-text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .dashboard-float-xxl-start {
    float: left !important;
  }
  .dashboard-float-xxl-end {
    float: right !important;
  }
  .dashboard-float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .dashboard-flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .dashboard-flex-xxl-row {
    flex-direction: row !important;
  }
  .dashboard-flex-xxl-column {
    flex-direction: column !important;
  }
  .dashboard-flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .dashboard-flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .dashboard-flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .dashboard-flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .dashboard-flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .dashboard-flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .dashboard-flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .dashboard-flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .dashboard-flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .dashboard-gap-xxl-0 {
    gap: 0 !important;
  }
  .dashboard-gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .dashboard-gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .dashboard-gap-xxl-3 {
    gap: 1rem !important;
  }
  .dashboard-gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .dashboard-gap-xxl-5 {
    gap: 3rem !important;
  }
  .dashboard-gap-xxl-6 {
    gap: 4.5rem !important;
  }
  .dashboard-gap-xxl-7 {
    gap: 6rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .dashboard-align-items-xxl-start {
    align-items: flex-start !important;
  }
  .dashboard-align-items-xxl-end {
    align-items: flex-end !important;
  }
  .dashboard-align-items-xxl-center {
    align-items: center !important;
  }
  .dashboard-align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .dashboard-align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .dashboard-align-content-xxl-start {
    align-content: flex-start !important;
  }
  .dashboard-align-content-xxl-end {
    align-content: flex-end !important;
  }
  .dashboard-align-content-xxl-center {
    align-content: center !important;
  }
  .dashboard-align-content-xxl-between {
    align-content: space-between !important;
  }
  .dashboard-align-content-xxl-around {
    align-content: space-around !important;
  }
  .dashboard-align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .dashboard-align-self-xxl-auto {
    align-self: auto !important;
  }
  .dashboard-align-self-xxl-start {
    align-self: flex-start !important;
  }
  .dashboard-align-self-xxl-end {
    align-self: flex-end !important;
  }
  .dashboard-align-self-xxl-center {
    align-self: center !important;
  }
  .dashboard-align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .dashboard-align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .dashboard-order-xxl-first {
    order: -1 !important;
  }
  .dashboard-order-xxl-0 {
    order: 0 !important;
  }
  .dashboard-order-xxl-1 {
    order: 1 !important;
  }
  .dashboard-order-xxl-2 {
    order: 2 !important;
  }
  .dashboard-order-xxl-3 {
    order: 3 !important;
  }
  .dashboard-order-xxl-4 {
    order: 4 !important;
  }
  .dashboard-order-xxl-5 {
    order: 5 !important;
  }
  .dashboard-order-xxl-last {
    order: 6 !important;
  }
  .dashboard-m-xxl-0 {
    margin: 0 !important;
  }
  .dashboard-m-xxl-1 {
    margin: 0.25rem !important;
  }
  .dashboard-m-xxl-2 {
    margin: 0.5rem !important;
  }
  .dashboard-m-xxl-3 {
    margin: 1rem !important;
  }
  .dashboard-m-xxl-4 {
    margin: 1.5rem !important;
  }
  .dashboard-m-xxl-5 {
    margin: 3rem !important;
  }
  .dashboard-m-xxl-6 {
    margin: 4.5rem !important;
  }
  .dashboard-m-xxl-7 {
    margin: 6rem !important;
  }
  .dashboard-m-xxl-auto {
    margin: auto !important;
  }
  .dashboard-mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .dashboard-mx-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .dashboard-mx-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .dashboard-mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .dashboard-mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .dashboard-mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .dashboard-mx-xxl-6 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .dashboard-mx-xxl-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .dashboard-mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .dashboard-my-xxl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .dashboard-my-xxl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .dashboard-my-xxl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .dashboard-my-xxl-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .dashboard-my-xxl-4 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .dashboard-my-xxl-5 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .dashboard-my-xxl-6 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .dashboard-my-xxl-7 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .dashboard-my-xxl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .dashboard-mt-xxl-0 {
    margin-top: 0 !important;
  }
  .dashboard-mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .dashboard-mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .dashboard-mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .dashboard-mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .dashboard-mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .dashboard-mt-xxl-6 {
    margin-top: 4.5rem !important;
  }
  .dashboard-mt-xxl-7 {
    margin-top: 6rem !important;
  }
  .dashboard-mt-xxl-auto {
    margin-top: auto !important;
  }
  .dashboard-me-xxl-0 {
    margin-right: 0 !important;
  }
  .dashboard-me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .dashboard-me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .dashboard-me-xxl-3 {
    margin-right: 1rem !important;
  }
  .dashboard-me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .dashboard-me-xxl-5 {
    margin-right: 3rem !important;
  }
  .dashboard-me-xxl-6 {
    margin-right: 4.5rem !important;
  }
  .dashboard-me-xxl-7 {
    margin-right: 6rem !important;
  }
  .dashboard-me-xxl-auto {
    margin-right: auto !important;
  }
  .dashboard-mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .dashboard-mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .dashboard-mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .dashboard-mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .dashboard-mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .dashboard-mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .dashboard-mb-xxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .dashboard-mb-xxl-7 {
    margin-bottom: 6rem !important;
  }
  .dashboard-mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .dashboard-ms-xxl-0 {
    margin-left: 0 !important;
  }
  .dashboard-ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .dashboard-ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .dashboard-ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .dashboard-ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .dashboard-ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .dashboard-ms-xxl-6 {
    margin-left: 4.5rem !important;
  }
  .dashboard-ms-xxl-7 {
    margin-left: 6rem !important;
  }
  .dashboard-ms-xxl-auto {
    margin-left: auto !important;
  }
  .dashboard-m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .dashboard-m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .dashboard-m-xxl-n3 {
    margin: -1rem !important;
  }
  .dashboard-m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .dashboard-m-xxl-n5 {
    margin: -3rem !important;
  }
  .dashboard-m-xxl-n6 {
    margin: -4.5rem !important;
  }
  .dashboard-m-xxl-n7 {
    margin: -6rem !important;
  }
  .dashboard-mx-xxl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .dashboard-mx-xxl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .dashboard-mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .dashboard-mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .dashboard-mx-xxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .dashboard-mx-xxl-n6 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .dashboard-mx-xxl-n7 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .dashboard-my-xxl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .dashboard-my-xxl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .dashboard-my-xxl-n3 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .dashboard-my-xxl-n4 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .dashboard-my-xxl-n5 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .dashboard-my-xxl-n6 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .dashboard-my-xxl-n7 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .dashboard-mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .dashboard-mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .dashboard-mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .dashboard-mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .dashboard-mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .dashboard-mt-xxl-n6 {
    margin-top: -4.5rem !important;
  }
  .dashboard-mt-xxl-n7 {
    margin-top: -6rem !important;
  }
  .dashboard-me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .dashboard-me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .dashboard-me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .dashboard-me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .dashboard-me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .dashboard-me-xxl-n6 {
    margin-right: -4.5rem !important;
  }
  .dashboard-me-xxl-n7 {
    margin-right: -6rem !important;
  }
  .dashboard-mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .dashboard-mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .dashboard-mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .dashboard-mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .dashboard-mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .dashboard-mb-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .dashboard-mb-xxl-n7 {
    margin-bottom: -6rem !important;
  }
  .dashboard-ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .dashboard-ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .dashboard-ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .dashboard-ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .dashboard-ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .dashboard-ms-xxl-n6 {
    margin-left: -4.5rem !important;
  }
  .dashboard-ms-xxl-n7 {
    margin-left: -6rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4.5rem !important;
  }
  .p-xxl-7 {
    padding: 6rem !important;
  }
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xxl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xxl-6 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xxl-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .py-xxl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xxl-6 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xxl-7 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 6rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 6rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 6rem !important;
  }
  .dashboard-text-xxl-start {
    text-align: left !important;
  }
  .dashboard-text-xxl-end {
    text-align: right !important;
  }
  .dashboard-text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.dashboard-avatar {
  height: 40px;
  width: 40px;
}
.dashboard-avatar-lg {
  height: 64px;
  width: 64px;
}
.dashboard-avatar-title {
  align-items: center;
  color: #3b7ddd;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.dashboard-btn .dashboard-feather {
  height: 14px;
  width: 14px;
}
.dashboard-btn-danger,
.dashboard-btn-danger.dashboard-disabled,
.dashboard-btn-danger.dashboard-focus,
.dashboard-btn-danger.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-danger:disabled,
.dashboard-btn-danger:focus,
.dashboard-btn-danger:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-dark,
.dashboard-btn-dark.dashboard-disabled,
.dashboard-btn-dark.dashboard-focus,
.dashboard-btn-dark.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-dark:disabled,
.dashboard-btn-dark:focus,
.dashboard-btn-dark:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-info,
.dashboard-btn-info.dashboard-disabled,
.dashboard-btn-info.dashboard-focus,
.dashboard-btn-info.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-info:disabled,
.dashboard-btn-info:focus,
.dashboard-btn-info:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-light,
.dashboard-btn-light.dashboard-disabled,
.dashboard-btn-light.dashboard-focus,
.dashboard-btn-light.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-light:disabled,
.dashboard-btn-light:focus,
.dashboard-btn-light:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-primary,
.dashboard-btn-primary.dashboard-disabled,
.dashboard-btn-primary.dashboard-focus,
.dashboard-btn-primary.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-primary:disabled,
.dashboard-btn-primary:focus,
.dashboard-btn-primary:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-secondary,
.dashboard-btn-secondary.dashboard-disabled,
.dashboard-btn-secondary.dashboard-focus,
.dashboard-btn-secondary.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-secondary:disabled,
.dashboard-btn-secondary:focus,
.dashboard-btn-secondary:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-success,
.dashboard-btn-success.dashboard-disabled,
.dashboard-btn-success.dashboard-focus,
.dashboard-btn-success.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-success:disabled,
.dashboard-btn-success:focus,
.dashboard-btn-success:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-warning,
.dashboard-btn-warning.dashboard-disabled,
.dashboard-btn-warning.dashboard-focus,
.dashboard-btn-warning.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-warning:disabled,
.dashboard-btn-warning:focus,
.dashboard-btn-warning:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-danger.dashboard-dropdown-toggle,
.dashboard-show > .dashboard-btn-dark.dashboard-dropdown-toggle,
.dashboard-show > .dashboard-btn-info.dashboard-dropdown-toggle,
.dashboard-show > .dashboard-btn-light.dashboard-dropdown-toggle,
.dashboard-show > .dashboard-btn-primary.dashboard-dropdown-toggle,
.dashboard-show > .dashboard-btn-secondary.dashboard-dropdown-toggle,
.dashboard-show > .dashboard-btn-success.dashboard-dropdown-toggle,
.dashboard-show > .dashboard-btn-warning.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-facebook,
.dashboard-btn-facebook:focus,
.dashboard-btn-facebook:hover {
  background-color: #324c81;
  border-color: #2f477a;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-facebook,
.dashboard-btn-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-facebook,
.dashboard-btn-check:checked + .dashboard-btn-facebook,
.dashboard-btn-facebook.dashboard-active,
.dashboard-btn-facebook:active,
.dashboard-show > .dashboard-btn-facebook.dashboard-dropdown-toggle {
  background-color: #2f477a;
  border-color: #2c4372;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-facebook:focus,
.dashboard-btn-check:checked + .dashboard-btn-facebook:focus,
.dashboard-btn-facebook.dashboard-active:focus,
.dashboard-btn-facebook:active:focus,
.dashboard-show > .dashboard-btn-facebook.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.dashboard-btn-facebook.dashboard-disabled,
.dashboard-btn-facebook:disabled {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}
.dashboard-btn-facebook,
.dashboard-btn-facebook.dashboard-disabled,
.dashboard-btn-facebook.dashboard-focus,
.dashboard-btn-facebook.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-facebook:disabled,
.dashboard-btn-facebook:focus,
.dashboard-btn-facebook:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-facebook.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-twitter,
.dashboard-btn-twitter:focus,
.dashboard-btn-twitter:hover {
  background-color: #3faff4;
  border-color: #34aaf3;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-twitter,
.dashboard-btn-twitter:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 137, 206, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-twitter,
.dashboard-btn-check:checked + .dashboard-btn-twitter,
.dashboard-btn-twitter.dashboard-active,
.dashboard-btn-twitter:active,
.dashboard-show > .dashboard-btn-twitter.dashboard-dropdown-toggle {
  background-color: #4ab4f5;
  border-color: #34aaf3;
  color: #000;
}
.dashboard-btn-check:active + .dashboard-btn-twitter:focus,
.dashboard-btn-check:checked + .dashboard-btn-twitter:focus,
.dashboard-btn-twitter.dashboard-active:focus,
.dashboard-btn-twitter:active:focus,
.dashboard-show > .dashboard-btn-twitter.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 137, 206, 0.5);
}
.dashboard-btn-twitter.dashboard-disabled,
.dashboard-btn-twitter:disabled {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #000;
}
.dashboard-btn-twitter,
.dashboard-btn-twitter.dashboard-disabled,
.dashboard-btn-twitter.dashboard-focus,
.dashboard-btn-twitter.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-twitter:disabled,
.dashboard-btn-twitter:focus,
.dashboard-btn-twitter:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-twitter.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-google {
  background-color: #dc4e41;
  border-color: #dc4e41;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-google,
.dashboard-btn-google:focus,
.dashboard-btn-google:hover {
  background-color: #bb4237;
  border-color: #b03e34;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-google,
.dashboard-btn-google:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-google,
.dashboard-btn-check:checked + .dashboard-btn-google,
.dashboard-btn-google.dashboard-active,
.dashboard-btn-google:active,
.dashboard-show > .dashboard-btn-google.dashboard-dropdown-toggle {
  background-color: #b03e34;
  border-color: #a53b31;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-google:focus,
.dashboard-btn-check:checked + .dashboard-btn-google:focus,
.dashboard-btn-google.dashboard-active:focus,
.dashboard-btn-google:active:focus,
.dashboard-show > .dashboard-btn-google.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);
}
.dashboard-btn-google.dashboard-disabled,
.dashboard-btn-google:disabled {
  background-color: #dc4e41;
  border-color: #dc4e41;
  color: #fff;
}
.dashboard-btn-google,
.dashboard-btn-google.dashboard-disabled,
.dashboard-btn-google.dashboard-focus,
.dashboard-btn-google.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-google:disabled,
.dashboard-btn-google:focus,
.dashboard-btn-google:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-google.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-youtube {
  background-color: red;
  border-color: red;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-youtube,
.dashboard-btn-youtube:focus,
.dashboard-btn-youtube:hover {
  background-color: #d90000;
  border-color: #c00;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-youtube,
.dashboard-btn-youtube:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-youtube,
.dashboard-btn-check:checked + .dashboard-btn-youtube,
.dashboard-btn-youtube.dashboard-active,
.dashboard-btn-youtube:active,
.dashboard-show > .dashboard-btn-youtube.dashboard-dropdown-toggle {
  background-color: #c00;
  border-color: #bf0000;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-youtube:focus,
.dashboard-btn-check:checked + .dashboard-btn-youtube:focus,
.dashboard-btn-youtube.dashboard-active:focus,
.dashboard-btn-youtube:active:focus,
.dashboard-show > .dashboard-btn-youtube.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}
.dashboard-btn-youtube.dashboard-disabled,
.dashboard-btn-youtube:disabled {
  background-color: red;
  border-color: red;
  color: #fff;
}
.dashboard-btn-youtube,
.dashboard-btn-youtube.dashboard-disabled,
.dashboard-btn-youtube.dashboard-focus,
.dashboard-btn-youtube.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-youtube:disabled,
.dashboard-btn-youtube:focus,
.dashboard-btn-youtube:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-youtube.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-vimeo {
  background-color: #1ab7ea;
  border-color: #1ab7ea;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-vimeo,
.dashboard-btn-vimeo:focus,
.dashboard-btn-vimeo:hover {
  background-color: #3cc2ed;
  border-color: #31beec;
  color: #000;
}
.dashboard-btn-check:focus + .dashboard-btn-vimeo,
.dashboard-btn-vimeo:focus {
  box-shadow: 0 0 0 0.2rem rgba(22, 156, 199, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-vimeo,
.dashboard-btn-check:checked + .dashboard-btn-vimeo,
.dashboard-btn-vimeo.dashboard-active,
.dashboard-btn-vimeo:active,
.dashboard-show > .dashboard-btn-vimeo.dashboard-dropdown-toggle {
  background-color: #48c5ee;
  border-color: #31beec;
  color: #000;
}
.dashboard-btn-check:active + .dashboard-btn-vimeo:focus,
.dashboard-btn-check:checked + .dashboard-btn-vimeo:focus,
.dashboard-btn-vimeo.dashboard-active:focus,
.dashboard-btn-vimeo:active:focus,
.dashboard-show > .dashboard-btn-vimeo.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(22, 156, 199, 0.5);
}
.dashboard-btn-vimeo.dashboard-disabled,
.dashboard-btn-vimeo:disabled {
  background-color: #1ab7ea;
  border-color: #1ab7ea;
  color: #000;
}
.dashboard-btn-vimeo,
.dashboard-btn-vimeo.dashboard-disabled,
.dashboard-btn-vimeo.dashboard-focus,
.dashboard-btn-vimeo.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-vimeo:disabled,
.dashboard-btn-vimeo:focus,
.dashboard-btn-vimeo:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-vimeo.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-dribbble {
  background-color: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-dribbble,
.dashboard-btn-dribbble:focus,
.dashboard-btn-dribbble:hover {
  background-color: #c74174;
  border-color: #bb3d6e;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-dribbble,
.dashboard-btn-dribbble:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-dribbble,
.dashboard-btn-check:checked + .dashboard-btn-dribbble,
.dashboard-btn-dribbble.dashboard-active,
.dashboard-btn-dribbble:active,
.dashboard-show > .dashboard-btn-dribbble.dashboard-dropdown-toggle {
  background-color: #bb3d6e;
  border-color: #b03967;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-dribbble:focus,
.dashboard-btn-check:checked + .dashboard-btn-dribbble:focus,
.dashboard-btn-dribbble.dashboard-active:focus,
.dashboard-btn-dribbble:active:focus,
.dashboard-show > .dashboard-btn-dribbble.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
}
.dashboard-btn-dribbble.dashboard-disabled,
.dashboard-btn-dribbble:disabled {
  background-color: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}
.dashboard-btn-dribbble,
.dashboard-btn-dribbble.dashboard-disabled,
.dashboard-btn-dribbble.dashboard-focus,
.dashboard-btn-dribbble.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-dribbble:disabled,
.dashboard-btn-dribbble:focus,
.dashboard-btn-dribbble:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-dribbble.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-github {
  background-color: #181717;
  border-color: #181717;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-github,
.dashboard-btn-github:focus,
.dashboard-btn-github:hover {
  background-color: #141414;
  border-color: #131212;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-github,
.dashboard-btn-github:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 58, 58, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-github,
.dashboard-btn-check:checked + .dashboard-btn-github,
.dashboard-btn-github.dashboard-active,
.dashboard-btn-github:active,
.dashboard-show > .dashboard-btn-github.dashboard-dropdown-toggle {
  background-color: #131212;
  border-color: #121111;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-github:focus,
.dashboard-btn-check:checked + .dashboard-btn-github:focus,
.dashboard-btn-github.dashboard-active:focus,
.dashboard-btn-github:active:focus,
.dashboard-show > .dashboard-btn-github.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 58, 58, 0.5);
}
.dashboard-btn-github.dashboard-disabled,
.dashboard-btn-github:disabled {
  background-color: #181717;
  border-color: #181717;
  color: #fff;
}
.dashboard-btn-github,
.dashboard-btn-github.dashboard-disabled,
.dashboard-btn-github.dashboard-focus,
.dashboard-btn-github.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-github:disabled,
.dashboard-btn-github:focus,
.dashboard-btn-github:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-github.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-instagram {
  background-color: #e4405f;
  border-color: #e4405f;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-instagram,
.dashboard-btn-instagram:focus,
.dashboard-btn-instagram:hover {
  background-color: #c23651;
  border-color: #b6334c;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-instagram,
.dashboard-btn-instagram:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-instagram,
.dashboard-btn-check:checked + .dashboard-btn-instagram,
.dashboard-btn-instagram.dashboard-active,
.dashboard-btn-instagram:active,
.dashboard-show > .dashboard-btn-instagram.dashboard-dropdown-toggle {
  background-color: #b6334c;
  border-color: #ab3047;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-instagram:focus,
.dashboard-btn-check:checked + .dashboard-btn-instagram:focus,
.dashboard-btn-instagram.dashboard-active:focus,
.dashboard-btn-instagram:active:focus,
.dashboard-show > .dashboard-btn-instagram.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
}
.dashboard-btn-instagram.dashboard-disabled,
.dashboard-btn-instagram:disabled {
  background-color: #e4405f;
  border-color: #e4405f;
  color: #fff;
}
.dashboard-btn-instagram,
.dashboard-btn-instagram.dashboard-disabled,
.dashboard-btn-instagram.dashboard-focus,
.dashboard-btn-instagram.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-instagram:disabled,
.dashboard-btn-instagram:focus,
.dashboard-btn-instagram:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-instagram.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-pinterest,
.dashboard-btn-pinterest:focus,
.dashboard-btn-pinterest:hover {
  background-color: #a10718;
  border-color: #970616;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-pinterest,
.dashboard-btn-pinterest:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-pinterest,
.dashboard-btn-check:checked + .dashboard-btn-pinterest,
.dashboard-btn-pinterest.dashboard-active,
.dashboard-btn-pinterest:active,
.dashboard-show > .dashboard-btn-pinterest.dashboard-dropdown-toggle {
  background-color: #970616;
  border-color: #8e0615;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-pinterest:focus,
.dashboard-btn-check:checked + .dashboard-btn-pinterest:focus,
.dashboard-btn-pinterest.dashboard-active:focus,
.dashboard-btn-pinterest:active:focus,
.dashboard-show > .dashboard-btn-pinterest.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
}
.dashboard-btn-pinterest.dashboard-disabled,
.dashboard-btn-pinterest:disabled {
  background-color: #bd081c;
  border-color: #bd081c;
  color: #fff;
}
.dashboard-btn-pinterest,
.dashboard-btn-pinterest.dashboard-disabled,
.dashboard-btn-pinterest.dashboard-focus,
.dashboard-btn-pinterest.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-pinterest:disabled,
.dashboard-btn-pinterest:focus,
.dashboard-btn-pinterest:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-pinterest.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-flickr {
  background-color: #0063dc;
  border-color: #0063dc;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-flickr,
.dashboard-btn-flickr:focus,
.dashboard-btn-flickr:hover {
  background-color: #0054bb;
  border-color: #004fb0;
  color: #fff;
}
.dashboard-btn-check:focus + .dashboard-btn-flickr,
.dashboard-btn-flickr:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 122, 225, 0.5);
}
.dashboard-btn-check:active + .dashboard-btn-flickr,
.dashboard-btn-check:checked + .dashboard-btn-flickr,
.dashboard-btn-flickr.dashboard-active,
.dashboard-btn-flickr:active,
.dashboard-show > .dashboard-btn-flickr.dashboard-dropdown-toggle {
  background-color: #004fb0;
  border-color: #004aa5;
  color: #fff;
}
.dashboard-btn-check:active + .dashboard-btn-flickr:focus,
.dashboard-btn-check:checked + .dashboard-btn-flickr:focus,
.dashboard-btn-flickr.dashboard-active:focus,
.dashboard-btn-flickr:active:focus,
.dashboard-show > .dashboard-btn-flickr.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 122, 225, 0.5);
}
.dashboard-btn-flickr.dashboard-disabled,
.dashboard-btn-flickr:disabled {
  background-color: #0063dc;
  border-color: #0063dc;
  color: #fff;
}
.dashboard-btn-flickr,
.dashboard-btn-flickr.dashboard-disabled,
.dashboard-btn-flickr.dashboard-focus,
.dashboard-btn-flickr.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-flickr:disabled,
.dashboard-btn-flickr:focus,
.dashboard-btn-flickr:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-flickr.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-bitbucket {
  background-color: #0052cc;
  border-color: #0052cc;
  color: #fff;
}
.dashboard-btn-bitbucket:focus,
.dashboard-btn-bitbucket:hover,
.dashboard-btn-check:focus + .dashboard-btn-bitbucket {
  background-color: #0046ad;
  border-color: #0042a3;
  color: #fff;
}
.dashboard-btn-bitbucket:focus,
.dashboard-btn-check:focus + .dashboard-btn-bitbucket {
  box-shadow: 0 0 0 0.2rem rgba(38, 108, 212, 0.5);
}
.dashboard-btn-bitbucket.dashboard-active,
.dashboard-btn-bitbucket:active,
.dashboard-btn-check:active + .dashboard-btn-bitbucket,
.dashboard-btn-check:checked + .dashboard-btn-bitbucket,
.dashboard-show > .dashboard-btn-bitbucket.dashboard-dropdown-toggle {
  background-color: #0042a3;
  border-color: #003e99;
  color: #fff;
}
.dashboard-btn-bitbucket.dashboard-active:focus,
.dashboard-btn-bitbucket:active:focus,
.dashboard-btn-check:active + .dashboard-btn-bitbucket:focus,
.dashboard-btn-check:checked + .dashboard-btn-bitbucket:focus,
.dashboard-show > .dashboard-btn-bitbucket.dashboard-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 108, 212, 0.5);
}
.dashboard-btn-bitbucket.dashboard-disabled,
.dashboard-btn-bitbucket:disabled {
  background-color: #0052cc;
  border-color: #0052cc;
  color: #fff;
}
.dashboard-btn-bitbucket,
.dashboard-btn-bitbucket.dashboard-disabled,
.dashboard-btn-bitbucket.dashboard-focus,
.dashboard-btn-bitbucket.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-bitbucket:disabled,
.dashboard-btn-bitbucket:focus,
.dashboard-btn-bitbucket:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-bitbucket.dashboard-dropdown-toggle {
  color: #fff;
}
.dashboard-btn-light,
.dashboard-btn-light.dashboard-disabled,
.dashboard-btn-light.dashboard-focus,
.dashboard-btn-light.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-light:disabled,
.dashboard-btn-light:focus,
.dashboard-btn-light:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-white,
.dashboard-btn-white.dashboard-disabled,
.dashboard-btn-white.dashboard-focus,
.dashboard-btn-white.dashboard-hover:not(:disabled):not(.dashboard-disabled),
.dashboard-btn-white:disabled,
.dashboard-btn-white:focus,
.dashboard-btn-white:hover:not(:disabled):not(.dashboard-disabled),
.dashboard-show > .dashboard-btn-light.dashboard-dropdown-toggle,
.dashboard-show > .dashboard-btn-white.dashboard-dropdown-toggle {
  color: #343a40;
}
.dashboard-card {
  box-shadow: 0 0 0.875rem 0 rgba(33, 37, 41, 0.05);
  margin-bottom: 24px;
}
.dashboard-card-header {
  border-bottom-width: 1px;
}
.dashboard-card-title {
  color: #939ba2;
  font-size: 0.925rem;
  font-weight: 600;
}
.dashboard-card-subtitle {
  font-weight: 400;
}
.dashboard-card-table {
  margin-bottom: 0;
}
.dashboard-card-table tr td:first-child,
.dashboard-card-table tr th:first-child {
  padding-left: 1.25rem;
}
.dashboard-card-table tr td:last-child,
.dashboard-card-table tr th:last-child {
  padding-right: 1.25rem;
}
.dashboard-card-img,
.dashboard-card-img-bottom,
.dashboard-card-img-top {
  height: auto;
  max-width: 100%;
}
@media (-ms-high-contrast: none) {
  .dashboard-card-img,
  .dashboard-card-img-bottom,
  .dashboard-card-img-top {
    height: 100%;
  }
}
.dashboard-chart {
  margin: auto;
  min-height: 300px;
  position: relative;
  width: 100%;
}
.dashboard-chart-xs {
  min-height: 200px;
}
.dashboard-chart-sm {
  min-height: 252px;
}
.dashboard-chart-lg {
  min-height: 350px;
}
.dashboard-chart-xl {
  min-height: 500px;
}
.dashboard-chart canvas {
  max-width: 100%;
}
.dashboard-content {
  direction: ltr;
  flex: 1;
  max-width: 100vw;
  padding: 1.5rem 1.5rem 0.75rem;
  width: 100vw;
}
@media (min-width: 768px) {
  .dashboard-content {
    max-width: auto;
    width: auto;
  }
}
@media (min-width: 992px) {
  .dashboard-content {
    padding: 3rem 3rem 1.5rem;
  }
}
.dashboard-navbar-nav .dashboard-dropdown-menu {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}
.dashboard-dropdown .dashboard-dropdown-menu.dashboard-show {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: dropdownAnimation;
  animation-name: dropdownAnimation;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}
@-webkit-keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translate(0);
  }
}
.dashboard-dropdown-toggle:after {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
}
.dashboard-dropdown-item {
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
}
.dashboard-dropdown-menu {
  top: auto;
}
.dashboard-dropdown-menu-lg {
  min-width: 20rem;
}
.dashboard-dropdown .dashboard-list-group .dashboard-list-group-item {
  border-width: 0 0 1px;
  margin-bottom: 0;
}
.dashboard-dropdown .dashboard-list-group .dashboard-list-group-item:first-child,
.dashboard-dropdown .dashboard-list-group .dashboard-list-group-item:last-child {
  border-radius: 0;
}
.dashboard-dropdown .dashboard-list-group .dashboard-list-group-item:hover {
  background: #f8f9fa;
}
.dashboard-dropdown-menu-header {
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
  padding: 0.75rem;
  text-align: center;
}
.dashboard-dropdown-menu-footer {
  display: block;
  font-size: 0.75rem;
  padding: 0.5rem;
  text-align: center;
}
.dashboard-feather {
  stroke-width: 2;
  height: 18px;
  width: 18px;
}
.dashboard-feather-sm {
  height: 14px;
  width: 14px;
}
.dashboard-feather-lg {
  height: 36px;
  width: 36px;
}
footer.dashboard-footer {
  background: #fff;
  direction: ltr;
  padding: 1rem 0.875rem;
}
footer.dashboard-footer ul {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  footer.dashboard-footer {
    width: 100vw;
  }
}
.dashboard-hamburger,
.dashboard-hamburger:after,
.dashboard-hamburger:before {
  background: #495057;
  border-radius: 1px;
  content: "";
  cursor: pointer;
  display: block;
  height: 3px;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
  width: 24px;
}
.dashboard-hamburger {
  position: relative;
}
.dashboard-hamburger:before {
  position: absolute;
  top: -7.5px;
  width: 24px;
}
.dashboard-hamburger:after {
  bottom: -7.5px;
  position: absolute;
  width: 16px;
}
.dashboard-sidebar-toggle:hover .dashboard-hamburger,
.dashboard-sidebar-toggle:hover .dashboard-hamburger:after,
.dashboard-sidebar-toggle:hover .dashboard-hamburger:before {
  background: #ffa41e;
}
.dashboard-hamburger-right,
.dashboard-hamburger-right:after,
.dashboard-hamburger-right:before {
  right: 0;
}
a.dashboard-list-group-item {
  text-decoration: none;
}
.dashboard-main {
  background: #f5f7fb;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 0;
  overflow: hidden;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
  width: 100%;
}
.dashboard-navbar {
  border-bottom: 0;
  box-shadow: 0 0 2rem 0 rgba(33, 37, 41, 0.1);
}
@media (max-width: 767.98px) {
  .dashboard-navbar {
    width: 100vw;
  }
}
.dashboard-navbar .dashboard-avatar {
  margin-bottom: -15px;
  margin-top: -15px;
}
.dashboard-navbar-nav {
  direction: ltr;
}
.dashboard-navbar-align {
  margin-left: auto;
}
.dashboard-navbar-bg {
  background: #fff;
}
.dashboard-navbar-brand {
  color: #f8f9fa;
  display: block;
  font-size: 1.15rem;
  font-weight: 400;
  padding: 0.875rem 0;
}
.dashboard-navbar-brand .dashboard-feather,
.dashboard-navbar-brand svg {
  color: #ffa41e;
  height: 24px;
  margin-left: -0.15rem;
  margin-right: 0.375rem;
  margin-top: -0.375rem;
  width: 24px;
}
.dashboard-nav-flag,
.dashboard-nav-icon {
  color: #6c757d;
  display: block;
  font-size: 1.5rem;
  line-height: 1.4;
  padding: 0.1rem 0.8rem;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
}
.dashboard-nav-flag:after,
.dashboard-nav-icon:after {
  display: none !important;
}
.dashboard-nav-flag.dashboard-active,
.dashboard-nav-flag:hover,
.dashboard-nav-icon.dashboard-active,
.dashboard-nav-icon:hover {
  color: #ff9d33;
}
.dashboard-nav-flag .dashboard-feather,
.dashboard-nav-flag svg,
.dashboard-nav-icon .dashboard-feather,
.dashboard-nav-icon svg {
  height: 20px;
  width: 20px;
}
.dashboard-nav-item .dashboard-indicator {
  background: #ffa41e;
  border-radius: 50%;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  color: #fff;
  display: block;
  font-size: 0.675rem;
  height: 18px;
  padding: 1px;
  position: absolute;
  right: -8px;
  text-align: center;
  top: 0;
  transition: top 0.1s ease-out;
  width: 18px;
}
.dashboard-nav-item:hover .dashboard-indicator {
  top: -4px;
}
.dashboard-nav-item a:focus {
  outline: 0;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .dashboard-navbar .dashboard-avatar {
    max-height: 47px;
  }
}
@media (max-width: 575.98px) {
  .dashboard-navbar {
    padding: 0.75rem;
  }
  .dashboard-nav-icon {
    padding: 0.1rem 0.75rem;
  }
  .dashboard-dropdown,
  .dashboard-dropleft,
  .dashboard-dropright,
  .dashboard-dropup {
    position: inherit;
  }
  .dashboard-navbar-expand .dashboard-navbar-nav .dashboard-dropdown-menu-lg {
    min-width: 100%;
  }
  .dashboard-nav-item .dashboard-nav-link:after {
    display: none;
  }
}
.dashboard-nav-flag img {
  border-radius: 50%;
  height: 20px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 20px;
}
.dashboard-navbar input {
  direction: ltr;
}
#root,
body,
html {
  height: 100%;
}
body {
  opacity: 1 !important;
  overflow-y: scroll;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  html {
    overflow-x: hidden;
  }
}
.dashboard-sidebar {
  direction: ltr;
  max-width: 280px;
  min-width: 280px;
}
.dashboard-sidebar,
.dashboard-sidebar-content {
  background: #ebebeb; /* #222e3c; */
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
}
.dashboard-sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.dashboard-sidebar-nav {
  flex-grow: 1;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.dashboard-sidebar-link,
a.dashboard-sidebar-link {
  background: #ebebeb; /* #222e3c; */
  border-left: 3px solid transparent;
  color: #222; /* rgba(233, 236, 239, 0.5); */
  cursor: pointer;
  display: block;
  font-weight: 400;
  padding: 0.625rem 1.625rem;
  position: relative;
  text-decoration: none;
  transition: background 0.1s ease-in-out;
}
.dashboard-sidebar-link i,
.dashboard-sidebar-link svg,
a.dashboard-sidebar-link i,
a.dashboard-sidebar-link svg {
  color: #222; /* rgba(233, 236, 239, 0.5); */
  margin-right: 0.75rem;
}
.dashboard-sidebar-link:focus {
  outline: 0;
}
.dashboard-sidebar-link:hover {
  background: #ebebeb; /* #222e3c; */
  border-left-color: transparent;
}
.dashboard-sidebar-link:hover,
.dashboard-sidebar-link:hover i,
.dashboard-sidebar-link:hover svg {
  color: #333; /* rgba(233, 236, 239, 0.75); */
}
.dashboard-sidebar-item-active a {
  background: linear-gradient(
    90deg,
    rgba(183, 183, 183, 0.3),
    rgba(235, 235, 235, 0.088) 100%,
    transparent
  );
  border-left-color: #ffa41e;
  color: #222; /* #e9ecef; */
}
.dashboard-sidebar-link:hover {
  background: linear-gradient(
    90deg,
    rgba(183, 183, 183, 0.3),
    rgba(235, 235, 235, 0.088) 100%,
    transparent
  );
  border-left-color: #ffa41e;
  color: #222; /* #e9ecef; */
}
.dashboard-sidebar-item.dashboard-active .dashboard-sidebar-link:hover,
.dashboard-sidebar-item.dashboard-active > .dashboard-sidebar-link {
  background: linear-gradient(
    90deg,
    rgba(183, 183, 183, 0.3),
    rgba(235, 235, 235, 0.088) 100%,
    transparent
  );
  border-left-color: #ffa41e;
  color: #222; /* #e9ecef; */
}
.dashboard-sidebar-item.dashboard-active .dashboard-sidebar-link:hover i,
.dashboard-sidebar-item.dashboard-active .dashboard-sidebar-link:hover svg,
.dashboard-sidebar-item.dashboard-active > .dashboard-sidebar-link i,
.dashboard-sidebar-item.dashboard-active > .dashboard-sidebar-link svg {
  color: #222; /* #e9ecef; */
}
.dashboard-sidebar-brand {
  color: #222; /* #f8f9fa; */
  display: block;
  font-size: 1.15rem;
  font-weight: 600;
  padding: 1.15rem 1.5rem;
}
.dashboard-sidebar-brand:hover {
  color: #333; /* #f8f9fa; */
  text-decoration: none;
}
.dashboard-sidebar-brand:focus {
  outline: 0;
}
.dashboard-sidebar-toggle {
  cursor: pointer;
  display: flex;
  height: 26px;
  width: 26px;
}
.dashboard-sidebar.dashboard-collapsed {
  margin-left: -280px;
}
@media (min-width: 1px) and (max-width: 991.98px) {
  .dashboard-sidebar {
    margin-left: -280px;
  }
  .dashboard-sidebar.dashboard-collapsed {
    margin-left: 0;
  }
}
.dashboard-sidebar-toggle {
  margin-right: 1rem;
}
.dashboard-sidebar-header {
  background: transparent;
  color: #222; /* #ced4da; */
  font-size: 0.75rem;
  padding: 1.5rem 1.5rem 0.375rem;
}
.dashboard-sidebar-badge {
  position: absolute;
  right: 15px;
  top: 14px;
  z-index: 1;
}
.dashboard-sidebar-cta-content {
  background: #222; /* #2b3947; */
  border-radius: 0.3rem;
  color: #222; /* #e9ecef; */
  margin: 1.75rem;
  padding: 1.5rem;
}
.dashboard-min-vw-50 {
  min-width: 50vw !important;
}
.dashboard-min-vh-50 {
  min-height: 50vh !important;
}
.dashboard-vw-50 {
  width: 50vw !important;
}
.dashboard-vh-50 {
  height: 50vh !important;
}
.dashboard-stat {
  align-items: center;
  background: #d3e2f7;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}
.dashboard-stat svg {
  stroke-width: 1.5;
  color: #ffa41e !important;
  height: 18px;
  width: 18px;
}
.dashboard-table > :not(:last-child) > :last-child > *,
.dashboard-table tbody,
.dashboard-table td,
.dashboard-table tfoot,
.dashboard-table th,
.dashboard-table thead,
.dashboard-table tr {
  border-color: #dee2e6;
}
.dashboard-table > tbody > tr > td {
  vertical-align: middle;
}
.dashboard-text-sm {
  font-size: 0.75rem;
}
.dashboard-text-lg {
  font-size: 0.925rem;
}
b,
strong {
  font-weight: 600;
}
pre.dashboard-snippet {
  word-wrap: break-word;
  text-align: justify;
  white-space: pre-wrap;
}
a {
  cursor: pointer;
}
.wrapper {
  align-items: stretch;
  background: #222e3c;
  display: flex;
  width: 100%;
}
.dashboard-cursor-grab {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}
.dashboard-cursor-pointer {
  cursor: pointer;
}
svg {
  touch-action: none;
}
.jvm-zoomin,
.jvm-zoomout,
image,
text {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.jvm-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  touch-action: none;
  width: 100%;
}
.jvm-tooltip {
  background-color: #5c5cff;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
  display: none;
  font-family: sans-serif, Verdana !important;
  font-size: smaller;
  padding: 3px 5px;
  white-space: nowrap;
}
.jvm-tooltip,
.jvm-zoom-btn {
  border-radius: 3px;
  color: #fff;
  position: absolute;
}
.jvm-zoom-btn {
  background-color: #292929;
  box-sizing: border-box;
  cursor: pointer;
  height: 15px;
  left: 10px;
  line-height: 10px;
  padding: 3px;
  width: 15px;
}
.jvm-zoom-btn.jvm-zoomout {
  top: 30px;
}
.jvm-zoom-btn.jvm-zoomin {
  top: 10px;
}
.jvm-series-container {
  position: absolute;
  right: 15px;
}
.jvm-series-container.jvm-series-h {
  bottom: 15px;
}
.jvm-series-container.jvm-series-v {
  top: 15px;
}
.jvm-series-container .jvm-legend {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  float: left;
  margin-left: 0.75rem;
  padding: 0.6rem;
}
.jvm-series-container .jvm-legend .jvm-legend-title {
  border-bottom: 1px solid #e5e7eb;
  line-height: 1;
  margin-bottom: 0.575rem;
  padding-bottom: 0.5rem;
  text-align: left;
}
.jvm-series-container .jvm-legend .jvm-legend-inner {
  overflow: hidden;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick {
  min-width: 40px;
  overflow: hidden;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick:not(:first-child) {
  margin-top: 0.575rem;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-sample {
  border-radius: 4px;
  float: left;
  height: 16px;
  margin-right: 0.65rem;
  width: 16px;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-text {
  float: left;
  font-size: 12px;
  text-align: center;
}
.jvm-line[animation="true"] {
  -webkit-animation: jvm-line-animation 10s linear infinite forwards;
  animation: jvm-line-animation 10s linear infinite forwards;
}
@-webkit-keyframes jvm-line-animation {
  0% {
    stroke-dashoffset: 250;
  }
}
@keyframes jvm-line-animation {
  0% {
    stroke-dashoffset: 250;
  }
}
[data-simplebar] {
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
}
.dashboard-simplebar-wrapper {
  height: inherit;
  max-height: inherit;
  max-width: inherit;
  overflow: hidden;
  width: inherit;
}
.dashboard-simplebar-mask {
  direction: inherit;
  height: auto !important;
  overflow: hidden;
  width: auto !important;
  z-index: 0;
}
.dashboard-simplebar-mask,
.dashboard-simplebar-offset {
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.dashboard-simplebar-offset {
  -webkit-overflow-scrolling: touch;
  box-sizing: inherit !important;
  direction: inherit !important;
  resize: none !important;
}
.dashboard-simplebar-content-wrapper {
  -ms-overflow-style: none;
  box-sizing: border-box !important;
  direction: inherit;
  display: block;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  scrollbar-width: none;
  width: auto;
}
.dashboard-simplebar-content-wrapper::-webkit-scrollbar,
.dashboard-simplebar-hide-scrollbar::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.dashboard-simplebar-content:after,
.dashboard-simplebar-content:before {
  content: " ";
  display: table;
}
.dashboard-simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  width: 100%;
}
.dashboard-simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  flex-basis: 0;
  flex-grow: inherit;
  flex-shrink: 0;
  float: left;
  height: 100%;
  margin: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: -1;
}
.dashboard-simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  height: 1000%;
  left: 0;
  min-height: 1px;
  min-width: 1px;
  opacity: 0;
  top: 0;
  width: 1000%;
  z-index: -1;
}
.dashboard-simplebar-height-auto-observer,
.dashboard-simplebar-track {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
}
.dashboard-simplebar-track {
  bottom: 0;
  right: 0;
  z-index: 1;
}
[data-simplebar].dashboard-simplebar-dragging .dashboard-simplebar-content {
  pointer-events: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}
[data-simplebar].dashboard-simplebar-dragging .dashboard-simplebar-track {
  pointer-events: all;
}
.dashboard-simplebar-scrollbar {
  left: 0;
  min-height: 10px;
  position: absolute;
  right: 0;
}
.dashboard-simplebar-scrollbar:before {
  background: #000;
  border-radius: 7px;
  content: "";
  left: 2px;
  opacity: 0;
  position: absolute;
  right: 2px;
  transition: opacity 0.2s linear;
}
.dashboard-simplebar-scrollbar.dashboard-simplebar-visible:before {
  opacity: 0.5;
  transition: opacity 0s linear;
}
.dashboard-simplebar-track.dashboard-simplebar-vertical {
  top: 0;
  width: 11px;
}
.dashboard-simplebar-track.dashboard-simplebar-vertical .dashboard-simplebar-scrollbar:before {
  bottom: 2px;
  top: 2px;
}
.dashboard-simplebar-track.dashboard-simplebar-horizontal {
  height: 11px;
  left: 0;
}
.dashboard-simplebar-track.dashboard-simplebar-horizontal .dashboard-simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}
.dashboard-simplebar-track.dashboard-simplebar-horizontal .dashboard-simplebar-scrollbar {
  height: 7px;
  left: 0;
  min-height: 0;
  min-width: 10px;
  right: auto;
  top: 2px;
  width: auto;
}
[data-simplebar-direction="rtl"] .dashboard-simplebar-track.dashboard-simplebar-vertical {
  left: 0;
  right: auto;
}
.dashboard-hs-dummy-scrollbar-size {
  direction: rtl;
  height: 500px;
  opacity: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  position: fixed;
  visibility: hidden;
  width: 500px;
}
.dashboard-simplebar-hide-scrollbar {
  -ms-overflow-style: none;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  scrollbar-width: none;
  visibility: hidden;
}
.dashboard-flatpickr-calendar {
  -webkit-animation: none;
  animation: none;
  background: transparent;
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6,
    0 3px 13px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  direction: ltr;
  display: none;
  font-size: 14px;
  line-height: 24px;
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  touch-action: manipulation;
  visibility: hidden;
  width: 307.875px;
}
.dashboard-flatpickr-calendar.dashboard-inline,
.dashboard-flatpickr-calendar.dashboard-open {
  max-height: 640px;
  opacity: 1;
  visibility: visible;
}
.dashboard-flatpickr-calendar.dashboard-open {
  display: inline-block;
  z-index: 99999;
}
.dashboard-flatpickr-calendar.dashboard-animate.dashboard-open {
  -webkit-animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.dashboard-flatpickr-calendar.dashboard-inline {
  display: block;
  position: relative;
  top: 2px;
}
.dashboard-flatpickr-calendar.dashboard-static {
  position: absolute;
  top: calc(100% + 2px);
}
.dashboard-flatpickr-calendar.dashboard-static.dashboard-open {
  display: block;
  z-index: 999;
}
.dashboard-flatpickr-calendar.dashboard-multiMonth
  .dashboard-flatpickr-days
  .dayContainer:nth-child(n + 1)
  .dashboard-flatpickr-day.dashboard-inRange:nth-child(7n + 7) {
  box-shadow: none !important;
}
.dashboard-flatpickr-calendar.dashboard-multiMonth
  .dashboard-flatpickr-days
  .dayContainer:nth-child(n + 2)
  .dashboard-flatpickr-day.dashboard-inRange:nth-child(7n + 1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.dashboard-flatpickr-calendar .dashboard-hasTime .dayContainer,
.dashboard-flatpickr-calendar .dashboard-hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dashboard-flatpickr-calendar .dashboard-hasWeeks .dayContainer {
  border-left: 0;
}
.dashboard-flatpickr-calendar.dashboard-hasTime .dashboard-flatpickr-time {
  border-top: 1px solid #e6e6e6;
  height: 40px;
}
.dashboard-flatpickr-calendar.dashboard-noCalendar.dashboard-hasTime .dashboard-flatpickr-time {
  height: auto;
}
.dashboard-flatpickr-calendar:after,
.dashboard-flatpickr-calendar:before {
  border: solid transparent;
  content: "";
  display: block;
  height: 0;
  left: 22px;
  pointer-events: none;
  position: absolute;
  width: 0;
}
.dashboard-flatpickr-calendar.dashboard-arrowRight:after,
.dashboard-flatpickr-calendar.dashboard-arrowRight:before,
.dashboard-flatpickr-calendar.dashboard-rightMost:after,
.dashboard-flatpickr-calendar.dashboard-rightMost:before {
  left: auto;
  right: 22px;
}
.dashboard-flatpickr-calendar.dashboard-arrowCenter:after,
.dashboard-flatpickr-calendar.dashboard-arrowCenter:before {
  left: 50%;
  right: 50%;
}
.dashboard-flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.dashboard-flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.dashboard-flatpickr-calendar.dashboard-arrowTop:after,
.dashboard-flatpickr-calendar.dashboard-arrowTop:before {
  bottom: 100%;
}
.dashboard-flatpickr-calendar.dashboard-arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.dashboard-flatpickr-calendar.dashboard-arrowTop:after {
  border-bottom-color: #fff;
}
.dashboard-flatpickr-calendar.dashboard-arrowBottom:after,
.dashboard-flatpickr-calendar.dashboard-arrowBottom:before {
  top: 100%;
}
.dashboard-flatpickr-calendar.dashboard-arrowBottom:before {
  border-top-color: #e6e6e6;
}
.dashboard-flatpickr-calendar.dashboard-arrowBottom:after {
  border-top-color: #fff;
}
.dashboard-flatpickr-calendar:focus {
  outline: 0;
}
.dashboard-flatpickr-wrapper {
  display: inline-block;
  position: relative;
}
.dashboard-flatpickr-months {
  display: flex;
}
.dashboard-flatpickr-months .dashboard-flatpickr-month {
  fill: rgba(0, 0, 0, 0.9);
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  flex: 1;
  height: 34px;
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.dashboard-flatpickr-months .dashboard-flatpickr-next-month,
.dashboard-flatpickr-months .dashboard-flatpickr-prev-month {
  fill: rgba(0, 0, 0, 0.9);
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  height: 34px;
  padding: 10px;
  position: absolute;
  text-decoration: none;
  top: 0;
  z-index: 3;
}
.dashboard-flatpickr-months .dashboard-flatpickr-next-month.dashboard-flatpickr-disabled,
.dashboard-flatpickr-months .dashboard-flatpickr-prev-month.dashboard-flatpickr-disabled {
  display: none;
}
.dashboard-flatpickr-months .dashboard-flatpickr-next-month i,
.dashboard-flatpickr-months .dashboard-flatpickr-prev-month i {
  position: relative;
}
.dashboard-flatpickr-months .dashboard-flatpickr-next-month.dashboard-flatpickr-prev-month,
.dashboard-flatpickr-months .dashboard-flatpickr-prev-month.dashboard-flatpickr-prev-month {
  left: 0;
}
.dashboard-flatpickr-months .dashboard-flatpickr-next-month.dashboard-flatpickr-next-month,
.dashboard-flatpickr-months .dashboard-flatpickr-prev-month.dashboard-flatpickr-next-month {
  right: 0;
}
.dashboard-flatpickr-months .dashboard-flatpickr-next-month:hover,
.dashboard-flatpickr-months .dashboard-flatpickr-prev-month:hover {
  color: #959ea9;
}
.dashboard-flatpickr-months .dashboard-flatpickr-next-month:hover svg,
.dashboard-flatpickr-months .dashboard-flatpickr-prev-month:hover svg {
  fill: #f64747;
}
.dashboard-flatpickr-months .dashboard-flatpickr-next-month svg,
.dashboard-flatpickr-months .dashboard-flatpickr-prev-month svg {
  height: 14px;
  width: 14px;
}
.dashboard-flatpickr-months .dashboard-flatpickr-next-month svg path,
.dashboard-flatpickr-months .dashboard-flatpickr-prev-month svg path {
  fill: inherit;
  transition: fill 0.1s;
}
.dashboard-numInputWrapper {
  height: auto;
  position: relative;
}
.dashboard-numInputWrapper input,
.dashboard-numInputWrapper span {
  display: inline-block;
}
.dashboard-numInputWrapper input {
  width: 100%;
}
.dashboard-numInputWrapper input::-ms-clear {
  display: none;
}
.dashboard-numInputWrapper input::-webkit-inner-spin-button,
.dashboard-numInputWrapper input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dashboard-numInputWrapper span {
  border: 1px solid rgba(57, 57, 57, 0.15);
  box-sizing: border-box;
  cursor: pointer;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  padding: 0 4px 0 2px;
  position: absolute;
  right: 0;
  width: 14px;
}
.dashboard-numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.dashboard-numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.dashboard-numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}
.dashboard-numInputWrapper span.dashboard-arrowUp {
  border-bottom: 0;
  top: 0;
}
.dashboard-numInputWrapper span.dashboard-arrowUp:after {
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  top: 26%;
}
.dashboard-numInputWrapper span.dashboard-arrowDown {
  top: 50%;
}
.dashboard-numInputWrapper span.dashboard-arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}
.dashboard-numInputWrapper span svg {
  height: auto;
  width: inherit;
}
.dashboard-numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.dashboard-numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.dashboard-numInputWrapper:hover span {
  opacity: 1;
}
.dashboard-flatpickr-current-month {
  color: inherit;
  display: inline-block;
  font-size: 135%;
  font-weight: 300;
  height: 34px;
  left: 12.5%;
  line-height: inherit;
  line-height: 1;
  padding: 7.48px 0 0;
  position: absolute;
  text-align: center;
  transform: translateZ(0);
  width: 75%;
}
.dashboard-flatpickr-current-month span.dashboard-cur-month {
  color: inherit;
  display: inline-block;
  font-family: sans-serif, Verdana !important;
  font-weight: 700;
  margin-left: 0.5ch;
  padding: 0;
}
.dashboard-flatpickr-current-month span.dashboard-cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.dashboard-flatpickr-current-month .dashboard-numInputWrapper {
  display: inline-block;
  width: 6ch;
  width: 7ch\0;
}
.dashboard-flatpickr-current-month .dashboard-numInputWrapper span.dashboard-arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}
.dashboard-flatpickr-current-month .dashboard-numInputWrapper span.dashboard-arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.dashboard-flatpickr-current-month input.dashboard-cur-year {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  display: inline-block;
  font-family: sans-serif, Verdana !important;
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0 0 0 0.5ch;
  vertical-align: initial;
}
.dashboard-flatpickr-current-month input.dashboard-cur-year:focus {
  outline: 0;
}
.dashboard-flatpickr-current-month input.dashboard-cur-year[disabled],
.dashboard-flatpickr-current-month input.dashboard-cur-year[disabled]:hover {
  background: transparent;
  color: rgba(0, 0, 0, 0.5);
  font-size: 100%;
  pointer-events: none;
}
.dashboard-flatpickr-current-month .dashboard-flatpickr-monthDropdown-months {
  appearance: menulist;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-family: sans-serif, Verdana !important;
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  width: auto;
}
.dashboard-flatpickr-current-month .dashboard-flatpickr-monthDropdown-months:active,
.dashboard-flatpickr-current-month .dashboard-flatpickr-monthDropdown-months:focus {
  outline: none;
}
.dashboard-flatpickr-current-month .dashboard-flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.dashboard-flatpickr-current-month
  .dashboard-flatpickr-monthDropdown-months
  .dashboard-flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.dashboard-flatpickr-weekdays {
  align-items: center;
  background: transparent;
  display: flex;
  height: 28px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.dashboard-flatpickr-weekdays .dashboard-flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}
span.dashboard-flatpickr-weekday {
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  cursor: default;
  display: block;
  flex: 1;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  margin: 0;
  text-align: center;
}
.dayContainer,
.dashboard-flatpickr-weeks {
  padding: 1px 0 0;
}
.dashboard-flatpickr-days {
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 307.875px;
}
.dashboard-flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  max-width: 307.875px;
  min-width: 307.875px;
  opacity: 1;
  outline: 0;
  padding: 0;
  text-align: left;
  transform: translateZ(0);
  width: 307.875px;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}
.dashboard-flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  display: inline-block;
  flex-basis: 14.2857143%;
  font-weight: 400;
  height: 39px;
  justify-content: center;
  line-height: 39px;
  margin: 0;
  max-width: 39px;
  position: relative;
  text-align: center;
  width: 14.2857143%;
}
.dashboard-flatpickr-day.dashboard-inRange,
.dashboard-flatpickr-day.dashboard-nextMonthDay.dashboard-inRange,
.dashboard-flatpickr-day.dashboard-nextMonthDay.dashboard-today.dashboard-inRange,
.dashboard-flatpickr-day.dashboard-nextMonthDay:focus,
.dashboard-flatpickr-day.dashboard-nextMonthDay:hover,
.dashboard-flatpickr-day.prevMonthDay.dashboard-inRange,
.dashboard-flatpickr-day.prevMonthDay.dashboard-today.dashboard-inRange,
.dashboard-flatpickr-day.prevMonthDay:focus,
.dashboard-flatpickr-day.prevMonthDay:hover,
.dashboard-flatpickr-day.dashboard-today.dashboard-inRange,
.dashboard-flatpickr-day:focus,
.dashboard-flatpickr-day:hover {
  background: #e6e6e6;
  border-color: #e6e6e6;
  cursor: pointer;
  outline: 0;
}
.dashboard-flatpickr-day.dashboard-today {
  border-color: #959ea9;
}
.dashboard-flatpickr-day.dashboard-today:focus,
.dashboard-flatpickr-day.dashboard-today:hover {
  background: #959ea9;
  border-color: #959ea9;
  color: #fff;
}
.dashboard-flatpickr-day.endRange,
.dashboard-flatpickr-day.endRange.dashboard-inRange,
.dashboard-flatpickr-day.endRange.dashboard-nextMonthDay,
.dashboard-flatpickr-day.endRange.prevMonthDay,
.dashboard-flatpickr-day.endRange:focus,
.dashboard-flatpickr-day.endRange:hover,
.dashboard-flatpickr-day.dashboard-selected,
.dashboard-flatpickr-day.dashboard-selected.dashboard-inRange,
.dashboard-flatpickr-day.dashboard-selected.dashboard-nextMonthDay,
.dashboard-flatpickr-day.dashboard-selected.prevMonthDay,
.dashboard-flatpickr-day.dashboard-selected:focus,
.dashboard-flatpickr-day.dashboard-selected:hover,
.dashboard-flatpickr-day.dashboard-startRange,
.dashboard-flatpickr-day.dashboard-startRange.dashboard-inRange,
.dashboard-flatpickr-day.dashboard-startRange.dashboard-nextMonthDay,
.dashboard-flatpickr-day.dashboard-startRange.prevMonthDay,
.dashboard-flatpickr-day.dashboard-startRange:focus,
.dashboard-flatpickr-day.dashboard-startRange:hover {
  background: #569ff7;
  border-color: #569ff7;
  box-shadow: none;
  color: #fff;
}
.dashboard-flatpickr-day.endRange.dashboard-startRange,
.dashboard-flatpickr-day.dashboard-selected.dashboard-startRange,
.dashboard-flatpickr-day.dashboard-startRange.dashboard-startRange {
  border-radius: 50px 0 0 50px;
}
.dashboard-flatpickr-day.endRange.endRange,
.dashboard-flatpickr-day.dashboard-selected.endRange,
.dashboard-flatpickr-day.dashboard-startRange.endRange {
  border-radius: 0 50px 50px 0;
}
.dashboard-flatpickr-day.endRange.dashboard-startRange + .endRange:not(:nth-child(7n + 1)),
.dashboard-flatpickr-day.dashboard-selected.dashboard-startRange
  + .endRange:not(:nth-child(7n + 1)),
.dashboard-flatpickr-day.dashboard-startRange.dashboard-startRange
  + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #569ff7;
}
.dashboard-flatpickr-day.endRange.dashboard-startRange.endRange,
.dashboard-flatpickr-day.dashboard-selected.dashboard-startRange.endRange,
.dashboard-flatpickr-day.dashboard-startRange.dashboard-startRange.endRange {
  border-radius: 50px;
}
.dashboard-flatpickr-day.dashboard-inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.dashboard-flatpickr-day.dashboard-flatpickr-disabled,
.dashboard-flatpickr-day.dashboard-flatpickr-disabled:hover,
.dashboard-flatpickr-day.dashboard-nextMonthDay,
.dashboard-flatpickr-day.dashboard-notAllowed,
.dashboard-flatpickr-day.dashboard-notAllowed.dashboard-nextMonthDay,
.dashboard-flatpickr-day.dashboard-notAllowed.prevMonthDay,
.dashboard-flatpickr-day.prevMonthDay {
  background: transparent;
  border-color: transparent;
  color: rgba(57, 57, 57, 0.3);
  cursor: default;
}
.dashboard-flatpickr-day.dashboard-flatpickr-disabled,
.dashboard-flatpickr-day.dashboard-flatpickr-disabled:hover {
  color: rgba(57, 57, 57, 0.1);
  cursor: not-allowed;
}
.dashboard-flatpickr-day.week.dashboard-selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}
.dashboard-flatpickr-day.dashboard-hidden {
  visibility: hidden;
}
.dashboard-rangeMode .dashboard-flatpickr-day {
  margin-top: 1px;
}
.dashboard-flatpickr-weekwrapper {
  float: left;
}
.dashboard-flatpickr-weekwrapper .dashboard-flatpickr-weeks {
  box-shadow: 1px 0 0 #e6e6e6;
  padding: 0 12px;
}
.dashboard-flatpickr-weekwrapper .dashboard-flatpickr-weekday {
  float: none;
  line-height: 28px;
  width: 100%;
}
.dashboard-flatpickr-weekwrapper span.dashboard-flatpickr-day,
.dashboard-flatpickr-weekwrapper span.dashboard-flatpickr-day:hover {
  background: transparent;
  border: none;
  color: rgba(57, 57, 57, 0.3);
  cursor: default;
  display: block;
  max-width: none;
  width: 100%;
}
.dashboard-flatpickr-innerContainer {
  box-sizing: border-box;
  display: block;
  display: flex;
  overflow: hidden;
}
.dashboard-flatpickr-rContainer {
  box-sizing: border-box;
  display: inline-block;
  padding: 0;
}
.dashboard-flatpickr-time {
  box-sizing: border-box;
  display: block;
  display: flex;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  outline: 0;
  overflow: hidden;
  text-align: center;
}
.dashboard-flatpickr-time:after {
  clear: both;
  content: "";
  display: table;
}
.dashboard-flatpickr-time .dashboard-numInputWrapper {
  flex: 1;
  float: left;
  height: 40px;
  width: 40%;
}
.dashboard-flatpickr-time .dashboard-numInputWrapper span.dashboard-arrowUp:after {
  border-bottom-color: #393939;
}
.dashboard-flatpickr-time .dashboard-numInputWrapper span.dashboard-arrowDown:after {
  border-top-color: #393939;
}
.dashboard-flatpickr-time.dashboard-hasSeconds .dashboard-numInputWrapper {
  width: 26%;
}
.dashboard-flatpickr-time.dashboard-time24hr .dashboard-numInputWrapper {
  width: 49%;
}
.dashboard-flatpickr-time input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #393939;
  font-size: 14px;
  height: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
}
.dashboard-flatpickr-time input.dashboard-flatpickr-hour {
  font-weight: 700;
}
.dashboard-flatpickr-time input.dashboard-flatpickr-minute,
.dashboard-flatpickr-time input.dashboard-flatpickr-second {
  font-weight: 400;
}
.dashboard-flatpickr-time input:focus {
  border: 0;
  outline: 0;
}
.dashboard-flatpickr-time .dashboard-flatpickr-am-pm,
.dashboard-flatpickr-time .dashboard-flatpickr-time-separator {
  align-self: center;
  color: #393939;
  float: left;
  font-weight: 700;
  height: inherit;
  line-height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 2%;
}
.dashboard-flatpickr-time .dashboard-flatpickr-am-pm {
  cursor: pointer;
  font-weight: 400;
  outline: 0;
  text-align: center;
  width: 18%;
}
.dashboard-flatpickr-time .dashboard-flatpickr-am-pm:focus,
.dashboard-flatpickr-time .dashboard-flatpickr-am-pm:hover,
.dashboard-flatpickr-time input:focus,
.dashboard-flatpickr-time input:hover {
  background: #eee;
}
.dashboard-flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fpFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.dashboard-flatpickr-calendar.dashboard-inline {
  background: transparent;
  box-shadow: none;
  width: 100%;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-days {
  width: 100%;
}
.dashboard-flatpickr-calendar.dashboard-inline .dayContainer {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-day {
  border-radius: 0.2rem;
  height: 45px;
  line-height: 45px;
  max-width: inherit;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-day.dashboard-today {
  border: 0;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-day.dashboard-today:before {
  border-color: rgba(0, 0, 0, 0.2) transparent #ffa41e;
  border-style: solid;
  border-width: 0 0 7px 7px;
  bottom: 4px;
  content: "";
  display: inline-block;
  position: absolute;
  right: 4px;
}
.dashboard-flatpickr-calendar.dashboard-inline
  .dashboard-flatpickr-day.dashboard-today.dashboard-selected:before {
  border-color: rgba(0, 0, 0, 0.2) transparent #fff;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-day.dashboard-today:hover {
  background: #e6e6e6;
  color: #000;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-day.dashboard-selected,
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-day.dashboard-selected:focus,
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-day.dashboard-selected:hover {
  background: #ffa41e;
  border-radius: 0.2rem;
  color: #fff;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-weekdays {
  height: 45px;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-weekday {
  height: 45px;
  line-height: 45px;
}
.dashboard-flatpickr-calendar.dashboard-inline
  .dashboard-flatpickr-months
  .dashboard-flatpickr-month,
.dashboard-flatpickr-calendar.dashboard-inline
  .dashboard-flatpickr-months
  .dashboard-flatpickr-next-month,
.dashboard-flatpickr-calendar.dashboard-inline
  .dashboard-flatpickr-months
  .dashboard-flatpickr-prev-month {
  height: 45px;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-current-month {
  height: 45px;
  line-height: 45px;
  padding-top: 0;
}
.dashboard-flatpickr-calendar.dashboard-inline
  .dashboard-flatpickr-current-month
  .dashboard-flatpickr-monthDropdown-months {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.dashboard-flatpickr-calendar.dashboard-inline
  .dashboard-flatpickr-current-month
  .dashboard-flatpickr-monthDropdown-months,
.dashboard-flatpickr-calendar.dashboard-inline
  .dashboard-flatpickr-current-month
  input.dashboard-cur-year {
  font-size: 1.09375rem;
  font-weight: 400;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-next-month,
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-prev-month {
  border-radius: 0.2rem;
  width: 45px;
}
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-next-month:hover,
.dashboard-flatpickr-calendar.dashboard-inline .dashboard-flatpickr-prev-month:hover {
  background: #e6e6e6;
  color: #000;
}
.dashboard-simplebar-scrollbar:before {
  background: #fff;
}
.dashboard-simplebar-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 0 !important;
}
[data-simplebar] {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 280px;
}
