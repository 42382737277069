/*
    Por si no lo tienen incluido previamente agrega las siguientes propiedades a cada clase:
    margin: 0;
    padding: 0;
    box-sizing: border-box;
*/

.css-1ig23v0-MuiBadge-badge {
  margin: 10px;
}

.character {
  width: 45;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  transform: translateX(-100%);
  animation: character-anim 1s linear forwards;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  object-fit: cover;
}

.logo-img {
  width: 125px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  transform: translateX(-50%);
  animation: brand-anim 1s linear 0.5s forwards;
  margin-left: 40px;
  padding: 0;
  box-sizing: border-box;
}

@keyframes character-anim {
  0% {
    transform: translateX(-100%);
  }
  90%,
  100% {
    transform: translateX(0%);
  }
}
@keyframes brand-anim {
  0% {
    transform: translateX(-50%);
  }
  80% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(-28%);
  }
}
