/* ===================================
	Default Header style
====================================== */

header{ transition: all 0.5s ease 0s; position: relative; top: 0; left: 0; z-index: 99999; }

.navbar-brand img{ max-height: 43px; transition-duration: 0.5s; }
.navbar-header-custom{ padding: 17px 0 15px 0; }
.navbar-default{ transition: all 0.5s ease 0s;  }
.navbar-default .container, .navbar-default .container-fluid{ position: relative; }
.menu_area{ position: relative; z-index: 2; }
.menu_area .navbar-brand{ transition: all 0.5s ease 0s; }

.navbar-nav li{ list-style: none; -webkit-transition: background .3s ease; -ms-transition: background .3s ease; transition: background .3s ease; }
.navbar-nav li.has-sub > a{ position: relative; }
.navbar-nav li.has-sub a:hover{ color: #232323; }
.navbar-nav li a{ display: block; color: #6f6f6f; font-size: 14px; }
.navbar-nav > li{ margin-left: 15px; float: left; }
.navbar-nav > li.has-sub{ padding-right: 13px; }
.navbar-nav > li > a{ position: relative; display: block; font-size: 15px; font-weight: 500; padding: 21px 0.5rem; color: #232323; letter-spacing: 0.5px; -webkit-transition-duration: 500ms; -o-transition-duration: 500ms; transition-duration: 500ms; }
.navbar-nav > li > a:hover { opacity: 0.65; }
.navbar-nav li.current > a{ color: #000; }
.navbar-nav > li.active > a{ color: #000; }
.navbar-nav > li.has-sub > a:hover{ color: #000; }
.navbar > ul > li.has-sub > a:hover:after{ border-color: transparent #000 #000 transparent; }
.navbar > ul > li.has-sub > a:after{ position: absolute; top: 30px; right: -8px; content: ""; border-color: transparent #232323 #232323 transparent; border-style: solid; border-width: 0 1px 1px 0; display: block; height: 6px; transform: rotate(45deg); transition: border 0.3s ease 0s; width: 6px; }
.navbar > ul > li.current > a:after{ border-color: transparent #000 #000 transparent; }

.navbar-nav ul{ position: absolute; left: -9999px; }
.navbar ul ul li{ -webkit-transition: all .25s ease; -ms-transition: all .25s ease; transition: all .25s ease; margin-bottom: 15px; padding: 0 20px; }
.navbar ul ul li:last-child{ margin-bottom: 0; }
.navbar ul ul li.active > a{ color: #000; }
.navbar-nav li > ul{ padding: 15px 0; margin: 0 0 0 10px; background: #fff; min-width: 225px; -webkit-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.07); -ms-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.07); -moz-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.07); box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.07); }
.navbar-nav li:hover > ul{ left: auto; }

.navbar-nav > li.has-sub > ul{ opacity: 0; margin-top: 10px; -webkit-transition: all .5s ease; -ms-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; }
.navbar-nav > li.has-sub:hover > ul{ margin-top: 0; opacity: 1; }
.navbar-nav > li.has-sub:last-child:hover ul{ left: inherit; right: 0; }
.navbar li > ul ul{ margin-top: -39px; margin-left: 200px; min-width: 200px; }
.navbar li > ul ul ul{ margin-left: 175px; min-width: 130px; }
.navbar ul ul li:last-child > a, .navbar ul ul li.last-item > a{ border-bottom: 0; }
.navbar ul ul li.has-sub > a:after{ position: absolute; top: 12px; right: -3px; width: 8px; height: 2px; display: block; background: #6f6f6f; content: ""; }
.navbar ul ul li.has-sub > a:before{ position: absolute; top: 9px; right: 0; display: block; width: 2px; height: 8px; background: #6f6f6f; content: ''; -webkit-transition: all .25s ease; -ms-transition: all .25s ease; transition: all 0.25s ease; }
.navbar ul ul > li.has-sub:hover > a:before{ top: 17px; height: 0; }

/* atribute navigation */
.attr-nav{ float: right; margin-left: 11px; margin-right: -15px; }
.attr-nav .dropdown-toggle:after{ content: none; }
.attr-nav > ul{ padding: 0; margin: 0 0 -5px 0; list-style: none; display: inline-block; }
.attr-nav > ul > li{ float: left; display: block; }
.attr-nav > ul > li > a{ color: #232323; display: block; padding: 21px 15px; position: relative; }

/* top search */
.top-search{ padding: 10px 0; display: none; }
.top-search input.form-control{ background-color: transparent; min-height: auto; border: none; -moz-box-shadow: 0px 0px 0px; -webkit-box-shadow: 0px 0px 0px; -o-box-shadow: 0px 0px 0px; box-shadow: 0px 0px 0px; color: #fff; height: 40px; padding: 0 15px; }
.top-search input.form-control::placeholder{ color: #f1f1f1; opacity: 1; }
.top-search input.form-control::-ms-input-placeholder, .top-search input.form-control::-moz-input-placeholder{ color: #f1f1f1; }
.top-search .input-group-addon{ background-color: transparent; border: none; color: #fff; padding-left: 0; padding-right: 0; line-height: 36px; font-size: 16px; }
.top-search .input-group-addon.close-search{ cursor: pointer; }


/* Responsive CSS */
@media screen and (min-width: 992px){

	/* ===================================
		Default Header style
	====================================== */

	header.scrollHeader .navbar-default{ position: fixed; top: 0; z-index: 99; width: 100%; background: #fff; left: 0; transition: all 0.2s ease 0s;  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); }
	.wrapper-boxed .scrollHeader .navbar-default{ left: 0; margin: 0 auto; max-width: 1200px; right: 0; }
	.scrollHeader .navbar-brand img{ max-height: 38px; transition-duration: 0.5s; }
	.scrollHeader .navbar-header-custom{ padding: 10px 0; transition-duration: 0.5s; }

	/* atribute navigation */
	.scrollHeader .attr-nav > ul > li > a{ padding: 21px 15px; }
	.attr-nav > ul > li.dropdown ul.dropdown-menu{ visibility: hidden; top: 66px; border-radius: 0; opacity: 0; left: -225px; }
	.attr-nav > ul > li.dropdown:hover ul.dropdown-menu{ margin-top: 0; opacity: 1; visibility: visible; }
	.scrollHeader .attr-nav > ul > li.dropdown ul.dropdown-menu{ top: 67px; }

	/* transparent header */
	.transparent-header .navbar-nav > li > a { color: #fff; }
	.transparent-header .navbar-nav > li.active > a,.transparent-header .navbar-nav > li.current > a { opacity: 1; }
	.transparent-header .navbar > ul > li.has-sub > a:after { border-color: transparent #fff #fff transparent; }
	.transparent-header .attr-nav > ul > li > a { color: #fff; }
	.transparent-header .navbar-nav > li.has-sub > a:hover { color: #fff; }

	.transparent-header.scrollHeader .navbar-nav > li > a { color: #232323; }
	.transparent-header.scrollHeader .navbar-nav > li.active > a,.transparent-header.scrollHeader .navbar-nav > li.current > a { opacity: 1; color: #000; }
	.transparent-header.scrollHeader .navbar > ul > li.has-sub > a:after { border-color: transparent #232323 #232323 transparent; }
	.transparent-header.scrollHeader .navbar > ul > li.has-sub.current > a:after { border-color: transparent #000 #000 transparent; }
	.transparent-header.scrollHeader .attr-nav > ul > li > a { color: #232323; }
	.transparent-header.scrollHeader .navbar-nav > li.has-sub > a:hover { color: #232323; }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.navbar-nav > li{ margin-left: 12px; }
 	.navbar-nav > li > a { padding: 21px 0.4rem; }
    .attr-nav > ul > li > a { padding: 21px 8px; }
    .scrollHeader .navbar-nav > li > a { padding: 21px 0.4rem; }
    .scrollHeader .attr-nav > ul > li > a { padding: 21px 8px; }
}

@media screen and (max-width: 991px){ 

	/* ===================================
		Default Header style
	====================================== */	

	.navbar-header-custom{ padding: 4px 0 8px;  }
	.menu_area .navbar-brand{ margin-top: 6px;  }

	.navbar-nav li > ul{ -webkit-box-shadow: none; -ms-box-shadow: none; -moz-box-shadow: none; box-shadow: none;  }
	.navbar-nav > li.has-sub > ul{ opacity: 1; margin: 0; -webkit-box-shadow: none; -ms-box-shadow: none; -moz-box-shadow: none; box-shadow: none; -webkit-transition: all 0s ease; -ms-transition: all 0s ease; -moz-transition: all 0s ease; -o-transition: all 0s ease; transition: all 0s ease;  }
	.navbar li > ul ul{ margin: 0; min-width: auto;  }
	.navbar-nav > li.has-sub{ padding-right: 0;  }
	.navbar-nav > li > a{ padding: 10px 20px; color: #232323;  }
	.navbar-nav > li > a:hover{ opacity: 1;  }
	.navbar-nav li.has-sub a:hover{ color: #232323;  }
	.navbar ul ul li.active > a{ color: #000;  }
	.navbar .sub-title { color: #232323; }

	.navbar-nav{ background: #fff; -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); -ms-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); position: absolute; top: 71px; left: 0; width: 100%; transition-duration: 0; -ms-transition-duration: 0; -moz-transition-duration: 0; -webkit-transition-duration: 0; -o-transition-duration: 0; width: 100%; display: none; transition: none; -ms-transition: none; -moz-transition: none; -webkit-transition: none; -o-transition: none;  }
	.navbar-nav li.active > a{ opacity: 1;  }
	.navbar-nav ul{ width: 100%; display: none; transition: none; -ms-transition: none; -moz-transition: none; -webkit-transition: none; -o-transition: none;  }
	.navbar-nav li{ width: 100%; border-top: 1px solid #e6e6e6; position: relative; padding: 0; margin: 0;  }
	.navbar-nav ul li:hover{ background: #f5f5f5;  }
	.navbar-nav ul ul li{ height: auto;  }
	.navbar-nav li:hover > ul > li{ height: auto;  }

	.navbar ul li a{ width: 100%; border-bottom: 0;  }
	.navbar ul ul{ position: relative; left: 0; width: 100%; margin: 0; text-align: left; background: #f5f5f5; padding: 0;  }
	.navbar ul ul li{ margin-bottom: 0; padding: 0; border-color: #e6e6e6;  }
	.navbar ul ul li a{ width: 100%; border-bottom: 0; color: #616161; background: none; padding: 8px 30px;  }
	.navbar ul ul ul li a{ padding-left: 45px;  }
	.navbar > ul > li{ float: none;  }
	.navbar-nav ul ul{ position: relative; left: 0; width: 100%; margin: 0; text-align: left; background: #f5f5f5; padding: 0;  }

	.navbar > ul > li.has-sub > a:after, .navbar > ul > li.has-sub > a:before{ display: none;  }
	.navbar ul ul > li.has-sub > a:after, .navbar ul ul > li.has-sub > a:before{ display: none;  }
	.navbar-nav ul ul ul li.active a{ border-left: none;  }
	.navbar-nav > ul > li.has-sub > ul > li.active > a, .navbar-nav > ul ul > li.has-sub > ul > li.active > a{ border-top: none;  }
	.navbar li > ul ul ul{ margin-left: 0; background: #fff;  }
	.navbar ul ul ul ul li a{ padding-left: 55px;  }
	.navbar-nav .has-sub .has-sub .submenu-button{ height: 40px;  }
	.navbar-nav .has-sub .has-sub .submenu-button:after{ top: 19px;  }
	.navbar-nav .has-sub .has-sub .submenu-button:before{ top: 16px;  }

	.navbar-toggler{ width: 50px; height: 45px; padding: 0; border-radius: 0; position: absolute; right: 0; top: 14px; cursor: pointer; z-index: 12399994; border: none; background: #ffa41e;  }
	.navbar-toggler:after{ position: absolute; top: 22px; right: 15px; display: block; height: 8px; width: 20px; border-top: 2px solid #000; border-bottom: 2px solid #000; content: "";  }
	.navbar-toggler:before{ -webkit-transition: all .3s ease; -ms-transition: all .3s ease; transition: all .3s ease; position: absolute; top: 16px; right: 15px; display: block; height: 2px; width: 20px; background: #000; content: "";  }
	.navbar-toggler.menu-opened:after{ -webkit-transition: all .3s ease; -ms-transition: all .3s ease; transition: all .3s ease; top: 23px; border: 0; height: 2px; width: 19px; background: #000; -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg);  }
	.navbar-toggler.menu-opened:before{ top: 23px; background: #000; width: 19px; -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -ms-transform: rotate(-45deg); -o-transform: rotate(-45deg); transform: rotate(-45deg);  }
	.navbar-nav .submenu-button{ position: absolute; z-index: 99; right: 0; top: 2px; display: block; height: 44px; width: 100%; cursor: pointer;  }
	.navbar-nav .submenu-button:after{ position: absolute; top: 21px; right: 17px; width: 8px; height: 2px; display: block; background: #6f6f6f; content: "";  }
	.navbar-nav .submenu-button:before{ position: absolute; top: 18px; right: 20px; display: block; width: 2px; height: 8px; background: #6f6f6f; content: "";  }
	.navbar-nav .has-sub.active > .submenu-button:before{ display: none;  }

	/* atribute navigation */
	.attr-nav{ float: left;  }
	.attr-nav > ul > li{ display: inline-block;  }
	.attr-nav > ul > li > a{ padding: 0;  }
	.attr-nav ul li.search { margin-top: 8px; }

	/* transparent header */
	.transparent-header li.search > a { color: #fff; }

}
