body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  scroll-behavior: smooth;
}

input[type="file"] {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.error {
  border-color: crimson;
}

.image-div {
  position: relative;
}

.del-button {
  position: absolute;
  right: 0;
  top: -10px;
  border-radius: 100%;
  padding: 2px 9px;
  border: none;
  background-color: crimson;
  color: white;
}
