.MuiToolbar-root,
.MuiToolbar-gutters,
.MuiToolbar-regular,
.css-14covj4-MuiToolbar-root {
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center !important;
}

.MuiToolbar-root,
.MuiToolbar-gutters,
.MuiToolbar-regular,
.css-1756544-MuiToolbar-root {
}

.MuiToolbar-root,
.MuiToolbar-gutters,
.MuiToolbar-regular,
.css-1qlerxe-MuiToolbar-root {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}
