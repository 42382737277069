.shimmer-container {
  padding: 1%;
  height: 85px;
  display: flex;
}

.shimmer-img-container {
  width: 15%;
  padding: 20px;
}

.img-ready,
.shimmer-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.shimmer-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 0;
  justify-content: space-around;
}

.stripe {
  height: 20%;
}

.small-stripe {
  width: 40%;
}

.medium-stripe {
  width: 70%;
}

.long-stripe {
  width: 100%;
}

.shimmer-container.loading .shimmer-img,
.shimmer-container.loading .stripe {
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

.chat-photo {
  height: 45px;
  margin-left: 4px;
  margin-right: 10px;
}

.small {
  height: 25px !important;
}

.large {
  height: 80px !important;
}

@keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
